import { LoaderButton } from "Constants/icons";

const CustomButton = ({ text, onClick, disabled, loading }) => {
  let buttonClassName =
    "linear mt-4 flex w-full items-center justify-center rounded-xl px-2 py-2 text-base font-medium transition duration-200";

  // Modify the class name based on the disabled prop
  if (disabled) {
    buttonClassName += " bg-gray-300 text-bleck-500 ";
  } else {
    buttonClassName +=
      " bg-brand-500 text-white hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200";
  }
  return (
    <button onClick={onClick} className={buttonClassName} disabled={disabled}>
      {loading ? (
        <>
          <LoaderButton />
          Loading...
        </>
      ) : (
        text
      )}
    </button>
  );
};
export default CustomButton;
