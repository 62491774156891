import CustomButton from "components/CustomButton";
import CustomSelect from "components/CustomSelect";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import DatePicker from "react-tailwindcss-datepicker";
import { addNewEvent } from "Redux/Actions/calendarAction";
import TextField from "components/fields/TextField";
import { EventFormData } from "Constants/utils";
import { getAllEvents } from "Redux/Actions/calendarAction";
import { mapEventData } from "Constants/utils";

const EventForm = (props) => {
    const dispatch = useDispatch();
  const { eventType } = useSelector((state) => state.calendarReducer);
    
  const [validationErrors, setValidationError] = useState({});
  const [selectedType,setSelectedType]= useState()
  const { darkMode } = useSelector((state) => state.generalReducer);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });
 
  const [value, setValue] = useState({
    start: props.start,
    end: props.end,

    title: "",
      content: "",
    
  });
useEffect(() => {
    if (!eventType.length) {
      dispatch(getAllEvents())
  }
}, [eventType])

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

 


  const handleInputChange = (valuefield, field) => {
    setValue({
      ...value,
      title: valuefield["title"] ?? value.title,
      content: valuefield["content"] ?? value.content,
    });

    if (validationErrors[field] && value[field].length > 0) {
      const state = validationErrors;
      // delete state[field];
      setValidationError(state);
    }
  };
  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!value.startDate) {
      errors = { ...errors, startDate: "startDate is required !" };
      valid = false;
    } else if (!value.endDate) {
      errors = { ...errors, endDate: "endDate is required !" };
      valid = false;
    } else if (value.title.length === 0) {
      errors = { ...errors, title: "title is required !" };
      valid = false;
    } else if (value.content.length === 0) {
      errors = { ...errors, content: "content is required !" };
      valid = false;
    }

    setValidationError(errors);
    return valid;
  };

  const handleSubmit = () => {
  
    if (validateForm) {
      dispatch(addNewEvent({ ...value,event_type_id:selectedType}));
    }
  };
  return (
    <div className="block w-full items-center justify-center">
      <div className="">
        <div className="flex  ">
          <div className="mr-2 w-1/2 w-full grow">
            <InputField
              label="Titre"
              id="title"
              name="title"
              extra="mb-3"
              placeholder="title"
              type="text"
              field="title"
              required={true}
              setValue={(value) => handleInputChange(value, "title")}
              errMessage={validationErrors["title"]}
            />
          </div>
          <div className="ml-2 w-1/2 w-full grow">
            <TextField
              label="Description"
              id="content"
              className="w-full"
              name="content"
              extra="mb-3"
              placeholder="Content"
              field="content"
              required={true}
              setValue={(value) => handleInputChange(value, "content")}
              errMessage={validationErrors["content"]}
            />
          </div>
        </div>
        <div className="mb-4 ">
          <div className="flex w-full ">
            <div className="mt-4 w-1/4 grow">
              <label htmlFor="Period">Période</label>
            </div>
            <div className="w-3/4 grow">
              <DatePicker
                containerClassName="relative mt-4"
                toggleClassName="absolute bg-blue-300 rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                inputClassName="bg-lightPrimary  dark:!bg-navy-900 dark:text-white w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:shadow-outline"
                classNames="dark:bg-[#0b14374d] dark:text-white"
                              primaryColor={"pink"}
                              period={value}
                              value={selectedDateRange}
                              dateLooking="middle"
                onChange={(date) => {
                  setSelectedDateRange(date);
                  handleValueChange({
                    ...value,
                    start: date.startDate,
                    end: date.endDate,
                  });
                }}
                calendarContainer={
                  darkMode ? "dark:bg-[#0b14374d]  dark:text-white" : "bg-white"
                }
                calendarClassName="dark:bg-[#0b14374d]  dark:text-white"
              />
            </div>
          </div>
          <div className="relative mt-2">
          <CustomSelect
                  data={
                    mapEventData(eventType, true)
                  }
                  selected={selectedType}
                  setSelected={(value) =>
                   setSelectedType(value)
                  }
                  label={"Evenement type"}
                  required={true}
                  errMessage={validationErrors["event"]}
                />
          </div>
        </div>
      </div>
      <div className="block w-full items-center justify-center">
        <div className="grid-cols-2 gap-4"></div>
        <div className="flex justify-center">
          <div className="w-1/3">
            <CustomButton text={"Ajouter"} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventForm;
