import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/fr";
const ICalendar = (props) => {
  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.color;
    var style = {
      backgroundColor: backgroundColor,
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  const isToday = (date) => {
    const today = moment().startOf("day");
    return moment(date).isSame(today, "day");
  };

  return (
    <Calendar
      startAccessor="start"
      endAccessor="end"
      localizer={momentLocalizer(moment)}
      defaultDate={moment().toDate()}
      events={props.events}
      style={{
        backgroundColor: "#FFFFFF",
        color: "#FFFFFF",
        borderRadius: "4px",
        padding: "8px",
        fontSize: "14px",
        cursor: "pointer",
        height: "500px",
      }}
      messages={{
        next: "suivant",
        previous: "précédent",
        today: "aujourd'hui",
        month: "mois",
      }}
      components={{
        dateCellWrapper: (props) => {
          const isTodayDate = isToday(props.value);
          const defaultClassName = "rbc-day-bg";
          const customClassName = isTodayDate ? "bg-blue-200 text-white " : "";
          const className = `${defaultClassName} ${customClassName}`;
          return <div className={className}>{props.children}</div>;
        },
      }}
      eventPropGetter={eventStyleGetter}
      onSelectEvent={props.onSelectEvent}
      onSelectSlot={props.onSelectSlot}
      culture="fr"
      selectable
      views={["month", "agenda"]}
    />
  );
};
export default ICalendar;
