import { mapClassData } from "Constants/utils";
import { teacherData } from "Constants/utils";
import { isMail } from "Constants/utils";
import { parentData } from "Constants/utils";
import { updateParent } from "Redux/Actions/ParentAction";
import { addNewParent } from "Redux/Actions/ParentAction";
import { getAllClass } from "Redux/Actions/classActions";
import { addNewTeacher } from "Redux/Actions/teacherAction";
import { updateTeacher } from "Redux/Actions/teacherAction";
import CustomButton from "components/CustomButton";
import CustomSelect from "components/CustomSelect";
import MultiSelect from "components/MultiSelect";
import InputField from "components/fields/InputField";
import { useDebugValue, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

const ParentForm = ({ user }) => {
  const dispatch = useDispatch();
  const { loadingAdd, error } = useSelector((state) => state.teacherReducer);
  const { classes } = useSelector((state) => state.classReducer);
  const mapClassIds = user?.classes?.map((el) => ({
    value: el.id,
    label: el.name,
  }));
  const [selectedUsers, setSelectedUsers] = useState(mapClassIds);

  useEffect(() => {
    dispatch(getAllClass());
  }, []);
  const [userData, setUserData] = useState({
    name: user?.name,
    email: user?.email,
    phone: user?.phone,
    role: "teacher",
  });
  const [validationErrors, setValidationError] = useState({});

  const handleChange = (value, field) => {
    if (field === "class") {
      setUserData({ ...userData, [field]: value });
    } else {
      setUserData({ ...userData, [field]: value[field] });
    }
    if (validationErrors[field] && value[field].length > 0) {
      const state = validationErrors;
      delete state[field];
      setValidationError(state);
    }
  };
  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!userData?.user_id) {
      teacherData.forEach(({ field, required, label }) => {
        if (user && field === "password") {
          return null;
        } else if (required && !userData[field]) {
          errors = { ...errors, [field]: `${label} is required !` };
          valid = false;
        }
      });

      if (userData?.name?.length < 4) {
        errors = { ...errors, name: `Name must be at least 4 characters !` };
        valid = false;
      }
      if (userData?.phone?.length < 8) {
        errors = { ...errors, phone: `Phone must be at least 8 digits !` };
        valid = false;
      }
    }
    setValidationError(errors);
    return valid;
  };
  function handleSelect(data) {
    if (data.some((item) => item.value === "all")) {
      setSelectedUsers(mapClassData(classes, true).map((user) => user.value));
    } else {
      setSelectedUsers(data.map((user) => user.value));
    }
  }
  const handleSubmit = () => {
    if (validateForm()) {
      if (user) {
        dispatch(
          updateTeacher(user?.id, {
            ...userData,
            classes: selectedUsers,
          })
        );
      } else {
        var splitText = userData?.name?.split(" ");
        var firstWord = splitText[0];
        const password = firstWord + userData?.phone;
        dispatch(
          addNewTeacher({
            ...userData,
            classes: selectedUsers,
            password: password,
            password_confirmation: password,
          })
        );
      }
    }
  };
  return (
    <div className="block w-full items-center justify-center">
      {teacherData.map(
        ({ label, placeholder, type, field, required }, index) => {
          if (
            user &&
            (field === "password" || field === "password_confirmation")
          )
            return null;
          return type === "select" ? (
            <div className="mt-4" key={index}>
              <label
                className={` text-sm text-navy-700 dark:text-white ${"ml-3 font-bold"}`}
              >
                Classes
                <span className="text-red-600"> *</span>
              </label>
              <Select
                options={[
                  { value: "all", label: "Select All" },
                  ...mapClassData(classes, true),
                ]}
                isMulti
                defaultValue={selectedUsers}
                onChange={handleSelect}
                placeholder={placeholder}
                isSearchable={true}
              />
            </div>
          ) : (
            <InputField
              key={index}
              extra="mb-3"
              label={label}
              placeholder={placeholder}
              type={type}
              field={field}
              required={required}
              defaultValue={userData[field]}
              setValue={(value) => handleChange(value, field)}
              totalInputs={userData}
              errMessage={validationErrors[field]}
            />
          );
        }
      )}
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={user ? "Update" : "Ajouter"}
            disabled={!userData.name || !userData.phone}
            onClick={handleSubmit}
            loading={loadingAdd}
          />
        </div>
      </div>
    </div>
  );
};
export default ParentForm;
