import { updateEventType } from 'Redux/Actions/calendarAction';
import { addNewEventType } from 'Redux/Actions/calendarAction';
import CustomButton from 'components/CustomButton';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState } from 'react'
import { ColorPicker, useColor } from 'react-color-palette';
import { useDispatch, useSelector } from 'react-redux';

const EventTypeForm = ({event}) => {
    const dispatch = useDispatch();
    const [validationErrors, setValidationError] = useState({});
    const [color, setColor] = useColor("hex", event?.color || "#121212");
    const [showColorPicker, setShowColorPicker] = useState(false);
    const { darkMode } = useSelector((state) => state.generalReducer);
    const rgbToBackground = (rgb) => {
        return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
    };
    const handleColorPickerToggle = () => {
        setShowColorPicker(!showColorPicker);
    };
   
    const [value, setValue] = useState({
        name: event?.name || "",
        description: event?.description|| "",
      });
    const handleInputChange = (valuefield, field) => {
        setValue({
          ...value,
          name: valuefield["name"] ?? value.name,
          description: valuefield["description"] ?? value.description,
        });
    
        if (validationErrors[field] && value[field].length > 0) {
          const state = validationErrors;
          // delete state[field];
          setValidationError(state);
        }
    };
    const validateForm = () => {
        let valid = true;
        let errors = {};
     if (!value.name) {
          errors = { ...errors, name: "name is required !" };
          valid = false;
        } else if (!value.description) {
          errors = { ...errors, description: "description is required !" };
          valid = false;
        } else if (!color) {
          errors = { ...errors, color: "color is required !" };
          valid = false;
        }
        setValidationError(errors);
        return valid;
      };
    const handleSubmit = () => {
        if (validateForm()) {
            if (event) {
                dispatch(updateEventType({ ...value, color: color.hex },event?.id));
            
            } else {
                
                dispatch(addNewEventType({ ...value, color: color.hex }));
            }
        }
      };
  return (
    <div>
      <div className="flex  ">
          <div className="mr-2 w-1/2 w-full grow">
            <InputField
                      label="Name"
              id="name"
              name="name"
              extra="mb-3"
              placeholder="name"
              type="text"
              field="name"
                      required={true}
                      defaultValue={value['name']}
                      setValue={(value) => handleInputChange(value, "name")}
                      totalInputs={value}
              errMessage={validationErrors["name"]}
            />
          </div>
          <div className="ml-2 w-1/2 w-full grow">
            <TextField
              label="Description"
              id="description"
              className="w-full"
              name="description"
              extra="mb-3"
              placeholder="Description"
              field="description"
                      required={true}
                      defaultValue={value['description']}
                      totalInputs={value}
              setValue={(value) => handleInputChange(value, "description")}
              errMessage={validationErrors["description"]}
            />
          </div>
          </div>
          <div className="mb-4 ">
          <div className="relative mt-2">
            <div className="mb-4 ">
              <div className="flex w-full ">
                <div className="mt-4 w-1/4 grow">
                  <label htmlFor="Period">Couleur</label>
                </div>
                <div className="mt-4 w-3/4 grow">
                  <button
                    onClick={handleColorPickerToggle}
                    style={{
                      background: rgbToBackground(color.rgb), // Set the background color based on the RGB values
                    }}
                    className="mr-2 mb-2 rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:!bg-navy-900 dark:focus:ring-cyan-800"
                  >
                    {!showColorPicker ? "Afficher" : "masquer"}
                  </button>
                  {showColorPicker && (
                    <div className="z-90 absolute top-10 left-40">
                      <ColorPicker
                        width={300}
                        height={80}
                        color={color}
                        onChange={setColor}
                        hideHSV
                        dark={darkMode ? true : false}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="block w-full items-center justify-center">
        <div className="grid-cols-2 gap-4"></div>
        <div className="flex justify-center">
          <div className="w-1/3">
            <CustomButton text={event?"Modifier":"Ajouter"} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventTypeForm
