export const columnsDataDevelopment = [
  {
    Header: "NOM",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "PHONE",
    accessor: "phone",
  },
  {
    Header: "ACTIONS",
    accessor: "id", // Use a unique identifier from your data as the accessor
  },
  // {
  //   Header: "PROGRESS",
  //   accessor: "progress",
  // },
];

export const columnsDataFeedback = [
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "COMMENTAIRE",
    accessor: "comment",
  },
  {
    Header: "PARENT",
    accessor: "user",
  },
  {
    Header: "DATE",
    accessor: "created_at",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "ACTIONS",
    accessor: "id", // Use a unique identifier from your data as the accessor
  },
  // {
  //   Header: "PROGRESS",
  //   accessor: "progress",
  // },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const notifsDataColumns = [
  {
    Header: "TITRE",
    accessor: "title",
  },
  {
    Header: "CONTENU",
    accessor: "content",
  },
  {
    Header: "USERS",
    accessor: "user",
  },
  {
    Header: "ACTIONS",
    accessor: "id", // Use a unique identifier from your data as the accessor
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
export const columnsDataAbsence = [
  {
    Header: "NOM",
    accessor: "student.full_name",
  },
  {
    Header: "DE",
    accessor: "from",
  },
  {
    Header: "A",
    accessor: "to",
  },
];
