import { addNewFolder } from "Redux/Actions/galerieAction";
import CustomButton from "components/CustomButton";
import InputField from "components/fields/InputField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const FolderForm = () => {
  const dispatch = useDispatch();
  const { loadingAdd } = useSelector((state) => state.galerieReducer);
  const [folderName, setFolderName] = useState();
  const [errMessage, setErrorMessage] = useState();
  const handleSubmit = () => {
    if (folderName === "") {
      setErrorMessage("folder name is required !");
    } else {
      dispatch(addNewFolder(folderName));
    }
  };
  return (
    <>
      <InputField
        extra="mb-3"
        label={"Folder Name"}
        placeholder={"folder name"}
        type={"text"}
        field={"folderName"}
        required={true}
        defaultValue={folderName}
        setValue={(value) => setFolderName(value)}
        // totalInputs={cantineData}
        errMessage={errMessage}
        // onKeyDown={field === "menu" ? handleKeyDown : undefined}
      />
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={"Ajouter"}
            disabled={!folderName}
            onClick={handleSubmit}
            loading={loadingAdd}
          />
        </div>
      </div>
    </>
  );
};

export default FolderForm;
