import { Loading } from "Constants/icons";
import { PlusIconNoStroke } from "Constants/icons";
import { deleteClass } from "Redux/Actions/classActions";
import { getAllClass } from "Redux/Actions/classActions";
import { closeCentredModal } from "Redux/Actions/modalAction";
import { openCentredModal } from "Redux/Actions/modalAction";
import Card from "components/card";
import ClassForm from "components/class/ClassForm";
import StudentItem from "components/class/StudentItem";
import ArrowDown from "components/icons/ArrowDown";
import TrashIcon from "components/icons/TrashIcon";
import DeleteModal from "components/modal/DeleteModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DevelopmentTable from "./components/DevelopmentTable";
import { columnsDataDevelopment } from "./variables/columnsData";

const ClassView = () => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.generalReducer);
  const { classes, loading } = useSelector((state) => state.classReducer);

  useEffect(() => {
    dispatch(getAllClass());
  }, []);

  return (
    <div className="mt-5  h-full ">
      <div className="mt-4   min-h-[70vh]  overflow-x-scroll xl:overflow-x-hidden">
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={classes || []}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ClassView;
