import { closeSuccessModal } from "Redux/Actions/modalAction";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

const SuccessModal = () => {
  const dispatch = useDispatch();
  const { openSuccessModal, contentSuccess, isSuccess } = useSelector(
    (state) => state.modalReducer
  );
  const modalRef = useRef(null);

  const handleCloseModal = (e) => {
    dispatch(closeSuccessModal());
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(closeSuccessModal());
    }, 4000);

    document.addEventListener("mousedown", handleCloseModal);

    return () => {
      clearTimeout(timer);

      // Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", handleCloseModal);
    };
  }, [openSuccessModal, dispatch]);
  return (
    <div
      ref={modalRef}
      id="successModal"
      tabIndex="-1"
      aria-hidden="true"
      className={` ${
        openSuccessModal ? "flex" : "hidden"
      }  h-modal fixed top-0 right-0 left-0 z-50  w-full items-start justify-end overflow-y-auto overflow-x-hidden md:inset-0 md:h-full`}
    >
      <div className="relative h-full w-full max-w-md p-4 md:h-auto">
        <div
          className={` ${
            isSuccess ? "bg-green-100 " : "bg-red-100 "
          } relative flex h-[80px] rounded-lg text-center shadow ps-7  `}
        >
          <div
            className={`flex items-center rounded-lg  text-base ${
              isSuccess ? "text-green-700" : "text-red-700"
            } `}
            role="alert"
          >
            {isSuccess ? (
              <span className="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            ) : (
              <span className="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            )}
            {contentSuccess}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
