import { billingForm } from "Constants/utils";
import { closeCentredModal } from "Redux/Actions/modalAction";
import CustomButton from "components/CustomButton";
import InputField from "components/fields/InputField";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

const BillingExport = ({ billings }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const handleChange = (value, field) => {
    setFormData({ ...formData, [field]: value[field] });
  };

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!formData.startDate) {
      errors = { ...errors, startDate: "startDate is required !" };
      valid = false;
    } else if (!formData.endDate) {
      errors = { ...errors, endDate: "endDate is required !" };
      valid = false;
    } else {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(formData.endDate);

      if (startDate > endDate) {
        errors = {
          ...errors,
          dateComparison:
            "La date de fin doit être supérieure ou égale à la date de début.",
        };
        valid = false;
      }
    }
    setValidationErrors(errors);
    return valid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const filteredData = billings?.filter((item) => {
        const createdAt = new Date(item?.created_at);
        const startDate = new Date(formData?.startDate);
        const endDate = new Date(formData?.endDate);
        return createdAt >= startDate && createdAt <= endDate;
      });
      if (filteredData?.length === 0) {
        setValidationErrors({
          ...validationErrors,
          dateComparison: "1ucune donnée dans cette plage.",
        });
      } else {
        const mappedData = filteredData?.map((el) => ({
          Èlève: el?.student?.first_name + " " + el?.student?.last_name,
          Responsable: el?.student?.user?.name,
          Service: el?.service?.name,
          Status: el?.status,
          amount: el?.amount,
        }));

        import("xlsx").then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(mappedData);
          const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ["data"],
          };
          const excelBuffer = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });

          saveAsExcelFile(excelBuffer, "billing-list");
        });
        dispatch(closeCentredModal());
      }
    }
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };
  return (
    <div className="block h-[100%] w-full items-center justify-center">
      {billingForm.map(
        ({ label, placeholder, type, field, required }, index) => {
          return (
            <InputField
              key={index}
              extra="mb-3 "
              label={label}
              placeholder={placeholder}
              type={type}
              field={field}
              required={required}
              defaultValue={formData[field]}
              setValue={(value) => handleChange(value, field)}
              totalInputs={formData}
              errMessage={validationErrors[field]}
            />
          );
        }
      )}
      {validationErrors["dateComparison"] && (
        <div className=" mt-1 px-3 text-red-500">
          {validationErrors["dateComparison"]}
        </div>
      )}
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={"Exporter"}
            disabled={!formData?.startDate || !formData?.endDate}
            onClick={handleSubmit}
            // loading={loadingAdd}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingExport;
