import Card from "components/card";
import TeacherIcon from "components/icons/TeacherIcon";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import {
  FcBadDecision,
  FcCancel,
  FcCheckmark,
  FcCollaboration,
  FcConferenceCall,
  FcMiddleBattery,
} from "react-icons/fc";
import ReactionsChart from "./ReactionsChart";
import FeedBackChart from "./FeedBackChart";
import { useDispatch, useSelector } from "react-redux";
import { getStats } from "Redux/Actions/userAction";

const DashboardView = () => {
  const dispatch = useDispatch();
  const { stats } = useSelector((state) => state.userReducer);
  console.log(stats);
  useEffect(() => {
    dispatch(getStats());
  }, []);
  const ticketOverview = [
    {
      id: 1,
      number: stats?.studentCount,
      title: "Èlèves",
      icon: <FcConferenceCall className="h-10 w-10" />,
    },
    {
      id: 2,
      number: stats?.ResponsibleCount,
      title: "Responsables",
      icon: <FcCollaboration className="h-10 w-10" />,
    },
    {
      id: 3,
      number: stats?.teachers,
      title: "Professeurs",
      icon: <TeacherIcon h="10" w="10"></TeacherIcon>,
    },
  ];
  const payOverview = [
    {
      id: 1,
      number: stats?.paidPaymentAmount,
      title: "Payé",
      icon: <FcCheckmark className="h-10 w-10" />,
    },
    {
      id: 2,
      number: stats?.UnpaidPaymentAmount,
      title: "Non payé",
      icon: <FcCancel className="h-10 w-10" />,
    },
    {
      id: 3,
      number: stats?.partiallyPaymentAmount,
      title: "Partiellement",
      icon: <FcMiddleBattery h="10" w="10" />,
    },
  ];

  const reactionsData = {
    cantine: {
      sad: stats?.SadeCountcantine,
      happy: stats?.HappyCountCantine,
      satisfied: stats?.SatisfiedCountCantine,
    },
    garderie: {
      sad: stats?.SadeCountProgram,
      happy: stats?.HappyCountProgram,
      satisfied: stats?.SatisfiedCountProgram,
    },
  };
  const feedbackData = {
    paid: stats?.paidPaymentCount,
    unpaid: stats?.UnpaidPaymentCount,
    partiallyPaid: stats?.partiallyPaymentCount,
  };
  return (
    <div className="mt-5  h-full ">
      <div className={"h-full w-full p-4"}>
        <div className="relative flex   w-full justify-between">
          {ticketOverview.map(({ id, number, title, icon }) => {
            return (
              <div
                className={
                  "mx-2 flex h-[100px] w-1/3 rounded-[10px] bg-white p-4 text-lg  font-bold text-darkBg dark:text-white"
                }
                key={id}
              >
                <div className="mr-5">{icon}</div>
                <div>
                  <CountUp duration={1} end={number} />
                  <div className="my-2"> {title}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="relative mt-4  flex w-full justify-between">
          {payOverview.map(({ id, number, title, icon }) => {
            return (
              <div
                className={
                  "mx-2 flex h-[100px] w-1/3 rounded-[10px] bg-white p-4 text-lg  font-bold text-darkBg dark:text-white"
                }
                key={id}
              >
                <div className="mr-5">{icon}</div>
                <div>
                  <div className="flex">
                    <CountUp duration={1} end={number} />
                    <div className="ms-2">Dt</div>
                  </div>
                  <div className="my-2"> {title}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mx-2 mt-8 flex w-full">
          <div className="mr-5 w-2/3">
            <ReactionsChart data={reactionsData} />
          </div>
          <div className="w-1/3">
            <FeedBackChart data={feedbackData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardView;
