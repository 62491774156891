import { reclamationData } from "Constants/utils";
import { answerAvis } from "Redux/Actions/notifAction";
import CustomButton from "components/CustomButton";
import InputField from "components/fields/InputField";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

const FedbackForm = ({ feedback }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  console.log(feedback);
  const handleSubmit = () => {
    const type = reclamationData.find(({ id }) => id === feedback.type)?.text;
    dispatch(
      answerAvis({
        title: type,
        message: message,
        selectedUsers: [feedback?.user_id],
        feedbackId: feedback?.id,
      })
    );
  };
  return (
    <div className="block w-full items-center justify-center">
      <InputField
        extra="mb-3"
        label={"Message"}
        placeholder={"Entre votre message"}
        type={"text-area"}
        field={"message"}
        required={true}
        setValue={(value) => setMessage(value["message"])}
      />

      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={"Envoyer"}
            disabled={!message}
            onClick={handleSubmit}
            loading={false}
          />
        </div>
      </div>
    </div>
  );
};

export default FedbackForm;
