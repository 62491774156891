import { ADD_STUDENT_FAILED } from "Redux/ActionsType/studentType";
import { GET_STUDENT_SUCCESS } from "Redux/ActionsType/studentType";
import { LOADING_DELETE_STUDENT } from "Redux/ActionsType/studentType";
import { DELETE_STUDENT_FAILED } from "Redux/ActionsType/studentType";
import { GET_HEALTH_FAILED } from "Redux/ActionsType/studentType";
import { ADD_HEALTH_FAILED } from "Redux/ActionsType/studentType";
import { UPDATE_STUDENT_FAILED } from "Redux/ActionsType/studentType";
import { UPDATE_STUDENT_SUCCESS } from "Redux/ActionsType/studentType";
import { ADD_HEALTH_SUCCESS } from "Redux/ActionsType/studentType";
import { GET_HEALTH_SUCCESS } from "Redux/ActionsType/studentType";
import { DELETE_STUDENT_SUCCESS } from "Redux/ActionsType/studentType";
import { GET_STUDENT_FAILED } from "Redux/ActionsType/studentType";
import { LOADING_GET_STUDENT } from "Redux/ActionsType/studentType";
import { ADD_STUDENT_SUCCESS } from "Redux/ActionsType/studentType";
import { LOADING_ADD_STUDENT } from "Redux/ActionsType/studentType";

const initialState = {
  loading: false,
  loadingAdd: false,
  loadingDeleteStudent: false,
  students: [],
  error: null,
  healthInfos: [],
};

export const studentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_GET_STUDENT:
      return {
        ...state,
        loading: true,
      };
    case GET_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        students: payload,
      };
    case GET_STUDENT_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case LOADING_ADD_STUDENT:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_STUDENT_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        students: [payload, ...state.students],
      };
    case ADD_STUDENT_FAILED:
      return {
        ...state,
        loadingAdd: false,
        error: payload,
      };
    case LOADING_DELETE_STUDENT:
      return {
        ...state,
        loadingDeleteStudent: true,
      };
    case DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        loadingDeleteStudent: false,
        students: state?.students?.filter(({ id }) => +id !== +payload),
      };
    case DELETE_STUDENT_FAILED:
      return {
        ...state,
        loadingDeleteStudent: false,
        error: payload,
      };

    case UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        students: state.students.map((parent) =>
          parent.id === payload.id ? payload : parent
        ),
      };
    case UPDATE_STUDENT_FAILED:
      return {
        ...state,
        loadingAdd: false,
        error: payload,
      };

    case GET_HEALTH_SUCCESS:
      return {
        ...state,
        loading: false,
        healthInfos: payload,
      };
    case ADD_HEALTH_SUCCESS:
      return {
        ...state,
        loading: false,
        healthInfos: [payload, ...state.healthInfos],
      };
    case GET_HEALTH_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case ADD_HEALTH_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
