import { GET_TEACHERS_FAILED } from "Redux/ActionsType/teacherType";
import { ADD_TEACHERS_FAILED } from "Redux/ActionsType/teacherType";
import { DELETE_TEACHER_SUCCESS } from "Redux/ActionsType/teacherType";
import { LOADING_ADD } from "Redux/ActionsType/teacherType";
import { UPDATE_TEACHER_SUCCESS } from "Redux/ActionsType/teacherType";
import { LOADING_DELETE } from "Redux/ActionsType/teacherType";
import { ADD_TEACHERS_SUCCESS } from "Redux/ActionsType/teacherType";
import { GET_TEACHERS_SUCCESS } from "Redux/ActionsType/teacherType";
import { LOADING_TEACHER } from "Redux/ActionsType/teacherType";

const initialState = {
  loading: false,
  loadingAdd: false,
  loadingDelete: false,
  teachers: null,
  error: null,
};

export const teacherReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_TEACHER:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
