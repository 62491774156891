import {
  CLOSE_CENTRED_MODAL,
  CLOSE_RECLAMATION_MODAL,
  CLOSE_SUCCESS_MODAL,
  OPEN_CENTRED_MODAL,
  OPEN_RECLAMATION_MODAL,
  OPEN_SUCCESS_MODAL,
} from "../ActionsType/modalType";

export const openReclamationModal = () => {
  return { type: OPEN_RECLAMATION_MODAL };
};
export const closeReclamationModal = () => {
  return { type: CLOSE_RECLAMATION_MODAL };
};

export const openCentredModal = (payload) => {
  return { type: OPEN_CENTRED_MODAL, payload: payload };
};
export const closeCentredModal = () => {
  return { type: CLOSE_CENTRED_MODAL };
};

export const openSuccessModal = (content, isSuccess) => {
  return {
    type: OPEN_SUCCESS_MODAL,
    payload: { content: content, isSuccess: isSuccess },
  };
};
export const closeSuccessModal = () => {
  return { type: CLOSE_SUCCESS_MODAL };
};
