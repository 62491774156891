import {
  ADD_FILES_FAILED,
  ADD_FILES_SUCCESS,
  ADD_FOLDER_FAILED,
  ADD_FOLDER_SUCCESS,
  DELETE_FOLDER_FAILED,
  DELETE_FOLDER_SUCCESS,
  GET_GALERIE_FAILED,
  GET_GALERIE_SUCCESS,
  LOADING_ADD,
  LOADING_ADD_FILES,
  LOADING_DELETE_FOLDER,
  LOADING_GALERIE,
} from "../ActionsType/galerieType";

const initialState = {
  loading: false,
  loadingAdd: false,
  loadingAddFiles: false,
  loadingDeleteFolder: false,
  galeries: [],
  error: null,
};

export const galerieReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_GALERIE:
      return {
        ...state,
        loading: true,
      };
    case GET_GALERIE_SUCCESS:
      return { ...state, loading: false, galeries: payload };
    case GET_GALERIE_FAILED:
      return { ...state, loading: false, error: payload };

    case LOADING_ADD:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_FOLDER_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        galeries: [payload, ...state.galeries],
      };
    case ADD_FOLDER_FAILED:
      return { ...state, loadingAdd: false, error: payload };

    case LOADING_DELETE_FOLDER:
      return {
        ...state,
        loadingDeleteFolder: true,
      };
    case DELETE_FOLDER_SUCCESS:
      return {
        ...state,
        loadingDeleteFolder: false,
        galeries: state?.galeries?.filter(({ id }) => +id !== +payload),
      };
    case DELETE_FOLDER_FAILED:
      return { ...state, loadingDeleteFolder: false, error: payload };

    case LOADING_ADD_FILES:
      return {
        ...state,
        loadingAddFiles: true,
      };
    case ADD_FILES_SUCCESS:
      return {
        ...state,
        loadingAddFiles: false,
      };
    case ADD_FILES_FAILED:
      return { ...state, loadingAddFiles: false, error: payload };
    default:
      return state;
  }
};
