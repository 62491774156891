import api from "Constants/api";
import { LOADING_ADD } from "Redux/ActionsType/teacherType";
import { closeCentredModal, openSuccessModal } from "./modalAction";
import { ADD_TEACHERS_SUCCESS } from "Redux/ActionsType/teacherType";
import { UPDATE_TEACHER_SUCCESS } from "Redux/ActionsType/teacherType";
import { UPDATE_TEACHER_FAILED } from "Redux/ActionsType/teacherType";
import { ADD_TEACHERS_FAILED } from "Redux/ActionsType/teacherType";

export const addNewTeacher = (userData) => async (dispatch) => {
  dispatch({ type: LOADING_ADD });
  try {
    const token = localStorage.getItem("token");
    const data = await api.post(`/admin/users`, userData, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: ADD_TEACHERS_SUCCESS, payload: data?.data?.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: ADD_TEACHERS_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const updateTeacher = (id, userData) => async (dispatch) => {
  dispatch({ type: LOADING_ADD });
  try {
    const token = localStorage.getItem("token");
    const data = await api.put(`/admin/users/${id}`, userData, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: UPDATE_TEACHER_SUCCESS, payload: data?.data?.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: UPDATE_TEACHER_FAILED,
      payload: "Update failed.",
    });
  }
};
