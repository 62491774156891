import events from "resources/events";
import {
  ADD_EVENT_FAILED,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_TYPE_SUCCESS,
  DELETE_EVENT_TYPE_SUCCESS,
  GET_CALENDAR_FAILED,
  GET_CALENDAR_SUCCESS,
  GET_EVENTS_SUCCESS,
  LOADING_CALENDAR,
  LOADING_EVENTS,
  LOADING_EVENT_ADD,
  LOADING_EVENT_TYPE_ADD,
  LOADING_EVENT_TYPE_UPDATE,
  UPDATE_EVENT_TYPE_SUCCESS,
} from "../ActionsType/calendarType";

const initialState = {
  loading: false,
    loadingAdd: false,
    loadingEvents: false,
  loadingAddEvents:false,
  calendars: [],
  events: [],
    error: null,
  eventType:[]
};

export const calendarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_CALENDAR:
      return {
        ...state,
        loading: true,
      };
      case LOADING_EVENTS:
      return {
        ...state,
        loadingEvents: true,
      };
      case LOADING_EVENT_TYPE_ADD:
      return {
        ...state,
        loadingAddEvents: true,
      };
    case GET_CALENDAR_SUCCESS:
      return { ...state, loading: false, events: payload };
    case GET_CALENDAR_FAILED:
      return { ...state, loading: false, error: payload };
      case LOADING_EVENT_ADD:
          case LOADING_EVENT_TYPE_UPDATE:
        return {
          ...state,
          loadingAdd: true,
        };
      case ADD_EVENT_SUCCESS:
        return {
          ...state,
          loadingAdd: false,
          events: [payload, ...state.events],
        };
      case ADD_EVENT_FAILED:
        return { ...state, loadingAdd: false, error: payload };
      case GET_EVENTS_SUCCESS:
          return { ...state, loadingEvents: false, eventType: payload };
          case ADD_EVENT_TYPE_SUCCESS:
            return {
              ...state,
              loadingAddEvents: false,
              eventType: [payload, ...state.eventType],
            };
      
            case UPDATE_EVENT_TYPE_SUCCESS:
                return {
                  ...state,
                  loadingAdd: false,
                  eventType: state.eventType.map((event) =>
                    event.id === payload.id ? payload : event
                  ),
                };
                case DELETE_EVENT_TYPE_SUCCESS:
                    return {
                      ...state,
                      eventType: state?.eventType?.filter(({ id }) => +id !== +payload),
                    };
          
    default:
      return state;
  }
};
