import { mapFolderData } from "Constants/utils";
import { addNewFiles } from "Redux/Actions/galerieAction";
import CustomButton from "components/CustomButton";
import CustomSelect from "components/CustomSelect";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Upload from "views/admin/profile/components/Upload";

const NewFiles = ({ folders }) => {
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState();
  const [selected, setSelected] = useState(folders[0].id);
  const { loadingAddFiles } = useSelector((state) => state.galerieReducer);
  const handleSubmit = () => {
    const data = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      // data.append("field", `images[${i}]`);
      data.append(`images[${i}]`, file);
    }
    dispatch(addNewFiles(selected, data));
  };
  return (
    <>
      <div className="m-1">
        <CustomSelect
          data={mapFolderData(folders)}
          selected={selected}
          setSelected={(value) => setSelected(value)}
          label={"Choose Folder"}
          required={true}
        />
      </div>
      <div className="flex">
        <Upload uploadedFiles={fileList} setUploadedFiles={setFileList} />
      </div>
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={"Ajouter"}
            disabled={!fileList || !selected}
            onClick={handleSubmit}
            loading={loadingAddFiles}
          />
        </div>
      </div>
    </>
  );
};

export default NewFiles;
