import { GET_PROGRAM_FAILED } from "Redux/ActionsType/programType";
import { ADD_PROGRAM_SUCCESS } from "Redux/ActionsType/programType";
import { LOADING_ADD } from "Redux/ActionsType/programType";
import { GET_PROGRAM_SUCCESS } from "Redux/ActionsType/programType";
import { LOADING_PROGRAM } from "Redux/ActionsType/programType";
import { closeCentredModal, openSuccessModal } from "./modalAction";
import { ADD_PROGRAM_FAILED } from "Redux/ActionsType/programType";
import { DELETE_PROGRAM_SUCCESS } from "Redux/ActionsType/programType";
import { DELETE_PROGRAM_FAILED } from "Redux/ActionsType/programType";
import api from "Constants/api";
import { LOADING_UPDATE } from "Redux/ActionsType/programType";
import { UPDATE_PROGRAM_SUCCESS } from "Redux/ActionsType/programType";
import { UPDATE_PROGRAM_FAILED } from "Redux/ActionsType/programType";
import { LOADING_DELETE_PROG } from "Redux/ActionsType/programType";

export const getAllPrograms = () => async (dispatch) => {
  dispatch({ type: LOADING_PROGRAM });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.get(`/admin/programs`, {
      headers: { authorization: `Bearer ${token}` },
    });

    dispatch({ type: GET_PROGRAM_SUCCESS, payload: data?.data });
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: GET_PROGRAM_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const addNewProgram = (cantineData) => async (dispatch) => {
  dispatch({ type: LOADING_ADD });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.post(`/admin/programs`, cantineData, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: ADD_PROGRAM_SUCCESS, payload: data?.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    dispatch({
      type: ADD_PROGRAM_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const deleteProgram = (id) => async (dispatch) => {
  dispatch({ type: LOADING_DELETE_PROG });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.delete(
      `/admin/programs/${id}`,

      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch({ type: DELETE_PROGRAM_SUCCESS, payload: id });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    dispatch({
      type: DELETE_PROGRAM_FAILED,
      payload: "Fialed .",
    });
  }
};

export const updateProgram = (id, cantineData) => async (dispatch) => {
  dispatch({ type: LOADING_UPDATE });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.put(`/admin/programs/${id}`, cantineData, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: UPDATE_PROGRAM_SUCCESS, payload: data?.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    dispatch({
      type: UPDATE_PROGRAM_FAILED,
      payload: "Update failed.",
    });
  }
};
