import { GET_NOTIF_FAILED } from "Redux/ActionsType/notificationType";
import { ADD_NOTIF_SUCCESS } from "Redux/ActionsType/notificationType";
import { LOADING_DELETE_NOTIF } from "Redux/ActionsType/notificationType";
import { DELETE_NOTIF_FAILED } from "Redux/ActionsType/notificationType";
import { DELETE_NOTIF_SUCCESS } from "Redux/ActionsType/notificationType";
import { ADD_NOTIF_FAILED } from "Redux/ActionsType/notificationType";
import { LOADING_ADD_NOTIF } from "Redux/ActionsType/notificationType";
import { GET_NOTIF_SUCCESS } from "Redux/ActionsType/notificationType";
import { LOADING_NOTIF } from "Redux/ActionsType/notificationType";

const initialState = {
  loading: false,
  loadingAdd: false,
  loadingDeleteNotif: false,
  notifs: [],
  error: null,
};

export const notifReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_NOTIF:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIF_SUCCESS:
      return { ...state, loading: false, notifs: payload };
    case GET_NOTIF_FAILED:
      return { ...state, loading: false, error: payload };

    case LOADING_ADD_NOTIF:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_NOTIF_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        notifs: [payload, ...state.notifs],
      };
    case ADD_NOTIF_FAILED:
      return { ...state, loadingAdd: false, error: payload };

    case LOADING_DELETE_NOTIF:
      return {
        ...state,
        loadingDeleteNotif: true,
      };
    case DELETE_NOTIF_SUCCESS:
      return {
        ...state,
        loadingDeleteNotif: false,
        notifs: state?.notifs?.filter(({ id }) => +id !== +payload),
      };
    case DELETE_NOTIF_FAILED:
      return { ...state, loadingDeleteNotif: false, error: payload };

    default:
      return state;
  }
};
