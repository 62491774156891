import { LOADING_ADD } from "Redux/ActionsType/parentType";
import { LOADING_DELETE } from "Redux/ActionsType/parentType";
import { UPDATE_PARENT_SUCCESS } from "Redux/ActionsType/parentType";
import { DELETE_PARENT_FAILED } from "Redux/ActionsType/parentType";
import { UPDATE_PARENT_FAILED } from "Redux/ActionsType/parentType";
import { DELETE_PARENT_SUCCESS } from "Redux/ActionsType/parentType";
import { ADD_PARENTS_FAILED } from "Redux/ActionsType/parentType";
import { ADD_PARENTS_SUCCESS } from "Redux/ActionsType/parentType";
import {
  LOADING_PARENT,
  GET_PARENTS_SUCCESS,
  GET_PARENTS_FAILED,
} from "Redux/ActionsType/parentType";

const initialState = {
  loading: false,
  loadingAdd: false,
  loadingDelete: false,
  parents: null,
  error: null,
};

export const parentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_PARENT:
      return {
        ...state,
        loading: true,
      };
    // // case GET_PARENTS_SUCCESS:
    // //   return { ...state, loading: false, parents: payload };
    // // case GET_PARENTS_FAILED:
    // //   return { ...state, loading: false, error: payload };

    // // case LOADING_ADD:
    // //   return {
    // //     ...state,
    // //     loadingAdd: true,
    // //   };
    // // case ADD_PARENTS_SUCCESS:
    // //   return {
    // //     ...state,
    // //     loadingAdd: false,
    // //     parents: [payload, ...state.parents],
    // //   };
    // // case ADD_PARENTS_FAILED:
    // //   return { ...state, loadingAdd: false, error: payload };

    // // case LOADING_DELETE:
    // //   return {
    // //     ...state,
    // //     loadingDelete: true,
    // //   };
    // // case DELETE_PARENT_SUCCESS:
    // //   return {
    // //     ...state,
    // //     loadingDelete: false,
    // //     // parents: [payload, ...state.parents],
    // //     parents: state?.parents?.filter(({ id }) => +id !== +payload),
    // //   };
    // // case DELETE_PARENT_FAILED:
    // //   return { ...state, loadingDelete: false, error: payload };

    // case UPDATE_PARENT_SUCCESS:
    //   return {
    //     ...state,
    //     loadingAdd: false,
    //     parents: state.parents.map((parent) =>
    //       parent.id === payload.id ? payload : parent
    //     ),
    //   };
    // case UPDATE_PARENT_FAILED:
    //   return { ...state, loadingAdd: false, error: payload };

    default:
      return state;
  }
};
