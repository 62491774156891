import {
  ADD_CANTINE_FAILED,
  ADD_CANTINE_SUCCESS,
  DELETE_CANTINE_FAILED,
  DELETE_CANTINE_SUCCESS,
  GET_CANTINE_FAILED,
  GET_CANTINE_SUCCESS,
  LOADING_ADD,
  LOADING_CANTINE,
  LOADING_DELETE,
  LOADING_UPDATE,
  UPDATE_CANTINE_FAILED,
  UPDATE_CANTINE_SUCCESS,
} from "../ActionsType/cantineType";
import api from "../../Constants/api";
import { closeCentredModal, openSuccessModal } from "./modalAction";

export const getAllCantine = () => async (dispatch) => {
  dispatch({ type: LOADING_CANTINE });
  try {
    const token = localStorage.getItem("token");
    const data = await api.get(`/cantines`, {
      headers: { authorization: `Bearer ${token}` },
    });

    dispatch({ type: GET_CANTINE_SUCCESS, payload: data?.data?.data });
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: GET_CANTINE_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const addNewCantine = (cantineData) => async (dispatch) => {
  dispatch({ type: LOADING_ADD });
  try {
    const token = localStorage.getItem("token");
    const data = await api.post(
      `/admin/cantine`,
      { day: cantineData.day, menu: cantineData.menu },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch({ type: ADD_CANTINE_SUCCESS, payload: data?.data?.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    dispatch({
      type: ADD_CANTINE_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const deleteCantine = (id) => async (dispatch) => {
  dispatch({ type: LOADING_DELETE });
  try {
    const token = localStorage.getItem("token");
    const data = await api.delete(
      `/admin/cantine/${id}`,

      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch({ type: DELETE_CANTINE_SUCCESS, payload: id });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    dispatch({
      type: DELETE_CANTINE_FAILED,
      payload: "Fialed .",
    });
  }
};

export const updateCantine = (id, cantineData) => async (dispatch) => {
  dispatch({ type: LOADING_UPDATE });
  try {
    const token = localStorage.getItem("token");
    const data = await api.put(`/admin/users/${id}`, cantineData, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: UPDATE_CANTINE_SUCCESS, payload: data?.data?.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    dispatch({
      type: UPDATE_CANTINE_FAILED,
      payload: "Update failed.",
    });
  }
};

// // Set logged in user
// export const setCurrentUser = (decoded) => {
//   return {
//     type: SET_CURRENT_USER,
//     payload: decoded,
//   };
// };

// // Logout user
