import { getFeedback } from "Redux/Actions/feedbackAction";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnsDataFeedback } from "../parents/variables/columnsData";
import DevelopmentTable from "./components/DevelopmentTable";

const FeedbackView = () => {
  const dispatch = useDispatch();
  const { loading, feedbacks } = useSelector((state) => state.feedBackReducer);
  useEffect(() => {
    dispatch(getFeedback());
  }, []);
  return (
    <div>
      <div className="mt-5  h-full ">
        <DevelopmentTable
          columnsData={columnsDataFeedback}
          tableData={feedbacks || []}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default FeedbackView;
