export const LOADING_CALENDAR = "LOADING_CALENDAR";
export const GET_CALENDAR_SUCCESS = "GET_CALENDAR_SUCCESS";
export const GET_CALENDAR_FAILED = "GET_CALENDAR_FAILED";

export const LOADING_EVENT_ADD = "LOADING_EVENT_ADD";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAILED = "ADD_EVENT_FAILED";

export const LOADING_EVENTS = "LOADING_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILED = "GET_EVENTS_FAILED";

export const LOADING_EVENT_TYPE_ADD = "LOADING_EVENT_TYPE_ADD";
export const ADD_EVENT_TYPE_SUCCESS = "ADD_EVENT_TYPE_SUCCESS";
export const ADD_EVENT_TYPE_FAILED = "ADD_EVENT_TYPE_FAILED";

export const LOADING_EVENT_TYPE_UPDATE = "LOADING_EVENT_TYPE_UPDATE";
export const UPDATE_EVENT_TYPE_SUCCESS = "UPDATE_EVENT_TYPE_SUCCESS";
export const UPDATE_EVENT_TYPE_FAILED = "UPDATE_EVENT_TYPE_FAILED";

export const LOADING_EVENT_TYPE_DELETE = "LOADING_EVENT_TYPE_DELETE";
export const DELETE_EVENT_TYPE_SUCCESS = "DELETE_EVENT_TYPE_SUCCESS";
export const DELETE_EVENT_TYPE_FAILED = "DELETE_EVENT_TYPE_FAILED";