import { mapParentData } from "Constants/utils";
import { assistanceSecondaireformData } from "Constants/utils";
import { assistanceformData } from "Constants/utils";
import { parentData } from "Constants/utils";
import { getUserWithRole } from "Redux/Actions/userAction";
import CustomSelect from "components/CustomSelect";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Step1 = ({
  formData,
  setFormData,
  validationErrors,
  secAssist,
  setSecAssist,
  validationAssistSecErrors,
}) => {
  const dispatch = useDispatch();
  const { parents, loadingUsers } = useSelector((state) => state.userReducer);
  const [prenom, setprenom] = useState("");
  const [phone, setphone] = useState("");

  const updateCombinedValue = () => {
    const newCombinedValue = prenom + phone;
    setFormData({
      ...formData,
      password: newCombinedValue,
      prenom: prenom,
      phone: phone,
    });
  };

  useEffect(() => {
    updateCombinedValue();
  }, [prenom, phone]);

  const handlePasswordChange = (e) => {
    setFormData({ ...formData, password: e });
  };

  const handleChange = (value, field) => {
    setFormData({ ...formData, [field]: value[field] });
  };
  const handleChangeSec = (value, field) => {
    setSecAssist({ ...secAssist, [field]: value[field] });
  };
  useEffect(() => {
    dispatch(getUserWithRole("parent"));
  }, []);
  const realationData = [
    { id: 1, value: "Pere" },
    { id: 2, value: "mere" },
  ];
  return (
    <div className="block h-[100%] w-full items-center justify-center">
      <p className="mx-4 mb-3 font-semibold text-navy-700">
        Sélectionner un parent
      </p>

      <div>
        <CustomSelect
          data={mapParentData(parents, true)}
          setSelected={(value) => handleChange(value, "user_id")}
          label={"Parent"}
          field={"user_id"}
        />
      </div>
      <div className="my-5 flex items-center">
        <hr className="flex-grow border-t border-gray-400" />
        <p className="mx-4 font-semibold text-navy-700">ou</p>
        <hr className="flex-grow border-t border-gray-400" />
      </div>
      <div className="flex w-[100%] justify-center">
        <div className="w-[100%]">
          <p className="mx-4 mb-3 font-semibold text-navy-700">
            Responsable Principale
          </p>
          <div className="flex ">
            <InputField
              extra="mb-3 w-[100%]"
              label={"Prénom"}
              placeholder={"prenom"}
              type={"text"}
              field={"prenom"}
              required={true}
              defaultValue={formData["prenom"]}
              setValue={(value) => setprenom(value["prenom"])}
              totalInputs={formData}
              errMessage={validationErrors["prenom"]}
            />
            <InputField
              extra="mb-3 w-[100%]"
              label={"Nom"}
              placeholder={"Nom"}
              type={"text"}
              field={"nom"}
              required={true}
              defaultValue={formData["nom"]}
              setValue={(value) => handleChange(value, "nom")}
              totalInputs={formData}
              errMessage={validationErrors["nom"]}
            />
          </div>
          <InputField
            extra="mb-3 w-[100%]"
            label={"Email"}
            placeholder={"email"}
            type={"text"}
            field={"email"}
            required={false}
            defaultValue={formData["email"]}
            setValue={(value) => handleChange(value, "email")}
            totalInputs={formData}
            errMessage={validationErrors["email"]}
          />
          <InputField
            extra="mb-3 w-[100%]"
            label={"Numéro de téléphone"}
            placeholder={"phone"}
            type={"number"}
            field={"phone"}
            required={true}
            defaultValue={formData["phone"]}
            setValue={(value) => setphone(value["phone"])}
            totalInputs={formData}
            errMessage={validationErrors["phone"]}
          />
          <InputField
            extra="mb-3 w-[100%]"
            label={"Mot de pass"}
            placeholder={"password"}
            type={"text"}
            field={"password"}
            required={true}
            defaultValue={formData["password"]}
            setValue={(value) => handlePasswordChange(value["password"])}
            totalInputs={formData}
            errMessage={validationErrors["password"]}
          />
        </div>
        <div className="w-[100%] ms-1 ">
          <p className="mx-4 mb-1 font-semibold text-navy-700">
            Responsable secondaire
          </p>
          {assistanceSecondaireformData.map(
            ({ label, placeholder, type, field, required }, index) => {
              if (type === "radio") {
                return null;
              } else {
                return (
                  <InputField
                    key={index}
                    extra="mb-3 "
                    label={label}
                    placeholder={placeholder}
                    type={type}
                    field={field}
                    required={required}
                    defaultValue={secAssist[field]}
                    setValue={(value) => handleChangeSec(value, field)}
                    totalInputs={secAssist}
                    errMessage={validationAssistSecErrors[field]}
                  />
                );
              }
            }
          )}
          <div className=" flex flex-col">
            <label
              className={`mb-1 text-sm text-navy-700 dark:text-white ${"ml-3 font-bold"}`}
            >
              Relation {true && <span className="text-red-600"> *</span>}
            </label>
            <div className=" flex">
              {realationData.map(({ id, value }) => {
                return (
                  <div key={id} className="mx-2 flex">
                    <label
                      className={`text-sm text-navy-700 dark:text-white ${"ml-1 font-bold"}`}
                    >
                      {value}
                    </label>
                    <input
                      type="radio"
                      id={id}
                      name={value}
                      value={value}
                      checked={secAssist?.relation === value}
                      onChange={() => {
                        setSecAssist({ ...secAssist, relation: value });
                      }}
                      className="form-radio ml-2 h-5 w-5 text-indigo-600"
                    />
                  </div>
                );
              })}
            </div>
            {validationAssistSecErrors["relation"] && (
              <div className=" mt-1 px-3 text-red-500">
                {validationAssistSecErrors["relation"]}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
