export const authData = [
  {
    label: "Phone*",
    placeholder: "12345678",
    type: "text",
    field: "phone",
  },
  {
    label: "Password*",
    placeholder: "Min. 8 characters",
    type: "password",
    field: "password",
  },
];

export const programData = [
  {
    label: "Nom",
    type: "text",
    field: "name",
    required: true,
    placeholder: "Nom",
  },
  {
    label: "Description",
    type: "text",
    field: "description",
    required: true,
    placeholder: "description",
  },
  {
    label: "Date",
    type: "date",
    field: "start_date",
    required: true,
  },
];

export const cantineData = [
  {
    label: "Day",
    type: "date",
    field: "day",
    required: true,
  },
  {
    label: "Menu",
    type: "text",
    field: "menu",
    required: true,
  },
];

export const teacherData = [
  {
    label: "Nom",
    placeholder: "jhon doe",
    type: "text",
    field: "name",
    required: true,
    errorMessage: "Name is required !",
  },
  {
    label: "Email",
    placeholder: "mail@simmmple.com",
    type: "text",
    field: "email",
    required: false,
  },
  {
    label: "Numéro de téléphone",
    placeholder: "phone",
    type: "number",
    field: "phone",
    required: true,
  },
  {
    label: "Mot de passe",
    placeholder: "Min. 8 characters",
    type: "password",
    field: "password",
    required: true,
  },
  {
    label: "Select classes",
    type: "select",
    field: "class",
    required: false,
  },
];

export const parentData = [
  {
    label: "Prénom",
    placeholder: "jhon doe",
    type: "text",
    field: "first_name",
    required: true,
    errorMessage: "Prenom is required !",
  },
  {
    label: "Nom",
    placeholder: "jhon doe",
    type: "text",
    field: "last_name",
    required: true,
    errorMessage: "Nom is required !",
  },
  {
    label: "Email",
    placeholder: "mail@simmmple.com",
    type: "text",
    field: "email",
    required: false,
  },
  {
    label: "Numéro de téléphone",
    placeholder: "phone",
    type: "number",
    field: "phone",
    required: true,
  },
  {
    label: "Mot de passe",
    placeholder: "Min. 8 characters",
    type: "password",
    field: "password",
    required: true,
  },
  // {
  //   label: "Select classes",
  //   type: "select",
  //   field: "class",
  //   required: true,
  // },
];

export const isMail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test(mail)) {
    return true;
  }
  return false;
};

export const studentsData = [
  { id: 1, name: "student1", class: "class1" },
  { id: 2, name: "student2", class: "class1" },
  { id: 3, name: "student3", class: "class2" },
  { id: 4, name: "student4", class: "class2" },
  { id: 5, name: "student5", class: "class3" },
  { id: 6, name: "student6", class: "class1" },
];

export const classData = [
  { id: 1, value: "class1", label: "class1" },
  { id: 2, value: "class2", label: "class2" },
  { id: 3, value: "class3", label: "class3" },
];

export const classformData = [
  {
    label: "Nom",
    placeholder: "className",
    type: "text",
    field: "name",
    required: true,
    errorMessage: "Name is required !",
  },
  {
    label: "Description",
    placeholder: "description",
    type: "text",
    field: "description",
    required: true,
  },
];

export const studentformData = [
  {
    label: "Prénom",
    placeholder: "Prénom",
    type: "text",
    field: "first_name",
    required: true,
    errorMessage: "first name is required !",
  },
  {
    label: "Nom",
    placeholder: "Nom",
    type: "text",
    field: "last_name",
    required: true,
    errorMessage: "last name is required !",
  },
  {
    label: "Date de naissance",
    placeholder: "birthday",
    type: "date",
    field: "birthday",
    required: true,
    errorMessage: "birthday is required !",
  },
  {
    label: "class",
    placeholder: "class",
    type: "select",
    field: "classe_id",
    required: true,

    errorMessage: "class is required !",
  },
  {
    label: "parent",
    placeholder: "parent",
    type: "select",
    field: "user_id",
    required: true,
    errorMessage: "parent is required !",
  },
];

export const billingForm = [
  {
    id: 1,
    label: "Date debut",
    type: "date",
    field: "startDate",
    required: true,
    errorMessage: "start date is required !",
  },
  {
    id: 2,

    label: "Date fin",
    type: "date",
    field: "endDate",
    required: true,
    errorMessage: "end date is required !",
  },
];
export const assistanceformData = [
  {
    label: "Prénom",
    placeholder: "Prénom",
    type: "text",
    field: "name",
    required: true,
    errorMessage: "first name is required !",
  },
  {
    label: "Nom",
    placeholder: "Nom",
    type: "text",
    field: "surname",
    required: true,
    errorMessage: "last name is required !",
  },
  {
    label: "Relation",
    placeholder: "relation",
    type: "text",
    field: "relation",
    required: true,
    errorMessage: "relation is required !",
  },
  {
    label: "Téléphone",
    placeholder: "phone",
    type: "tel",
    field: "phone",
    required: true,
    errorMessage: "phone is required !",
  },
];
export const assistanceSecondaireformData = [
  {
    label: "Prénom",
    placeholder: "Prénom",
    type: "text",
    field: "name",
    required: true,
    errorMessage: "first name is required !",
  },
  {
    label: "Nom",
    placeholder: "Nom",
    type: "text",
    field: "surname",
    required: true,
    errorMessage: "last name is required !",
  },
  {
    label: "Numéro de téléphone",
    placeholder: "phone",
    type: "tel",
    field: "phone",
    required: true,
    errorMessage: "phone is required !",
  },
  {
    label: "Email",
    placeholder: "mail@simmmple.com",
    type: "text",
    field: "email",
    required: false,
  },
  {
    label: "Relation",
    type: "radio",
    field: "relation",
    required: true,
  },
];
export const mapClassData = (classData, attachId) => {
  return classData?.map((el) => ({
    label: el?.name,
    value: attachId === true ? el?.id : el?.name,
  }));
};

export const mapParentData = (parentData, attachId) => {
  return parentData?.map((el) => ({
    label: el?.name,
    value: attachId === true ? el?.id : el?.name,
  }));
};
export const mapEventData = (eventData, attachId) => {
    return eventData?.map((el) => ({
      label: el?.name,
      value: attachId === true ? el?.id : el?.name,
    }));
  };
export const mapStudentData = (students, attachId) => {
  return students?.map((el) => ({
    label: el?.first_name + el?.last_name,
    value: attachId === true ? el?.id : el?.name,
  }));
};
export const mapServiceData = (services, attachId) => {
  return services?.map((el) => ({
    label: el?.name,
    value: attachId === true ? el?.id : el?.name,
  }));
};

export const mapFolderData = (folderData) => {
  return folderData?.map((el) => ({
    label: el?.folderName,
    value: el?.id,
  }));
};

export const reclamationData = [
  { id: 1, text: "Remerciement" },
  { id: 2, text: "Proposition d'activité" },
  { id: 3, text: "Propositionde menu" },
  { id: 4, text: "Reclamation" },
  { id: 5, text: "Autre" },
];
export const mapCalendar = (events) => {
  return events.map((event) => ({
    color: event?.color ? event?.color : "#3788d8",
    title: event?.title,
    content: event?.content,
    duration: event?.duration,
    start: event?.start,
    end: addDuration(event?.start, event?.duration),
  }));
};

export const mapCalendarObject = (eventsObj) => {
  return {
    color: eventsObj?.color ? eventsObj?.color : "#3788d8",
    title: eventsObj?.title,
    content: eventsObj?.content,
    duration: eventsObj?.duration,
    start: eventsObj?.start,
    end: addDuration(eventsObj?.start, eventsObj?.duration),
  };
};

function addDuration(startDateTime, duration) {
  var start = new Date(startDateTime); // Convert the start date and time to a Date object
  var end = new Date(start.getTime() + duration * 24 * 60 * 60 * 1000); // Calculate the end date by adding the duration in milliseconds

  // Format the end date components
  var year = end.getFullYear();
  var month = String(end.getMonth() + 1).padStart(2, "0");
  var day = String(end.getDate()).padStart(2, "0");
  var hours = String(end.getHours()).padStart(2, "0");
  var minutes = String(end.getMinutes()).padStart(2, "0");
  var seconds = String(end.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
export const EventFormData = [
  {
    label: "Tite",
    placeholder: "Title",
    type: "text",
    field: "title",
    required: true,
    errorMessage: "Title  is required !",
  },
  {
    label: "content",
    placeholder: "content",
    type: "text",
    field: "description",
    required: true,
  },
];
export const mapBilling = (billings) => {
  return [...(billings || [])].map((d) => {
    d.date = new Date(d.date);

    return d;
  });
};

export const mapServiceTypeData = (serviceTypeData, attachId) => {
  return serviceTypeData?.map((el) => ({
    label: el?.name,
    value: attachId === true ? el?.id : el?.name,
  }));
};

export const ServiceTypeFormData = [
  {
    label: "Name",
    placeholder: "Name",
    type: "text",
    field: "name",
    required: true,
    errorMessage: "Name  is required !",
  },
  {
    label: "Description",
    placeholder: "Description",
    type: "text",
    field: "description",
    required: true,
    errorMessage: "Description  is required !",
  },
];

export const ServiceFormData = [
  {
    label: "Name",
    placeholder: "Name",
    type: "text",
    field: "name",
    required: true,
    errorMessage: "Name  is required !",
  },
  {
    label: "Description",
    placeholder: "Description",
    type: "text",
    field: "description",
    required: true,
    errorMessage: "Description  is required !",
  },
  {
    label: "Price",
    placeholder: "Price",
    type: "number",
    field: "price",
    required: true,
  },
  {
    label: "Service type",
    placeholder: "Price",
    type: "select",
    field: "service_type_id",
    required: true,
  },

  {
    label: "Status",
    type: "select",
    field: "status",
    required: true,
  },
];

export const trimesterData = [
  { id: 1, label: "First Trimester", value: 1 },
  { id: 2, label: "Second Trimester", value: 2 },
  { id: 3, label: "Third Trimester", value: 3 },
];

export const statusData = [
  { id: 1, label: "Actif", value: 1 },
  { id: 1, label: "Inactif", value: 0 },
];

export const inscriptionformData = [
  {
    label: "Prénom",
    placeholder: "Prénom",
    type: "text",
    field: "first_name",
    required: true,
    errorMessage: "first name is required !",
  },
  {
    label: "Nom",
    placeholder: "Nom",
    type: "text",
    field: "last_name",
    required: true,
    errorMessage: "last name is required !",
  },
  {
    label: "Date de naissance",
    placeholder: "birthday",
    type: "date",
    field: "birthday",
    required: true,
    errorMessage: "birthday is required !",
  },
  {
    label: "class",
    placeholder: "class",
    type: "select",
    field: "classe_id",
    required: true,

    errorMessage: "class is required !",
  },
  {
    label: "parent",
    placeholder: "parent",
    type: "select",
    field: "user_id",
    errorMessage: "parent is required !",
  },
  {
    label: "Gender",
    placeholder: "gender",
    type: "select",
    field: "gender",
    required: true,
    errorMessage: "gender is required !",
  },
];
