export const LOADING_CANTINE = "LOADING_CANTINE";
export const GET_CANTINE_SUCCESS = "GET_CANTINE_SUCCESS";
export const GET_CANTINE_FAILED = "GET_CANTINE_FAILED";

export const LOADING_ADD = "LOADING_ADD";
export const ADD_CANTINE_SUCCESS = "ADD_CANTINE_SUCCESS";
export const ADD_CANTINE_FAILED = "ADD_CANTINE_FAILED";

export const LOADING_DELETE = "LOADING_DELETE";
export const DELETE_CANTINE_SUCCESS = "DELETE_CANTINE_SUCCESS";
export const DELETE_CANTINE_FAILED = "DELETE_CANTINE_FAILED";

export const LOADING_UPDATE = "LOADING_UPDATE";
export const UPDATE_CANTINE_SUCCESS = "UPDATE_CANTINE_SUCCESS";
export const UPDATE_CANTINE_FAILED = "UPDATE_CANTINE_FAILED";
