import CheckTable from "./components/CheckTable";

import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "./variables/columnsData";
import tableDataDevelopment from "./variables/tableDataDevelopment.json";

import DevelopmentTable from "./components/DevelopmentTable";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserWithRole } from "Redux/Actions/userAction";
import { getAllCantine } from "Redux/Actions/cantineAction";

const Tables = () => {
  const dispatch = useDispatch();
  const { cantines, loading } = useSelector((state) => state.cantineReducer);

  useEffect(() => {
    dispatch(getAllCantine());
  }, []);
  return (
    <div>
      <div className="mt-5  h-full ">
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={cantines || []}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Tables;
