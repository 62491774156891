import api from "Constants/api";
import { ADD_STUDENT_FAILED } from "Redux/ActionsType/studentType";
import { ADD_STUDENT_SUCCESS } from "Redux/ActionsType/studentType";
import { LOADING_ADD_STUDENT } from "Redux/ActionsType/studentType";
import { closeCentredModal, openSuccessModal } from "./modalAction";
import { LOADING_GET_STUDENT } from "Redux/ActionsType/studentType";
import { GET_STUDENT_SUCCESS } from "Redux/ActionsType/studentType";
import { GET_STUDENT_FAILED } from "Redux/ActionsType/studentType";
import { LOADING_DELETE_STUDENT } from "Redux/ActionsType/studentType";
import { DELETE_STUDENT_SUCCESS } from "Redux/ActionsType/studentType";
import { DELETE_STUDENT_FAILED } from "Redux/ActionsType/studentType";
import { ADD_PARENTS_SUCCESS } from "Redux/ActionsType/parentType";
import { GET_HEALTH_SUCCESS } from "Redux/ActionsType/studentType";
import { GET_HEALTH_FAILED } from "Redux/ActionsType/studentType";
import { ADD_HEALTH_SUCCESS } from "Redux/ActionsType/studentType";
import { ADD_HEALTH_FAILED } from "Redux/ActionsType/studentType";
import { LOADING_UPDATE_STUDENT } from "Redux/ActionsType/studentType";
import { UPDATE_STUDENT_SUCCESS } from "Redux/ActionsType/studentType";
import { UPDATE_STUDENT_FAILED } from "Redux/ActionsType/studentType";

export const getStudentByClass = (id) => async (dispatch) => {
  dispatch({ type: LOADING_GET_STUDENT });
  try {
    const token = localStorage.getItem("token");
    const data = await api.get(`/admin/studentByclass/${id}`, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: GET_STUDENT_SUCCESS, payload: data?.data?.data });
    dispatch(closeCentredModal());
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: GET_STUDENT_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};
export const getHealthInfo = () => async (dispatch) => {
  dispatch({ type: LOADING_GET_STUDENT });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.get(`/admin/healthInformations`, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: GET_HEALTH_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: GET_HEALTH_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const aaHealthInfo = (formData, callback) => async (dispatch) => {
  dispatch({ type: LOADING_GET_STUDENT });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.post(
      `/admin/healthInformations`,
      { ...formData },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    if (callback) {
      callback();
    } else {
      dispatch({ type: ADD_HEALTH_SUCCESS, payload: data?.data });
      dispatch(closeCentredModal());
    }
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: ADD_HEALTH_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const addNewStudent = (userData, callback) => async (dispatch) => {
  dispatch({ type: LOADING_ADD_STUDENT });

  try {
    const token = localStorage.getItem("token");
    const data = await api.post(
      `/admin/students`,
      { ...userData },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch({ type: ADD_STUDENT_SUCCESS, payload: data?.data?.data });
    if (callback) {
      callback();
    }
    dispatch(closeCentredModal());
  } catch (error) {
    console.log(error);
    dispatch({
      type: ADD_STUDENT_FAILED,
      payload: error,
    });
  }
};
export const addParentGrantedAccesses =
  (userData, callback) => async (dispatch) => {
    dispatch({ type: LOADING_ADD_STUDENT });

    try {
      const token = localStorage.getItem("token");
      const { data } = await api.post(
        `parentGrantedAccesses`,
        { ...userData },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: ADD_STUDENT_FAILED,
        payload: "Unauthorized: Please check your credentials.",
      });
    }
  };

export const inscription = (studentData, parentData) => async (dispatch) => {
  try {
    let newParent;
    if (!studentData.user_id) {
      const token = localStorage.getItem("token");
      newParent = await api.post(
        `/admin/users`,
        { ...parentData, role: "parent" },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
    }
    dispatch({ type: ADD_PARENTS_SUCCESS, payload: newParent?.data?.data });
    const newStudentData = {
      ...studentData,
      user_id: newParent?.data?.data?.id,
    };
    dispatch(addNewStudent(studentData.user_id ? studentData : newStudentData));
  } catch (error) {
    console.log(error);
  }
};

export const deleteStudent = (id) => async (dispatch) => {
  dispatch({ type: LOADING_DELETE_STUDENT });
  try {
    const token = localStorage.getItem("token");
    const data = await api.delete(
      `/admin/students/${id}`,

      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch({ type: DELETE_STUDENT_SUCCESS, payload: id });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: DELETE_STUDENT_FAILED,
      payload: "Fialed .",
    });
  }
};

export const getAllStudent = () => async (dispatch) => {
  dispatch({ type: LOADING_GET_STUDENT });
  try {
    const token = localStorage.getItem("token");
    const data = await api.get(`/admin/students`, {
      headers: { authorization: `Bearer ${token}` },
    });

    dispatch({ type: GET_STUDENT_SUCCESS, payload: data?.data?.data });
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: GET_STUDENT_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const updateStudent = (userData, id) => async (dispatch) => {
  dispatch({ type: LOADING_UPDATE_STUDENT });

  try {
    const token = localStorage.getItem("token");
    const data = await api.put(
      `/admin/students/${id}`,
      { ...userData },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch({ type: UPDATE_STUDENT_SUCCESS, payload: data?.data?.data });

    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: UPDATE_STUDENT_FAILED,
      payload: error,
    });
  }
};
