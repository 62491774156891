import { closeCentredModal } from "Redux/Actions/modalAction";
import { useDispatch, useSelector } from "react-redux";

const CentredModal = () => {
  const dispatch = useDispatch();
  const { openCentredModal, headerText, content } = useSelector(
    (state) => state.modalReducer
  );
  return (
    <div
      id="defaultModal"
      tabIndex="-1"
      aria-hidden="true"
      className={` fixed z-50 ${
        openCentredModal ? "flex" : "hidden"
      }  bg-transparent h-[calc(100%-1rem)] max-h-full  w-full items-center justify-center overflow-y-auto overflow-x-hidden p-4 md:inset-0`}
    >
      <div className="relative max-h-full w-full max-w-2xl shadow-lg">
        <div className="relative rounded-lg bg-white shadow dark:bg-navy-600">
          <div className="flex items-start justify-between rounded-t border-b p-4 dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {headerText}
            </h3>
            <button
              type="button"
              className="bg-transparent ml-auto inline-flex items-center rounded-lg p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => dispatch(closeCentredModal())}
            >
              <svg
                aria-hidden="true"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="space-y-6 p-6">{content}</div>
        </div>
      </div>
    </div>
  );
};
export default CentredModal;
