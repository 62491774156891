import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useState } from "react";
import { authData } from "Constants/utils";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "Redux/Actions/userAction";
import { useNavigate } from "react-router-dom";
// import siginImg from "../../assets/img/auth/auth.jpg";
import siginImg from "../../assets/img/auth/log.jpg";
import { useSelect } from "@material-tailwind/react";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const { loading } = useSelector((state) => state.userReducer);
  const handleSubmit = () => {
    dispatch(
      loginUser(userData, () => navigate("/admin/dashboard", { replace: true }))
    );
  };
  return (
    <div className="mt-5  flex h-full w-full flex-col-reverse items-center justify-between px-9 md:flex-row  lg:items-center ">
      {/* Sign in section */}
      <div className="  w-[420px] max-w-full flex-col  items-center md:pl-4 lg:pl-0">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>

        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        {authData.map(({ label, placeholder, type, field }, index) => (
          <InputField
            key={index}
            extra="mb-3"
            label={label}
            placeholder={placeholder}
            type={type}
            field={field}
            setValue={setUserData}
            totalInputs={userData}
          />
        ))}
        {/* Email */}

        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          {/* <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a> */}
        </div>
        <button
          onClick={handleSubmit}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          {loading ? "loading..." : "Sign In"}
        </button>
        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
      <div className=" flex  w-[420px] items-center justify-center  md:pr-4 lg:max-w-[420px]  ">
        <img
          src={siginImg}
          alt="Logo"
          className=" mb-8 h-[300px] rounded-[10px] md:mb-0 md:h-full "
        />
      </div>
    </div>
  );
};
export default SignIn;
