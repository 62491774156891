import moment from "moment";
import React, { Fragment } from "react";

const StudentDetails = ({ user }) => {
  return (
    <>
      <div className="flex justify-between">
        <p className="text-gray-600">
          <span className="font-semibold me-2">Nom:</span>
          <span className="font-bold text-navy-700">{user.full_name}</span>
        </p>
        <p className="text-gray-600">
          <span className="font-semibold me-2">Date de naissance:</span>
          <span className="font-bold text-navy-700">{moment(user.birthday).format("DD-MM-YYYY")}</span>
        </p>
      </div>
      <div>
        <span className="font-semibold text-gray-600">
          informations sur la santé
        </span>
        <div>
          {user?.health_information?.map((el) => {
            return (
              <Fragment key={el.id}>
                <p className="text-gray-600">
                  <span className="font-semibold me-2">Question: </span>
                  <span className="font-bold text-navy-700">{el.question}</span>
                </p>
                <p className="text-gray-600">
                  <span className="font-semibold me-2">Description: </span>
                  <span className="font-bold text-navy-700">
                    {el.description || "Aucune description fournie "}
                  </span>
                </p>
              </Fragment>
            );
          })}
        </div>
      </div>
      <div>
        <span className="font-semibold text-gray-600">Personnes autorisée</span>
        <div>
        <div className="w-full">
  {/* Header */}
  <div className="flex justify-between  p-2">
    <div className="w-1/3 font-medium text-gray-600">Nom</div>
    <div className="w-1/3 font-medium text-gray-600">Relation</div>
    <div className="w-1/3 font-medium text-gray-600">Phone</div>
  </div>

  {/* Data Rows */}
  {user?.user?.parent_granted_access?.map((el, index) => (
    <div key={index} className="flex justify-between p-2 border-t">
      <div className="w-1/3 font-medium">{el.full_name}</div>
      <div className="w-1/3 font-medium">{el.relation}</div>
      <div className="w-1/3 font-medium">{el.phone}</div>
    </div>
  ))}
</div>
        </div>
      </div>
    </>
  );
};

export default StudentDetails;
