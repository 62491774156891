export const LOADING_PARENT = "LOADING_PARENT";
export const GET_PARENTS_SUCCESS = "GET_PARENTS_SUCCESS";
export const GET_PARENTS_FAILED = "GET_PARENTS_FAILED";

export const LOADING_ADD = "LOADING_ADD";
export const ADD_PARENTS_SUCCESS = "ADD_PARENTS_SUCCESS";
export const ADD_PARENTS_FAILED = "ADD_PARENTS_FAILED";

export const LOADING_DELETE = "LOADING_DELETE";
export const DELETE_PARENT_SUCCESS = "DELETE_PARENT_SUCCESS";
export const DELETE_PARENT_FAILED = "DELETE_PARENT_FAILED";

export const UPDATE_PARENT_SUCCESS = "UPDATE_PARENT_SUCCESS";
export const UPDATE_PARENT_FAILED = "UPDATE_PARENT_FAILED";
