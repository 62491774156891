import { UPDATE_FEEDBACK_SUCCESS } from "Redux/ActionsType/notificationType";
import {
  CANCEL_FEEDBACK_LOADING,
  DELETE_FEEDBACK_FAILED,
  DELETE_FEEDBACK_SUCCESS,
  FEEDBACK_LOADING,
  GET_FEEDBACK_FAILED,
  GET_FEEDBACK_SUCCESS,
  LOADING_DELETE_FEEDBACK,
} from "../ActionsType/userType";
import { UPDATE_FEEDBACK_FAILED } from "Redux/ActionsType/notificationType";

const initialState = {
  loading: false,
  feedbacks: [],
  error: null,
  loadingDeleteFeedback: false,
};

export const feedBackReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FEEDBACK_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_FEEDBACK_LOADING:
      return { ...state, loading: false };

    case GET_FEEDBACK_SUCCESS:
      return { ...state, loading: false, feedbacks: payload };
    case GET_FEEDBACK_FAILED:
      return { ...state, loading: false, error: null };

    case LOADING_DELETE_FEEDBACK:
      return {
        ...state,
        loadingDeleteFeedback: true,
      };
    case DELETE_FEEDBACK_SUCCESS:
      return {
        ...state,
        loadingDeleteFeedback: false,
        feedbacks: state?.feedbacks?.filter(({ id }) => +id !== +payload),
      };
    case DELETE_FEEDBACK_FAILED:
      return { ...state, loadingDeleteFeedback: false, error: payload };
    case UPDATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        loadingDeleteFeedback: false,
        feedbacks: state.feedbacks.map((parent) =>
          parent.id === payload.id ? payload : parent
        ),
      };
    case UPDATE_FEEDBACK_FAILED:
      return { ...state, loadingDeleteFeedback: false, error: payload };

    default:
      return state;
  }
};
