import {
  DELETE_USER_LOADING,
  GET_ABSENCES_FAILED,
  GET_ABSENCES_SUCCESS,
  GET_STATS_SUCCESS,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  LOADING_GET_ABSENCES,
  LOADING_USER,
  LOADING_USERS_WITH_ROLE,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
} from "../ActionsType/userType";
import api from "../../Constants/api";
import { LOADING_DELETE } from "Redux/ActionsType/parentType";
import { DELETE_TEACHER_SUCCESS } from "Redux/ActionsType/teacherType";
import { closeCentredModal, openSuccessModal } from "./modalAction";
import { DELETE_PARENT_FAILED } from "Redux/ActionsType/parentType";
import { GET_PARENTS_SUCCESS } from "Redux/ActionsType/parentType";
import { GET_TEACHERS_SUCCESS } from "Redux/ActionsType/teacherType";
import { GET_PARENTS_FAILED } from "Redux/ActionsType/parentType";
import { GET_TEACHERS_FAILED } from "Redux/ActionsType/teacherType";
import { DELETE_PARENT_SUCCESS } from "Redux/ActionsType/parentType";
import { DELETE_TEACHER_FAILED } from "Redux/ActionsType/teacherType";
import { handleError } from "./toastModal";
// Login
export const loginUser = (userData, callback) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    const { data } = await api.post(`/login`, userData);
    // await SecureStore.setItemAsync("token", data?.data?.data?.access_token);
    if (data?.data?.user?.roles[0]?.slug === "admin") {
      localStorage.setItem("token", data?.data?.access_token);
      dispatch({ type: LOGIN_SUCCESS, payload: data?.data?.data?.user });
      callback();
    } else {
      dispatch(openSuccessModal("Unauthorized", false));
      dispatch({ type: LOGIN_FAILED, payload: "error" });
    }
  } catch (error) {
    if (error) {
      dispatch({ type: LOGIN_FAILED, payload: "error" });

      dispatch(handleError("invalid credential !", 1));
    }
  }
};

export const getCurrentUser =
  (withLoad = true) =>
  async (dispatch) => {
    withLoad && dispatch({ type: LOADING_USER });
    try {
      const token = localStorage.getItem("token");
      const data = await api.get(`/getinfo`, {
        headers: { authorization: `Bearer ${token}` },
      });

      dispatch({ type: GET_USER_SUCCESS, payload: data?.data?.data });
    } catch (error) {
      if (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
        }
        dispatch({
          type: GET_USER_FAILED,
          payload: error,
        });
      }
    }
  };

// // Set logged in user
// export const setCurrentUser = (decoded) => {
//   return {
//     type: SET_CURRENT_USER,
//     payload: decoded,
//   };
// };

// // Logout user
// export const logoutUser = async (dispatch) => {
//   try {
//     await SecureStore.deleteItemAsync("token");

//     // await SecureStore.setItemAsync("token", "");
//     dispatch({ type: LOGOUT_SUCCESS });
//   } catch (error) {
//     dispatch({ type: LOGOUT_FAIL, payload: error });
//   }
// };
export const getUserWithRole = (roleName) => async (dispatch) => {
  dispatch({ type: LOADING_USERS_WITH_ROLE });
  try {
    const token = localStorage.getItem("token");
    const data = await api.get(`/admin/getUsersWithRole/${roleName}`, {
      headers: { authorization: `Bearer ${token}` },
    });

    dispatch({
      type: roleName === "parent" ? GET_PARENTS_SUCCESS : GET_TEACHERS_SUCCESS,
      payload: data?.data?.data?.data,
    });
  } catch (error) {
    console.log(error);
    if (roleName === "parent") {
      dispatch({
        type: GET_PARENTS_FAILED,
        payload: "ERROR !",
      });
    } else if (roleName === "teacher") {
      dispatch({
        type: GET_TEACHERS_FAILED,
        payload: "ERROR !",
      });
    }
  }
};

export const deleteUser = (id, roleName) => async (dispatch) => {
  dispatch({ type: DELETE_USER_LOADING });
  try {
    const token = localStorage.getItem("token");
    const data = await api.delete(
      `/admin/users/${id}`,

      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    if (roleName === "parent") {
      dispatch({
        type: DELETE_PARENT_SUCCESS,
        payload: id,
      });
    } else if (roleName === "teacher") {
      dispatch({
        type: DELETE_TEACHER_SUCCESS,
        payload: id,
      });
    }
    dispatch(closeCentredModal());
  } catch (error) {
    if (roleName === "parent") {
      dispatch({
        type: DELETE_PARENT_FAILED,
        payload: "ERROR !",
      });
    } else if (roleName === "teacher") {
      dispatch({
        type: DELETE_TEACHER_FAILED,
        payload: "ERROR !",
      });
    }
  }
};

export const getAllAbsence = () => async (dispatch) => {
  dispatch({ type: LOADING_GET_ABSENCES });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.get(`/admin/absences`, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: GET_ABSENCES_SUCCESS, payload: data?.data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_ABSENCES_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const updatePassword = (userData) => async (dispatch) => {
  try {
    // forceUpdateUserPassword
    const token = localStorage.getItem("token");
    const { data } = await api.post(
      `/admin/forceUpdateUserPassword`,
      userData,
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    console.log(error);
    dispatch(openSuccessModal("Error", false));
  }
};

export const getStats = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.get(`/admin/dashboard`, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: GET_STATS_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch(openSuccessModal("Error", false));
  }
};
