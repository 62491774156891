import {
  ADD_CANTINE_FAILED,
  ADD_CANTINE_SUCCESS,
  DELETE_CANTINE_FAILED,
  DELETE_CANTINE_SUCCESS,
  GET_CANTINE_FAILED,
  GET_CANTINE_SUCCESS,
  LOADING_ADD,
  LOADING_CANTINE,
  LOADING_DELETE,
} from "../ActionsType/cantineType";

const initialState = {
  loading: false,
  loadingAdd: false,
  loadingDeleteCantine: false,
  cantines: [],
  error: null,
};

export const cantineReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_CANTINE:
      return {
        ...state,
        loading: true,
      };
    case GET_CANTINE_SUCCESS:
      return { ...state, loading: false, cantines: payload };
    case GET_CANTINE_FAILED:
      return { ...state, loading: false, error: payload };

    case LOADING_ADD:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_CANTINE_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        cantines: [payload, ...state.cantines],
      };
    case ADD_CANTINE_FAILED:
      return { ...state, loadingAdd: false, error: payload };

    case LOADING_DELETE:
      return {
        ...state,
        loadingDeleteCantine: true,
      };
    case DELETE_CANTINE_SUCCESS:
      return {
        ...state,
        loadingDeleteCantine: false,
        cantines: state?.cantines?.filter(({ id }) => +id !== +payload),
      };
    case DELETE_CANTINE_FAILED:
      return { ...state, loadingDeleteCantine: false, error: payload };

    default:
      return state;
  }
};
