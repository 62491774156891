import { PlusIconNoStroke } from "Constants/icons";
import { BackIcon } from "../../../assets/svg/incons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openCentredModal } from "Redux/Actions/modalAction";
import NewFiles from "components/Galerie/NewFiles";
import { imageUrl } from "Constants/endPoint";

const Files = ({ choosenFolder, setChoosenFolder }) => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.generalReducer);
  const { galeries } = useSelector((state) => state.galerieReducer);
  const filteredFolders = galeries.filter((folder) =>
    folder.folderName.toLowerCase().includes(choosenFolder.toLowerCase())
  );

  return (
    <div className="mt-2 h-full px-5 ">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <BackIcon callback={() => setChoosenFolder()} />
          <div className="p-2 ms-10">
            <p className="text-lg font-bold">{choosenFolder}</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        {filteredFolders[0]?.images.map(({ url }, index) => (
          <img
            className=" h-[180px]  rounded"
            key={index}
            src={imageUrl + url}
            alt={`Image ${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Files;
