export const LOADING_SERVICE = "LOADING_SERVICE";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_FAILED = "GET_SERVICE_FAILED";

export const GET_SERVICE_TYPES_SUCCESS = "GET_SERVICE_TYPES_SUCCESS";
export const GET_SERVICE_TYPES_FAILED = "GET_SERVICE_TYPES_FAILED";

export const ADD_SERVICE_SUCCESS = "ADD_SERVICE__SUCCESS";
export const ADD_SERVICE_FAILED = "ADD_SERVICE__FAILED";

export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE__SUCCESS";
export const DELETE_SERVICE_FAILED = "DELETE_SERVICE__FAILED";

export const ADD_SERVICE_TYPES_SUCCESS = "ADD_SERVICE_TYPES_SUCCESS";
export const ADD_SERVICE_TYPES_FAILED = "ADD_SERVICE_TYPES_FAILED";

export const DELETE_SERVICE_TYPES_SUCCESS = "DELETE_SERVICE_TYPES_SUCCESS";
export const DELETE_SERVICE_TYPES_FAILED = "DELETE_SERVICE_TYPES_FAILED";

export const UPDATE_SERVICE_TYPES_SUCCESS = "UPDATE_SERVICE_TYPES_SUCCESS";
export const UPDATE_SERVICE_TYPES_FAILED = "UPDATE_SERVICE_TYPES_FAILED";

export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAILED = "UPDATE_SERVICE_FAILED";
