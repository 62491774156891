import {
  ADD_BILLING_FAILED,
  ADD_BILLING_SUCCESS,
  DELETE_BILLING_FAILED,
  DELETE_BILLING_SUCCESS,
  GET_BILLING_FAILED,
  GET_BILLING_SUCCESS,
  LOADING_BILLING,
  LOADING_BILLING_ADD,
  UPDATE_BILLING_FAILED,
  UPDATE_BILLING_SUCCESS,
} from "../ActionsType/billingType";

const initialState = {
  loading: false,
  loadingAdd: false,
  billings: [],
  error: null,
};

export const billingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_BILLING:
      return {
        ...state,
        loading: true,
      };
    case GET_BILLING_SUCCESS:
      return { ...state, loading: false, billings: payload };
    case GET_BILLING_FAILED:
      return { ...state, loading: false, error: payload };
    case LOADING_BILLING_ADD:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_BILLING_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        billings: [...payload, ...state.billings],
      };
    case ADD_BILLING_FAILED:
      return { ...state, loadingAdd: false, error: payload };

    case UPDATE_BILLING_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        billings: state.billings.map((service) =>
          service.id === payload.id ? payload : service
        ),
      };
    case UPDATE_BILLING_FAILED:
      return { ...state, loadingAdd: false, error: payload };

    case DELETE_BILLING_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        billings: state?.billings?.filter(({ id }) => +id !== +payload),
      };
    case DELETE_BILLING_FAILED:
      return { ...state, loadingAdd: false, error: payload };

    default:
      return state;
  }
};
