export const LOADING_CLASS = "LOADING_CLASS";
export const GET_CLASS_SUCCESS = "GET_CLASS_SUCCESS";
export const GET_CLASS_FAILED = "GET_CLASS_FAILED";

export const LOADING_ADD_CLASS = "LOADING_ADD_CLASS";
export const ADD_CLASS_SUCCESS = "ADD_CLASS_SUCCESS";
export const ADD_CLASS_FAILED = "ADD_CLASS_FAILED";

export const LOADING_DELETE_CLASS = "LOADING_DELETE_CLASS";
export const DELETE_CLASS_SUCCESS = "DELETE_CLASS_SUCCESS";
export const DELETE_CLASS_FAILED = "DELETE_CLASS_FAILED";

export const LOADING_UPDATE_CLASS = "LOADING_UPDATE_CLASS";
export const UPDATE_CLASS_SUCCESS = "UPDATE_CLASS_SUCCESS";
export const UPDATE_CLASS_FAILED = "UPDATE_CLASS_FAILED";
