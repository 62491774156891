import { getCurrentUser } from "Redux/Actions/userAction";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return <>{children}</>;
};

export default Auth;
