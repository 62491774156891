import {
  ADD_FILES_FAILED,
  ADD_FILES_SUCCESS,
  ADD_FOLDER_FAILED,
  ADD_FOLDER_SUCCESS,
  DELETE_FOLDER_FAILED,
  DELETE_FOLDER_SUCCESS,
  GET_GALERIE_FAILED,
  GET_GALERIE_SUCCESS,
  LOADING_ADD,
  LOADING_ADD_FILES,
  LOADING_DELETE_FOLDER,
  LOADING_GALERIE,
} from "../ActionsType/galerieType";
import api from "../../Constants/api";
import { closeCentredModal, openSuccessModal } from "./modalAction";

export const getAllGalerie = () => async (dispatch) => {
  dispatch({ type: LOADING_GALERIE });
  try {
    const token = localStorage.getItem("token");

    const data = await api.get(`/admin/media`, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: GET_GALERIE_SUCCESS, payload: data?.data?.data });
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: GET_GALERIE_FAILED,
      payload: "Failed to fetch data.",
    });
  }
};

export const addNewFolder = (folderName) => async (dispatch) => {
  dispatch({ type: LOADING_ADD });
  try {
    const token = localStorage.getItem("token");
    const data = await api.post(
      `/admin/media`,
      { name: folderName?.folderName },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch({ type: ADD_FOLDER_SUCCESS, payload: data?.data?.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    console.log(error);
    dispatch({
      type: ADD_FOLDER_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const deleteFolder = (id) => async (dispatch) => {
  dispatch({ type: LOADING_DELETE_FOLDER });
  try {
    const token = localStorage.getItem("token");
    const data = await api.delete(
      `/admin/media/${id}`,

      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch({ type: DELETE_FOLDER_SUCCESS, payload: id });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    dispatch({
      type: DELETE_FOLDER_FAILED,
      payload: "Fialed .",
    });
  }
};

export const addNewFiles = (folderId, files) => async (dispatch) => {
  dispatch({ type: LOADING_ADD_FILES });
  try {
    const token = localStorage.getItem("token");
    const data = await api.post(`/admin/addImagesFolder/${folderId}`, files, {
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    });
    dispatch({ type: ADD_FILES_SUCCESS });
    dispatch(getAllGalerie());
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    console.log(error);
    dispatch({
      type: ADD_FILES_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};
