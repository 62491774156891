import InputField from "components/fields/InputField";
import React, { useState } from "react";

const QuestionList = ({ questions, formData, setFormData }) => {
  const handleCheckboxChange = (questionId, answer) => {
    setFormData({
      ...formData,
      [questionId]: answer,
    });
  };

  return (
    <div>
          {questions.map((question) => (
              <div>
        <div key={question?.id} className="flex flex-row items-center">
          <div className="my-2  mr-3 flex flex-row  ">
            <label className="flex flex-row items-center">
              <input
                type="checkbox"
                name={`answer_${question?.id}`}
                checked={formData[question?.id] === true}
                onChange={() =>
                    handleCheckboxChange(question?.id, !formData[question?.id])
                }
                />
            </label>
          </div>
          <p className="">{question.question}</p>
                  </div>
                  {
                      formData[question?.id] === true &&
                      <InputField
                      id="title"
                      name="title"
                      extra="mb-2 ml-5"
                      placeholder="entrez votre question"
                      type="text"
                      field="question"
                      required={false}
                      setValue={(value) => setFormData({
                        ...formData,
                       [`desc${question?.id}`]: value?.question,
                      })}
                      />
                    }
                </div>
      ))}
      {/* Add a submit button or perform further actions here */}
    </div>
  );
};

export default QuestionList;
