import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import App from "./App";

import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';


//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
// import 'primeflex/primeflex.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <PrimeReactProvider>

      <App />
    </PrimeReactProvider>
  </BrowserRouter >
);
