import React, { useEffect } from "react";
import DevelopmentTable from "./components/DevelopmentTable";
import { programDataDevelopment } from "./components/ColumnData";
import { useDispatch, useSelector } from "react-redux";
import { getAllPrograms } from "Redux/Actions/programAction";

const ProgramView = () => {
  const dispatch = useDispatch();
  const { loading, programs } = useSelector((state) => state.programReducer);
  useEffect(() => {
    dispatch(getAllPrograms());
  }, []);
  return (
    <div>
      <div className="mt-5  h-full ">
        <DevelopmentTable
          columnsData={programDataDevelopment}
          tableData={programs || []}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ProgramView;
