import { Loading } from "Constants/icons";
import { DeleteIcon } from "Constants/icons";
import { UpdateIcon } from "Constants/icons";
import { PlusIconNoStroke } from "Constants/icons";
import { reclamationData } from "Constants/utils";
import { deleteFeedback } from "Redux/Actions/feedbackAction";
import { closeCentredModal } from "Redux/Actions/modalAction";
import { openCentredModal } from "Redux/Actions/modalAction";
import Card from "components/card";
import FedbackForm from "components/feedback/FedbackForm";
import DeleteModal from "components/modal/DeleteModal";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

const DevelopmentTable = ({ columnsData, tableData, loading }) => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.generalReducer);
  const columns = useMemo(() => columnsData, [columnsData]);
  const [toogle, setToogle] = useState(false);

  // const [filtredData, setFiltredData] = useState();
  // useEffect(() => {
  //   if (toogle) {
  //     const todayDate = new Date().toLocaleDateString("en-GB");

  //     // Iterate through the list and find the element that includes today's date
  //     const todayElement = tableData.filter((range) => {
  //       const fromDate = new Date(range.from).toLocaleDateString("en-GB");
  //       const toDate = new Date(range.to).toLocaleDateString("en-GB");
  //       return todayDate >= fromDate && todayDate <= toDate;
  //     });
  //     setFiltredData(todayElement);
  //   } else {
  //     setFiltredData(tableData);
  //   }
  // }, [toogle]);

  // const data = useMemo(() => tableData, [tableData]);
  const today = new Date(); // Get today's date

  const data = useMemo(() => {
    if (toogle) {
      return tableData.filter((range) => {
        const fromDate = new Date(range.from);
        const toDate = new Date(range.to);

        return (
          (today >= fromDate && today <= toDate) ||
          today.toDateString() === fromDate.toDateString() ||
          today.toDateString() === toDate.toDateString()
        );
      });
    } else {
      return tableData;
    }
  }, [tableData, toogle]);
  const handleDelete = (id, name) => {
    dispatch(
      openCentredModal({
        content: (
          <DeleteModal
            name={"this feedback"}
            // loading={loadingDelete}
            onSubmit={() => dispatch(deleteFeedback(id))}
            onCancel={() => dispatch(closeCentredModal())}
          />
        ),
        headerText: "Delete Feedback",
      })
    );
    // dispatch(deleteParent(cell.row.original.id))
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
      initialState,
      pageCount, // This is provided by usePagination
      gotoPage, // This is provided by usePagination
      nextPage, // This is provided by usePagination
      previousPage, // This is provided by usePagination
      canNextPage, // This is provided by usePagination
      canPreviousPage, // This is provided by usePagination
      state: { pageIndex },
  } = tableInstance;
  initialState.pageSize = 11;

  return (
    <Card extra={"w-full h-full p-4"}>
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Liste Absence
        </div>
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          <div className="mx-2  flex flex-row ">
            <label className="flex  items-center">
              <input
                type="checkbox"
                name={`toogle`}
                checked={toogle}
                onChange={() => setToogle(!toogle)}
              />
            </label>
            <p className="ms-2" style={{ fontWeight: "500", fontSize: "18px" }}>
              Aujourd'hui
            </p>
          </div>
        </div>
      </div>

      <div className="h-full overflow-x-scroll xl:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="mt-8 h-max w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups?.map((headerGroup, index) => (
              <tr {...headerGroup?.getHeaderGroupProps()} key={index}>
                {headerGroup?.headers?.map((column, index) => (
                  <th
                    {...column?.getHeaderProps(column?.getSortByToggleProps())}
                    className="border-b border-gray-200 pr-32 pb-[10px] text-start dark:!border-navy-700 "
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {loading ? (
            <tbody {...getTableBodyProps()}>
              <tr>
                <td colSpan={headerGroups[0]?.headers?.length}>
                  <div role=" w-full h-full p-4 d-flex items-center justify-center">
                    <Loading />
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page?.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row?.getRowProps()} key={index}>
                    {row?.cells?.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "NOM") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.row.original.student.full_name}
                          </p>
                        );
                      } else if (cell.column.Header === "DE") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {moment(cell?.value).format("DD-MM-YYYY")}
                          </p>
                        );
                      } else if (cell.column.Header === "A") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {moment(cell?.value).format("DD-MM-YYYY")}
                          </p>
                        );
                      } else {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      }

                      return (
                        <td
                          {...cell?.getCellProps()}
                          key={index}
                          className="pt-[14px] pb-3 text-[14px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
          </div>
          <div className="flex w-full items-center justify-center">
        <nav aria-label="Page navigation example">
          <ul className="inline-flex -space-x-px text-sm">
            <li>
              <div
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="ml-0 flex h-8 items-center justify-center rounded-l-lg border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                Previous
              </div>
            </li>
            <li>
              <a
                href="#"
                className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                Page {pageIndex + 1} of {pageCount}
              </a>
            </li>

            <li>
              <div
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="flex h-8 items-center justify-center rounded-r-lg border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                Next
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </Card>
  );
};

export default DevelopmentTable;
