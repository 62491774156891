import React from "react";

const ParentDetails = ({ user }) => {
  return (
    <>
      <p className="text-gray-600">
        <span className="font-semibold">Name:</span>
        <span className="font-bold text-navy-700">
          {"  "}
          {user.name}
        </span>
      </p>
      <p className="text-gray-600">
        <span className="font-semibold">Email:</span>
        {"  "}
        <span className="font-bold text-navy-700">{user.email}</span>
      </p>
      <p className="text-gray-600">
        <span className="font-semibold">Phone:</span>
        {"  "}
        <span className="font-bold text-navy-700">{user.phone}</span>
      </p>
      <div className="">
        <h3 className="text-lg font-semibold text-navy-700">Enfants</h3>
        {user.students.map((student, index) => (
          <div key={index} className="mb-2 flex text-gray-600">
            <p className="me-5">
              <span className="font-semibold">Prénom:</span>{" "}
              <span className="font-bold text-navy-700">
                {" "}
                {student.first_name}
              </span>
            </p>
            <p>
              <span className="font-semibold">Nom:</span>{" "}
              <span className="font-bold text-navy-700">
                {" "}
                {student.last_name}
              </span>
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default ParentDetails;
