import { PlusIconNoStroke } from "Constants/icons";
import Card from "components/card";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StudentItem from "./StudentItem";
import { studentsData } from "Constants/utils";
import CustomSelect from "components/CustomSelect";
import { classData } from "Constants/utils";
import { getAllClass } from "Redux/Actions/classActions";
import { mapClassData } from "Constants/utils";
import { openCentredModal } from "Redux/Actions/modalAction";
import StudentForm from "components/student/StudentForm";
import { getStudentByClass } from "Redux/Actions/studentAction";
import { FcGoodDecision } from "react-icons/fc";
import { Button } from "primereact/button";
import DevelopmentTable from "./components/DevelopmentTable";
import { columnsDataDevelopment } from "./variables/columnsData";
import { getAllStudent } from "Redux/Actions/studentAction";

const StudentsView = () => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.generalReducer);
  const { classes } = useSelector((state) => state.classReducer);
  const { students } = useSelector((state) => state.studentReducer);
  const [selectedClass, setSelectedClass] = useState(classes[0]?.id);
  const [search, setSearch] = useState();
  useEffect(() => {
    dispatch(getAllClass());
  }, []);
  useEffect(() => {
    if (selectedClass) {
      dispatch(getStudentByClass(selectedClass));
    } else {
      dispatch(getAllStudent());
    }
  }, [selectedClass, classes]);
  return (
    <div className="mt-5  h-full ">
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Èlèves
          </div>
          <div className="flex items-center">
            <input
              type={"test"}
              placeholder={"recherche"}
              onChange={(e) => setSearch(e.target.value)}
              autoComplete="off"
              className={` flex h-12 w-60 items-center justify-center rounded-lg border bg-white/0 p-3 text-sm outline-none ${"border-gray-200 dark:!border-white/10 dark:text-white"}`}
            />
            <div className="w-60 me-5">
              <CustomSelect
                data={mapClassData(classes, true)}
                selected={selectedClass}
                setSelected={setSelectedClass}
              />
            </div>

            <div className="align-items-center  flex gap-2">
              <Button
                label="Ajouter"
                icon="pi pi-plus"
                severity="success"
                onClick={() =>
                  dispatch(
                    openCentredModal({
                      content: <StudentForm />,
                      headerText: "Nouvel élève",
                    })
                  )
                }
              />
            </div>
          </div>
        </div>
      </Card>
      <div className="mt-4   min-h-[70vh]  overflow-x-scroll xl:overflow-x-hidden">
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={students || []}
          loading={false}
          searchInput={search}
        />
      </div>
    </div>
  );
};

export default StudentsView;
