import { classData } from "Constants/utils";
import React, { useEffect } from "react";

const CustomSelect = ({
  data,
  selected,
  setSelected,
  label,
  required,
  extra,
  variant,
  multiple,
  defaultValue,
  errMessage,
}) => {
  const handleChange = (event) => {
    const value = event.target.value;

    setSelected(value);
  };
  return (
    <div className={`${extra} `}>
      <label
        className={` pb-2 text-sm text-navy-700 dark:text-white ${
          variant === "auth" ? "ml-1.5  font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
        {required && <span className="text-red-600"> *</span>}
      </label>
      <select
        id="small"
        onChange={handleChange}
        multiple={multiple}
        className=" h-12 w-full rounded-lg border  border-gray-200 p-2 text-sm  text-gray-900 dark:!border-white/10 dark:bg-navy-800 dark:text-white"
        defaultChecked={defaultValue}
        value={selected}
      >
        <option value="">Select an option</option>
        {data?.map((item, index) => {
          return (
            <option key={index} value={item?.value} selected={selected}>
              {item?.label}
            </option>
          );
        })}
      </select>
      {errMessage && (
        <div className=" mt-1 px-3 text-red-500">{errMessage}</div>
      )}
    </div>
  );
};

export default CustomSelect;
