import {
  CLOSE_ERROR_MODAL,
  CLOSE_INFO_MODAL,
  CLOSE_NOTIFICATION,
  OPEN_ERROR_MODAL,
  OPEN_INFO_MODAL,
} from "../ActionsType/modalType";

const initialState = {
  info: false,
  infoMessage: null,
  error: false,
  errorMessage: null,
};

export const toastModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_INFO_MODAL:
      return {
        ...state,
        info: true,
        infoMessage: action.payload.message,
      };
    case CLOSE_INFO_MODAL:
      return { ...state, info: false, infoMessage: null };
    case OPEN_ERROR_MODAL:
      return { ...state, error: true, errorMessage: action.payload };
    case CLOSE_ERROR_MODAL:
      return { ...state, error: false, errorMessage: null };
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        error: false,
        errorMessage: null,
        info: false,
        infoMessage: null,
      };
    default:
      return state;
  }
};
