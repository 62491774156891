import { GET_TEACHERS_SUCCESS } from "Redux/ActionsType/teacherType";
import {
  GET_ABSENCES_FAILED,
  GET_ABSENCES_SUCCESS,
  GET_STATS_SUCCESS,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  LOADING_GET_ABSENCES,
  LOADING_USER,
  LOADING_USERS_WITH_ROLE,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
} from "../ActionsType/userType";
import { GET_TEACHERS_FAILED } from "Redux/ActionsType/teacherType";
import { GET_PARENTS_SUCCESS } from "Redux/ActionsType/parentType";
import { GET_PARENTS_FAILED } from "Redux/ActionsType/parentType";
import { LOADING_ADD } from "Redux/ActionsType/parentType";
import { ADD_PARENTS_SUCCESS } from "Redux/ActionsType/parentType";
import { ADD_PARENTS_FAILED } from "Redux/ActionsType/parentType";
import { ADD_TEACHERS_SUCCESS } from "Redux/ActionsType/teacherType";
import { ADD_TEACHERS_FAILED } from "Redux/ActionsType/teacherType";
import { DELETE_PARENT_SUCCESS } from "Redux/ActionsType/parentType";
import { DELETE_PARENT_FAILED } from "Redux/ActionsType/parentType";
import { DELETE_TEACHER_SUCCESS } from "Redux/ActionsType/teacherType";
import { LOADING_DELETE } from "Redux/ActionsType/parentType";
import { UPDATE_PARENT_FAILED } from "Redux/ActionsType/parentType";
import { UPDATE_TEACHER_SUCCESS } from "Redux/ActionsType/teacherType";
import { UPDATE_PARENT_SUCCESS } from "Redux/ActionsType/parentType";

const initialState = {
  loading: false,
  user: null,
  error: null,
  loadingUsers: false,
  loadingAdd: false,

  users: [],
  teachers: [],
  parents: [],
  absences: [],
  stats: [],
};

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_USER:
    case LOADING_GET_ABSENCES:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return { ...state, loading: false, user: payload };
    case LOGIN_FAILED:
      return { ...state, loading: false, error: payload };
    case GET_USER_SUCCESS:
      return { ...state, loading: false, user: payload };
    case GET_USER_FAILED:
      return { ...state, loading: false, error: payload };
    case GET_ABSENCES_SUCCESS:
      return { ...state, loading: false, absences: payload };
    case GET_ABSENCES_FAILED:
      return { ...state, loading: false, error: payload };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        loading: null,
        error: null,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        userLoading: false,
        error: payload,
      };

    case LOADING_USERS_WITH_ROLE:
      return { ...state, loadingUsers: true };

    case GET_TEACHERS_SUCCESS:
      return { ...state, loadingUsers: false, teachers: payload };

    case GET_TEACHERS_FAILED:
      return { ...state, loadingUsers: false, error: payload };
    case GET_STATS_SUCCESS:
      return { ...state, stats: payload };
    case GET_PARENTS_SUCCESS:
      return { ...state, loadingUsers: false, parents: payload };
    case GET_PARENTS_FAILED:
      return { ...state, loadingUsers: false, error: payload };

    case LOADING_ADD:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_PARENTS_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        parents: [payload, ...state.parents],
      };
    case ADD_PARENTS_FAILED:
      return { ...state, loadingAdd: false, error: payload };

    case ADD_TEACHERS_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        teachers: [payload, ...state.teachers],
      };
    case ADD_TEACHERS_FAILED:
      return { ...state, loadingAdd: false, error: payload };

    case LOADING_DELETE:
      return {
        ...state,
        loadingDelete: true,
      };
    case DELETE_PARENT_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        // parents: [payload, ...state.parents],
        parents: state?.parents?.filter(({ id }) => +id !== +payload),
      };
    case DELETE_PARENT_FAILED:
      return { ...state, loadingDelete: false, error: payload };

    case DELETE_TEACHER_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        teachers: state?.teachers?.filter(({ id }) => +id !== +payload),
      };
    case ADD_TEACHERS_FAILED:
      return { ...state, loadingDelete: false, error: payload };
    case UPDATE_PARENT_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        parents: state.parents.map((parent) =>
          parent.id === payload.id ? payload : parent
        ),
      };
    case UPDATE_PARENT_FAILED:
      return { ...state, loadingAdd: false, error: payload };
    case UPDATE_TEACHER_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        teachers: state.teachers.map((teacher) =>
          teacher.id === payload.id ? payload : teacher
        ),
      };
    case UPDATE_TEACHER_SUCCESS:
      return { ...state, loadingAdd: false, error: payload };
    default:
      return state;
  }
};
