export const LOADING_GALERIE = "LOADING_GALERIE";
export const GET_GALERIE_SUCCESS = "GET_GALERIE_SUCCESS";
export const GET_GALERIE_FAILED = "GET_GALERIE_FAILED";

export const LOADING_ADD = "LOADING_ADD";
export const ADD_FOLDER_SUCCESS = "ADD_FOLDER_SUCCESS";
export const ADD_FOLDER_FAILED = "ADD_FOLDER_FAILED";

export const LOADING_DELETE_FOLDER = "LOADING_DELETE_FOLDER";
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";
export const DELETE_FOLDER_FAILED = "DELETE_FOLDER_FAILED";

export const LOADING_ADD_FILES = "LOADING_ADD_FILES";
export const ADD_FILES_SUCCESS = "ADD_FILES_SUCCESS";
export const ADD_FILES_FAILED = "ADD_FILES_FAILED";
