import { MdFileUpload } from "react-icons/md";
import Card from "components/card";
import React, { useState } from "react";

const Upload = ({ setUploadedFiles }) => {
  const [selected, setSelected] = useState();
  const handleChange = (e) => {
    let files = e.target.files;
    if (files.length === 1) {
      setSelected(files[0].name);
    } else {
      setSelected(files.length + " files selected");
    }
    setUploadedFiles(files);
  };
  return (
    <Card className="grid h-full w-full grid-cols-1 gap-3 rounded-[20px] bg-white  font-dm shadow-3xl shadow-shadow-500  dark:shadow-none ">
      <div className="flex w-full items-center justify-center">
        <label
          htmlFor="dropzone-file"
          className="dark:hover:bg-bray-800 flex h-48 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-navy-800 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            {selected ? (
              <p className="mb-2 text-lg text-navy-800 dark:text-white">
                <span className="font-semibold">{selected}</span>
              </p>
            ) : (
              <>
                <svg
                  aria-hidden="true"
                  className="mb-3 h-10 w-10 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </>
            )}
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            multiple="multiple"
            onChange={handleChange}
          />
        </label>
      </div>
    </Card>
  );
};

export default Upload;
