import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import { DiApple } from "react-icons/di";
import { DiAndroid } from "react-icons/di";
import { DiWindows } from "react-icons/di";

import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { DeleteIcon, Loading } from "Constants/icons";
import { useDispatch, useSelector } from "react-redux";
import { PlusIconNoStroke } from "Constants/icons";
import { UpdateIcon } from "Constants/icons";
import { openCentredModal } from "Redux/Actions/modalAction";
import ParentForm from "components/Parent/ParentForm";
import { deleteUser } from "Redux/Actions/userAction";
import CentredModal from "components/modal/CentredModal";
import DeleteModal from "components/modal/DeleteModal";
import { closeCentredModal } from "Redux/Actions/modalAction";
import CantineForm from "components/cantine/CantineForm";
import { deleteCantine } from "Redux/Actions/cantineAction";
import moment from "moment";
import { Button } from "primereact/button";

const DevelopmentTable = ({ columnsData, tableData, loading }) => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.generalReducer);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const handleDelete = (id) => {
    dispatch(
      openCentredModal({
        content: (
          <DeleteModal
            name={"this cantine"}
            // loading={loadingDelete}
            onSubmit={() => dispatch(deleteCantine(id))}
            onCancel={() => dispatch(closeCentredModal())}
          />
        ),
        headerText: "Delete Cantine",
      })
    );
    // dispatch(deleteParent(cell.row.original.id))
  };

  const handleUpdate = (cantine) => {
    dispatch(
      openCentredModal({
        content: <CantineForm cantine={cantine} />,
        headerText: "Edit Cantine",
      })
    );
  };
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;
  return (
    <Card extra={"w-full h-full p-4"}>
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Liste des cantines
        </div>

        <div className="align-items-center  flex gap-2">
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            severity="success"
            onClick={() =>
              dispatch(
                openCentredModal({
                  content: <CantineForm />,
                  headerText: "Nouveau Cantine",
                })
              )
            }
          />
        </div>
      </div>

      <div className="h-full overflow-x-scroll xl:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="mt-8 h-max w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups?.map((headerGroup, index) => (
              <tr {...headerGroup?.getHeaderGroupProps()} key={index}>
                {headerGroup?.headers?.map((column, index) => (
                  <th
                    {...column?.getHeaderProps(column?.getSortByToggleProps())}
                    className="border-b border-gray-200 pr-32 pb-[10px] text-start dark:!border-navy-700 "
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {loading ? (
            <tbody {...getTableBodyProps()}>
              <tr>
                <td colSpan={headerGroups[0]?.headers?.length}>
                  <div role=" w-full h-full p-4 d-flex items-center justify-center">
                    <Loading />
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page?.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row?.getRowProps()} key={index}>
                    {row?.cells?.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "ACTIONS") {
                        data = (
                          <div className="flex gap-2">
                            <div
                              onClick={() => handleDelete(cell.row.original.id)} // Call the edit action function with row id
                              className=" cursor-pointer py-1 px-1"
                            >
                              <DeleteIcon darkMode={darkMode} />
                            </div>
                            <div
                              onClick={() =>
                                // console.log(cell.row.original.name)

                                handleUpdate(cell.row.original)
                              } // Call the delete action function with row id
                              className=" cursor-pointer py-1  px-1"
                            >
                              <UpdateIcon darkMode={darkMode} />
                            </div>
                          </div>
                        );
                      } else if (cell.column.Header === "MENU") {
                        if (Array.isArray(cell.value)) {
                          data = (
                            <div className="flex  gap-2">
                              {cell.value.map((item, index) => (
                                <p
                                  key={index}
                                  className="text-sm font-bold text-navy-700 dark:text-white"
                                >
                                  {item}
                                </p>
                              ))}
                            </div>
                          );
                        } else {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          );
                        }
                      } else {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {/* {moment(cell.value).format("dddd")} */}
                            {cell.value}
                          </p>
                        );
                      }

                      // } else if (cell.column.Header === "DATE") {
                      //   data = (
                      //     <p className="text-sm font-bold text-navy-700 dark:text-white">
                      //       {cell.value}
                      //     </p>
                      //   );
                      // } else if (cell.column.Header === "PROGRESS") {
                      //   data = (
                      //     <div className="flex items-center gap-3">
                      //       <p className="text-sm font-bold text-navy-700 dark:text-white">
                      //         {cell.value}%
                      //       </p>
                      //       <Progress width="w-[68px]" value={cell.value} />
                      //     </div>
                      //   );
                      // }
                      return (
                        <td
                          {...cell?.getCellProps()}
                          key={index}
                          className="pt-[14px] pb-3 text-[14px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </Card>
  );
};

export default DevelopmentTable;
