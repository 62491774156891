import { PlusIconNoStroke } from "Constants/icons";
import { getAllGalerie } from "Redux/Actions/galerieAction";
import { openCentredModal } from "Redux/Actions/modalAction";
import Card from "components/card";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Files from "./Files";
import FolderForm from "components/Galerie/FolderForm";
import { TrashIcon } from "@heroicons/react/solid";
import DeleteModal from "components/modal/DeleteModal";
import { closeCentredModal } from "Redux/Actions/modalAction";
import { deleteFolder } from "Redux/Actions/galerieAction";
import NewFolder from "components/Galerie/NewFolder";
import NewFiles from "components/Galerie/NewFiles";
import { Button } from "primereact/button";
const GalerieView = () => {
  const dispatch = useDispatch();
  const [choosenFolder, setChoosenFolder] = useState();
  const { darkMode } = useSelector((state) => state.generalReducer);
  const { galeries } = useSelector((state) => state.galerieReducer);

  useEffect(() => {
    dispatch(getAllGalerie());
  }, []);
  const handleNewFiles = () => {
    dispatch(
      openCentredModal({
        content: <NewFiles folders={galeries} />,
        headerText: "Add Files",
      })
    );
  };
  const handleDelete = (id) => {
    dispatch(
      openCentredModal({
        content: (
          <DeleteModal
            name={"this folder"}
            // loading={loadingDelete}
            onSubmit={() => dispatch(deleteFolder(id))}
            onCancel={() => dispatch(closeCentredModal())}
          />
        ),
        headerText: "Delete Folder",
      })
    );
    // dispatch(deleteParent(cell.row.original.id))
  };
  return (
    <div>
      <div className="mt-5  h-full ">
        <Card extra={"w-full h-full p-4"}>
          <div className="relative flex items-center justify-between">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              Galeries
            </div>
            {!choosenFolder && (
              <div className="align-items-center  flex gap-2">
                <Button
                  label="New"
                  icon="pi pi-plus"
                  severity="success"
                  onClick={handleNewFiles}
                />
              </div>
            )}
          </div>
        </Card>
        <div className="h-full overflow-x-scroll xl:overflow-x-hidden">
          {choosenFolder ? (
            <Files
              choosenFolder={choosenFolder}
              setChoosenFolder={setChoosenFolder}
            />
          ) : (
            <div className="grid grid-cols-4 gap-4 p-5">
              <NewFolder />
              {galeries?.map(({ id, folderName }) => {
                return (
                  <div
                    key={id}
                    className="relative rounded-2xl bg-white shadow transition-colors duration-300 hover:bg-gray-200 dark:!bg-navy-800"
                  >
                    <div className="absolute top-2 right-2 cursor-pointer">
                      <TrashIcon
                        className="h-5 w-5 text-gray-500"
                        onClick={() => handleDelete(id)}
                      />
                    </div>
                    <div className="flex h-32  items-center justify-center ">
                      <svg
                        width="50"
                        height="50"
                        viewBox="0 0 40 40"
                        className="cursor-pointer"
                        onClick={() => setChoosenFolder(folderName)}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M33.9545 12.0578C35.3588 13.4274 34.0497 15.4163 32.0882 15.4163H6.16658C4.60178 15.4163 3.33325 14.1478 3.33325 12.583V10.6997C3.33325 6.63301 6.63325 3.33301 10.6999 3.33301H14.5666C17.2833 3.33301 18.1333 4.21634 19.2166 5.66634L21.5499 8.76634C22.0666 9.44967 22.1333 9.53301 23.0999 9.53301H27.7499C30.1622 9.53301 32.3521 10.4949 33.9545 12.0578Z"
                          fill={"#DCD133"}
                        />
                        <path
                          d="M33.8095 17.9161C35.3705 17.916 36.6375 19.1787 36.6428 20.7398L36.6666 27.7498C36.6666 32.6665 32.6666 36.6665 27.7499 36.6665H12.2499C7.33325 36.6665 3.33325 32.6665 3.33325 27.7498V20.7498C3.33325 19.185 4.60176 17.9164 6.16655 17.9164L33.8095 17.9161Z"
                          fill={"#DCD133"}
                        />
                      </svg>
                    </div>
                    <div className="p-2 text-center">
                      <p className="text-lg font-bold text-navy-700 dark:text-white">
                        {folderName}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GalerieView;
