import api from "Constants/api";
import { LOADING_ADD_CLASS } from "Redux/ActionsType/classtype";
import { ADD_CLASS_SUCCESS } from "Redux/ActionsType/classtype";
import { GET_CLASS_FAILED } from "Redux/ActionsType/classtype";
import { GET_CLASS_SUCCESS } from "Redux/ActionsType/classtype";
import { LOADING_CLASS } from "Redux/ActionsType/classtype";
import { closeCentredModal, openSuccessModal } from "./modalAction";
import { ADD_CLASS_FAILED } from "Redux/ActionsType/classtype";
import { LOADING_DELETE_CLASS } from "Redux/ActionsType/classtype";
import { DELETE_CLASS_SUCCESS } from "Redux/ActionsType/classtype";
import { DELETE_CLASS_FAILED } from "Redux/ActionsType/classtype";
import { LOADING_UPDATE_CLASS } from "Redux/ActionsType/classtype";
import { UPDATE_CLASS_SUCCESS } from "Redux/ActionsType/classtype";
import { UPDATE_CANTINE_FAILED } from "Redux/ActionsType/cantineType";

export const getAllClass = () => async (dispatch) => {
  dispatch({ type: LOADING_CLASS });
  try {
    const token = localStorage.getItem("token");
    const data = await api.get(`/admin/classe`, {
      headers: { authorization: `Bearer ${token}` },
    });

    dispatch({
      type: GET_CLASS_SUCCESS,
      payload: data?.data?.data,
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: GET_CLASS_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const addNewClass = (classData) => async (dispatch) => {
  dispatch({ type: LOADING_ADD_CLASS });
  try {
    const token = localStorage.getItem("token");
    const data = await api.post(
      `/admin/classe`,
      { name: classData?.name, description: classData?.description },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );

    dispatch({ type: ADD_CLASS_SUCCESS, payload: data?.data?.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: ADD_CLASS_FAILED,
      payload: "Add class failed.",
    });
  }
};

export const updateClass = (classData, id) => async (dispatch) => {
  dispatch({ type: LOADING_UPDATE_CLASS });
  try {
    const token = localStorage.getItem("token");
    const data = await api.put(
      `/admin/classe/${id}`,
      { name: classData?.name, description: classData?.description },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );

    dispatch({ type: UPDATE_CLASS_SUCCESS, payload: data?.data?.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: UPDATE_CANTINE_FAILED,
      payload: "Add class failed.",
    });
  }
};

export const deleteClass = (id) => async (dispatch) => {
  dispatch({ type: LOADING_DELETE_CLASS });
  try {
    const token = localStorage.getItem("token");
    const data = await api.delete(
      `/admin/classe/${id}`,

      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch({ type: DELETE_CLASS_SUCCESS, payload: id });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: DELETE_CLASS_FAILED,
      payload: "Fialed .",
    });
  }
};
