import { assistanceformData } from "Constants/utils";
import InputField from "components/fields/InputField";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const Step3 = ({ formData, setFormData, parent_id, validationErrors }) => {
  const { parents, loadingUsers } = useSelector((state) => state.userReducer);
  useEffect(() => {
    if (parent_id) {
      setFormData({ ...formData, parent_id: parent_id });
    } else {
      setFormData({ ...formData, parent_id: parents[0]?.id });
    }
  }, []);
  const handleChange = (value, field) => {
    setFormData({ ...formData, [field]: value[field] });
  };
  return (
    <div className="block h-[100%] w-full items-center justify-center">
      {assistanceformData.map(
        ({ label, placeholder, type, field, required }, index) => {
          return (
            <InputField
              key={index}
              extra="mb-3 "
              label={label}
              placeholder={placeholder}
              type={type}
              field={field}
              required={required}
              defaultValue={formData[field]}
              setValue={(value) => handleChange(value, field)}
              totalInputs={formData}
              errMessage={validationErrors[field]}
            />
          );
        }
      )}
    </div>
  );
};

export default Step3;
