export const LOADING_NOTIF = "LOADING_NOTIF";
export const GET_NOTIF_SUCCESS = "GET_NOTIF_SUCCESS";
export const GET_NOTIF_FAILED = "GET_NOTIF_FAILED";

export const LOADING_ADD_NOTIF = "LOADING_ADD_NOTIF";
export const ADD_NOTIF_SUCCESS = "ADD_NOTIF_SUCCESS";
export const ADD_NOTIF_FAILED = "ADD_NOTIF_FAILED";

export const LOADING_DELETE_NOTIF = "LOADING_DELETE_NOTIF";
export const DELETE_NOTIF_SUCCESS = "DELETE_NOTIF_SUCCESS";
export const DELETE_NOTIF_FAILED = "DELETE_NOTIF_FAILED";

export const LOADING_UPDATE_FEEDBACK = "LOADING_UPDATE_FEEDBACK";
export const UPDATE_FEEDBACK_SUCCESS = "UPDATE_FEEDBACK_SUCCESS";
export const UPDATE_FEEDBACK_FAILED = "UPDATE_FEEDBACK_FAILED";
