import api from "Constants/api";
import { LOADING_ADD } from "Redux/ActionsType/parentType";
import { ADD_PARENTS_FAILED } from "Redux/ActionsType/parentType";
import { GET_PARENTS_FAILED } from "Redux/ActionsType/parentType";
import { GET_PARENTS_SUCCESS } from "Redux/ActionsType/parentType";
import { LOADING_PARENT } from "Redux/ActionsType/parentType";
import { closeCentredModal, openSuccessModal } from "./modalAction";
import { LOADING_DELETE } from "Redux/ActionsType/parentType";
import { DELETE_PARENT_SUCCESS } from "Redux/ActionsType/parentType";
import { DELETE_PARENT_FAILED } from "Redux/ActionsType/parentType";
import { ADD_PARENTS_SUCCESS } from "Redux/ActionsType/parentType";
import { UPDATE_PARENT_SUCCESS } from "Redux/ActionsType/parentType";
import { UPDATE_PARENT_FAILED } from "Redux/ActionsType/parentType";

export const addNewParent = (userData, callback) => async (dispatch) => {
  dispatch({ type: LOADING_ADD });
  try {
    const token = localStorage.getItem("token");
    const data = await api.post(
      `/admin/users`,
      { ...userData, role: "parent" },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    if (callback) {
      callback();
    }
    dispatch({ type: ADD_PARENTS_SUCCESS, payload: data?.data?.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("error", false));

    dispatch({
      type: ADD_PARENTS_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const updateParent = (id, userData) => async (dispatch) => {
  dispatch({ type: LOADING_ADD });
  try {
    const token = localStorage.getItem("token");
    const data = await api.put(`/admin/users/${id}`, userData, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: UPDATE_PARENT_SUCCESS, payload: data?.data?.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: UPDATE_PARENT_FAILED,
      payload: "Update failed.",
    });
  }
};

export const deleteParent = (id) => async (dispatch) => {
  dispatch({ type: LOADING_DELETE });
  try {
    const token = localStorage.getItem("token");
    const data = await api.delete(
      `/admin/users/${id}`,

      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch({ type: DELETE_PARENT_SUCCESS, payload: id });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    dispatch({
      type: DELETE_PARENT_FAILED,
      payload: "Fialed .",
    });
  }
};

export const validateStep1 =
  (parentData, userData, callback) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await api.post(
        `/admin/users`,
        { ...parentData, role: "parent" },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      await api.post(
        `parentGrantedAccesses`,
        { ...userData, parent_id: data?.data?.id },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      if (callback) {
        callback();
      }
      dispatch({ type: ADD_PARENTS_SUCCESS, payload: data?.data });
    } catch (error) {
      dispatch(openSuccessModal("error", false));
    }
  };

export const exportParent = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get(`/admin/responsible-export`, {
      headers: { authorization: `Bearer ${token}` },
      responseType: "blob",
    });

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const url = window.URL.createObjectURL(blob);

    // Create an anchor element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "downloaded_file1.xlsx";

    // Simulate a click event to trigger the download
    a.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};
