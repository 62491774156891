import { mapStudentData } from "Constants/utils";
import { mapServiceData } from "Constants/utils";
import { mapParentData } from "Constants/utils";
import { updateBilling } from "Redux/Actions/billingAction";
import { newBilling } from "Redux/Actions/billingAction";
import { getAllService } from "Redux/Actions/serviceAction";
import { getUserWithRole } from "Redux/Actions/userAction";
import CustomButton from "components/CustomButton";
import CustomSelect from "components/CustomSelect";
import MultiSelect from "components/MultiSelect";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

const BillingForm = ({ billing }) => {
  const dispatch = useDispatch();
  const [selectedParent, setSelectedParent] = useState();
  const [selectedStauts, setSelectedStatus] = useState();
  const [selectedServices, setSelectedServices] = useState();
  const { parents } = useSelector((state) => state.userReducer);
  const [amount, setAmount] = useState(0);
  const [listStudent, setListStudent] = useState();
  const [selectedStudents, setSelectedStudents] = useState(2);
  const { servicestypes, services } = useSelector(
    (state) => state.serviceReducer
  );
  useEffect(() => {
    if (billing) {
      const select = [
        { label: "unpaid", value: 0 },
        { label: "Paid", value: 2 },
        { label: "Partially Paid", value: 1 },
      ]?.find((el) => el?.label === billing?.status)?.value;
      setSelectedStatus(select);
    }
  }, []);
  useEffect(() => {
    dispatch(getAllService());
  }, []);
  const handleSubmit = () => {
    const formData = {
      user_id: Number(selectedParent),
      student_id: selectedStudents,

      payable_id: selectedServices,

      payable_type: "service",
      status: Number(selectedStauts),
      amount: amount,
    };

    if (billing) {
      dispatch(updateBilling(billing.id, Number(selectedStauts)));
    } else {
      dispatch(newBilling(formData));
    }
  };
  useEffect(() => {
    const students = parents.find((el) => el.id === +selectedParent)?.students;
    setListStudent(students);
    setSelectedStudents([]);
  }, [selectedParent]);

  useEffect(() => {
    const result = services?.filter((item) =>
      selectedServices?.includes(item.id)
    );
    const totalPrice = result?.reduce((sum, item) => sum + item.price, 0);
    setAmount(totalPrice);
  }, [selectedServices]);

  return (
    <div className="block w-full items-center justify-center">
      {!billing && (
        <>
          <CustomSelect
            data={mapParentData(parents, true)}
            label={"parents"}
            required={true}
            selected={selectedParent}
            setSelected={(value) => setSelectedParent(value)}
          />

          <div className="mt-4">
            <MultiSelect
              data={mapStudentData(listStudent, true)}
              label={"children"}
              bgWhite={true}
              selected={selectedStudents}
              setSelected={setSelectedStudents}
              // link={item?.attributes?.link}
            />
          </div>
        </>
      )}
      <div className="mt-2">
        <CustomSelect
          data={[
            { label: "unpaid", value: 0 },
            { label: "Paid", value: 2 },
            { label: "Partially Paid", value: 1 },
          ]}
          label={"Status"}
          required={true}
          selected={selectedStauts}
          defaultValue={selectedStauts}
          setSelected={(value) => setSelectedStatus(value)}
        />
      </div>
      {!billing && (
        <>
          <div className="mt-2">
            <MultiSelect
              data={mapServiceData(services, true)}
              label={"Services "}
              bgWhite={true}
              selected={selectedServices}
              setSelected={setSelectedServices}
            />
          </div>
          <div className="mt-2 p-2  font-medium text-navy-700">
            Price : {amount ? amount : billing?.amount}
          </div>
        </>
      )}
      <div className="mt-2 flex justify-between">
        <div className="mt-2 p-2  font-medium text-navy-700">
          Payment Methode : cash
        </div>
        <div className="font-regular mt-2 p-2  text-xs text-navy-700">
          no other option
        </div>
      </div>

      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={!billing ? "Ajouter" : "Update"}
            disabled={false}
            onClick={handleSubmit}
            // loading={loadingAdd}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingForm;
