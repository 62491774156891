import { ACTIVATE_LIGHT_MODE } from "Redux/ActionsType/generalType";
import { ACTVATE_DARK_MODE } from "Redux/ActionsType/generalType";

const initialState = {
  darkMode: false,
};

export const generalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTVATE_DARK_MODE:
      return {
        ...state,
        darkMode: true,
      };
    case ACTIVATE_LIGHT_MODE:
      return { ...state, darkMode: false };

    default:
      return state;
  }
};
