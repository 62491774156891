import { classformData } from "Constants/utils";
import { updateClass } from "Redux/Actions/classActions";
import { addNewClass } from "Redux/Actions/classActions";
import CustomButton from "components/CustomButton";
import InputField from "components/fields/InputField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ClassForm = ({ user }) => {
  const dispatch = useDispatch();
  const [classForm, setClassForm] = useState({
    name: user?.name || "",
    description: user?.description || "",
  });
  const [validationErrors, setValidationError] = useState({});
  const { classes, loadingAdd } = useSelector((state) => state.classReducer);

  const validateForm = () => {
    let valid = true;
    let errors = {};

    classformData.forEach(({ field, required, label }) => {
      if (required && !classForm[field]) {
        errors = { ...errors, [field]: `${label} is required !` };
        valid = false;
      }
    });

    setValidationError(errors);
    return valid;
  };
  const handleChange = (value, field) => {
    setClassForm({ ...classForm, [field]: value[field] });
    if (validationErrors[field] && value[field].length > 0) {
      const state = validationErrors;
      delete state[field];
      setValidationError(state);
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (user) {
        dispatch(updateClass(classForm, user.id));
      } else {
        dispatch(addNewClass(classForm));
      }
    }
  };
  return (
    <div className="block w-full items-center justify-center">
      {classformData?.map(({ label, type, field, placeholder }, index) => {
        return (
          <InputField
            key={index}
            extra="mb-3"
            label={label}
            placeholder={placeholder}
            type={type}
            field={field}
            required={true}
            defaultValue={classForm[field]}
            setValue={(value) => handleChange(value, field)}
            totalInputs={classForm}
            errMessage={validationErrors[field]}
          />
        );
      })}
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={user ? "Enregistrer" : "Ajouter"}
            disabled={!classForm.name || !classForm.description}
            onClick={handleSubmit}
            loading={loadingAdd}
          />
        </div>
      </div>
    </div>
  );
};

export default ClassForm;
