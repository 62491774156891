import { DELETE_CANTINE_FAILED } from "Redux/ActionsType/cantineType";
import { GET_CLASS_FAILED } from "Redux/ActionsType/classtype";
import { ADD_CLASS_SUCCESS } from "Redux/ActionsType/classtype";
import { LOADING_DELETE_CLASS } from "Redux/ActionsType/classtype";
import { UPDATE_CLASS_SUCCESS } from "Redux/ActionsType/classtype";
import { UPDATE_CLASS_FAILED } from "Redux/ActionsType/classtype";
import { LOADING_UPDATE_CLASS } from "Redux/ActionsType/classtype";
import { DELETE_CLASS_SUCCESS } from "Redux/ActionsType/classtype";
import { ADD_CLASS_FAILED } from "Redux/ActionsType/classtype";
import { LOADING_ADD_CLASS } from "Redux/ActionsType/classtype";
import { GET_CLASS_SUCCESS } from "Redux/ActionsType/classtype";
import { LOADING_CLASS } from "Redux/ActionsType/classtype";

const initialState = {
  loading: false,
  loadingAdd: false,
  loadingUpdate: false,
  loadingDeleteClass: false,
  classes: [],
  error: null,
};

export const classReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_CLASS:
      return {
        ...state,
        loading: true,
      };
    case GET_CLASS_SUCCESS:
      return { ...state, loading: false, classes: payload };
    case GET_CLASS_FAILED:
      return { ...state, loading: false, error: payload };

    case LOADING_ADD_CLASS:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_CLASS_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        classes: [payload, ...state.classes],
      };
    case ADD_CLASS_FAILED:
      return { ...state, loadingAdd: false, error: payload };

    case LOADING_DELETE_CLASS:
      return {
        ...state,
        loadingDeleteClass: true,
      };
    case DELETE_CLASS_SUCCESS:
      return {
        ...state,
        loadingDeleteClass: false,
        classes: state?.classes?.filter(({ id }) => +id !== +payload),
      };
    case DELETE_CANTINE_FAILED:
      return { ...state, loadingDeleteClass: false, error: payload };

    case LOADING_UPDATE_CLASS:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_CLASS_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        classes: state.classes.map((parent) =>
          parent.id === payload.id ? payload : parent
        ),
      };
    case UPDATE_CLASS_FAILED:
      return { ...state, loadingUpdate: false, error: payload };

    default:
      return state;
  }
};
