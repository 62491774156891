import { Loading } from "Constants/icons";
import { DeleteIcon } from "Constants/icons";
import { UpdateIcon } from "Constants/icons";
import { PlusIconNoStroke } from "Constants/icons";
import { reclamationData } from "Constants/utils";
import { deleteFeedback } from "Redux/Actions/feedbackAction";
import { closeCentredModal } from "Redux/Actions/modalAction";
import { openCentredModal } from "Redux/Actions/modalAction";
import Card from "components/card";
import FedbackForm from "components/feedback/FedbackForm";
import DeleteModal from "components/modal/DeleteModal";
import moment from "moment";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

const DevelopmentTable = ({ columnsData, tableData, loading }) => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.generalReducer);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const handleDelete = (id, name) => {
    dispatch(
      openCentredModal({
        content: (
          <DeleteModal
            name={"cet Avis"}
            // loading={loadingDelete}
            onSubmit={() => dispatch(deleteFeedback(id))}
            onCancel={() => dispatch(closeCentredModal())}
          />
        ),
        headerText: "Supprimer",
      })
    );
    // dispatch(deleteParent(cell.row.original.id))
  };

  const handleUpdate = (user) => {
    // dispatch(
    //   openCentredModal({
    //     content: <ParentForm user={user} />,
    //     headerText: "Edit Parent",
    //   })
    // );
    // dispatch(deleteParent(cell.row.original.id))
  };
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;
  return (
    <Card extra={"w-full h-full p-4"}>
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Liste des avis
        </div>
      </div>

      <div className="h-full overflow-x-scroll xl:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="mt-8 h-max w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups?.map((headerGroup, index) => (
              <tr {...headerGroup?.getHeaderGroupProps()} key={index}>
                {headerGroup?.headers?.map((column, index) => (
                  <th
                    {...column?.getHeaderProps(column?.getSortByToggleProps())}
                    className="border-b border-gray-200 pr-32 pb-[10px] text-start dark:!border-navy-700 "
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {loading ? (
            <tbody {...getTableBodyProps()}>
              <tr>
                <td colSpan={headerGroups[0]?.headers?.length}>
                  <div role=" w-full h-full p-4 d-flex items-center justify-center">
                    <Loading />
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page?.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row?.getRowProps()} key={index}>
                    {row?.cells?.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "ACTIONS") {
                        data = (
                          <div className="flex items-center gap-2">
                            <div
                              onClick={() => handleDelete(cell.row.original.id)} // Call the edit action function with row id
                              className=" cursor-pointer py-1 px-1"
                            >
                              <DeleteIcon darkMode={darkMode} />
                            </div>
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                dispatch(
                                  openCentredModal({
                                    content: (
                                      <FedbackForm
                                        feedback={cell.row.original}
                                      />
                                    ),
                                    headerText: "Envoyer Notification",
                                  })
                                )
                              }
                            >
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 13.5V7.5M9 10.5H15M9.9 19.2L11.36 21.1467C11.5771 21.4362 11.6857 21.5809 11.8188 21.6327C11.9353 21.678 12.0647 21.678 12.1812 21.6327C12.3143 21.5809 12.4229 21.4362 12.64 21.1467L14.1 19.2C14.3931 18.8091 14.5397 18.6137 14.7185 18.4645C14.9569 18.2656 15.2383 18.1248 15.5405 18.0535C15.7671 18 16.0114 18 16.5 18C17.8978 18 18.5967 18 19.1481 17.7716C19.8831 17.4672 20.4672 16.8831 20.7716 16.1481C21 15.5967 21 14.8978 21 13.5V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V13.5C3 14.8978 3 15.5967 3.22836 16.1481C3.53284 16.8831 4.11687 17.4672 4.85195 17.7716C5.40326 18 6.10218 18 7.5 18C7.98858 18 8.23287 18 8.45951 18.0535C8.76169 18.1248 9.04312 18.2656 9.2815 18.4645C9.46028 18.6137 9.60685 18.8091 9.9 19.2Z"
                                  stroke="#000000"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        );
                      } else if (cell.column.Header === "PARENT") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell?.value?.name}
                          </p>
                        );
                      } else if (cell.column.Header === "TYPE") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {
                              reclamationData.filter(
                                ({ id }) => id === cell.value
                              )[0]?.text
                            }
                          </p>
                        );
                      } else if (cell.column.Header === "STATUS") {
                        data = (
                          <p
                            className={`text-sm font-bold ${
                              cell.value === 0
                                ? " text-red-700"
                                : "text-green-700"
                            } dark:text-white`}
                          >
                            {cell.value === 0 ? "Non traité" : "traité"}
                          </p>
                        );
                      } else if (cell.column.Header === "DATE") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {moment(cell?.column?.created_at).format(
                              "MM/DD/YYYY"
                            )}
                          </p>
                        );
                      } else {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      }

                      return (
                        <td
                          {...cell?.getCellProps()}
                          key={index}
                          className="pt-[14px] pb-3 text-[14px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </Card>
  );
};

export default DevelopmentTable;
