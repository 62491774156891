import React from "react";
import { useState } from "react";

import { useRef } from "react";
import { useEffect } from "react";
import "./Select.css";
import { AiOutlineCheck } from "react-icons/ai";
const MultiSelect = ({
  data,
  label,
  required,
  icon,
  placeholder = "select",
  bgWhite,
  selected = [],
  setSelected,
}) => {
  const [open, setOpen] = useState(false);
  const elementRef = useRef(null);
  const [width, setWidth] = useState(0);
  const handleChange = (label, value) => {
    if (selected?.includes(value)) {
      setSelected(selected?.filter((item) => item !== value));
    } else {
      setSelected([...selected, value]);
    }
  };
  const formatSelectedItems = (selectedArray) => {
    const result = data?.filter((item) => selected?.includes(item.value));

    if (result?.length > 3) {
      return `${result?.length} items selected`;
    } else {
      return result?.map((item) => item?.label).join(", ");
    }
  };
  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);
  return (
    <div style={{ width: "100%" }} ref={elementRef}>
      <label
        className={` pb-2 text-sm text-navy-700 dark:text-white ${"ml-3 font-bold"}`}
      >
        {label}
        {required && <span className="text-red-600"> *</span>}
      </label>
      <div style={{ width: "100%", position: "relative" }}>
        <div
          className="flex h-12 w-full rounded-lg border  border-gray-200 p-2 text-sm  text-gray-900 dark:!border-white/10 dark:bg-navy-800 dark:text-white"
          onClick={() => setOpen(!open)}
        >
          <div style={{ width: "90%", display: "flex", alignItems: "center" }}>
            {selected.length !== 0 ? (
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "start",
                  marginLeft: "5px",
                }}
              >
                {formatSelectedItems(selected)}
              </div>
            ) : (
              <>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "start",
                    marginLeft: "5px",
                  }}
                >
                  {placeholder}
                </div>
              </>
            )}
          </div>
          {/* <div className="w10 d-flex">
              <ArrowDownIcon />
            </div> */}
        </div>
        {open && (
          <div
            className="   selectContainer bg-white"
            style={{ width: width - 8, position: "fixed", marginLeft: "2px" }}
          >
            {data?.map(({ id, value, label }, index) => {
              return (
                <div
                  key={value}
                  className={"  selectItem "}
                  style={{
                    backgroundColor: selected === label && "#59638b26",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                  onClick={() => handleChange(label, value)}
                >
                  {label}
                  {selected?.includes(value) && <AiOutlineCheck />}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelect;
