import DevelopmentTable from "./components/DevelopmentTable";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { columnsDataDevelopment } from "./variables/columnsData";
import { getUserWithRole } from "Redux/Actions/userAction";

const Tables = () => {
  const dispatch = useDispatch();
  const { teachers, loadingUsers } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(getUserWithRole("teacher"));
  }, []);
  return (
    <div>
      <div className="mt-5  h-full ">
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={teachers || []}
          loading={loadingUsers}
        />
      </div>
    </div>
  );
};

export default Tables;
