import moment from "moment";
import api from "../../Constants/api";
import { mapCalendar, mapCalendarObject } from "../../Constants/utils";
import {
  GET_CALENDAR_SUCCESS,
  GET_CALENDAR_FAILED,
  LOADING_CALENDAR,
  LOADING_EVENT_ADD,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILED,
  LOADING_EVENTS,
  GET_EVENTS_FAILED,
  LOADING_EVENT_TYPE_ADD,
  ADD_EVENT_TYPE_FAILED,
  GET_EVENTS_SUCCESS,
  ADD_EVENT_TYPE_SUCCESS,
  LOADING_EVENT_TYPE_UPDATE,
  UPDATE_EVENT_TYPE_SUCCESS,
  UPDATE_EVENT_TYPE_FAILED,
  LOADING_EVENT_TYPE_DELETE,
  DELETE_EVENT_TYPE_SUCCESS,
  DELETE_EVENT_TYPE_FAILED,
} from "../ActionsType/calendarType";
import { closeCentredModal, openSuccessModal } from "./modalAction";

export const getAllCalendar = () => async (dispatch) => {
  dispatch({ type: LOADING_CALENDAR });
  try {
    const token = await localStorage.getItem("token");
    const data = await api.get(`/admin/event`, {
      headers: { authorization: `Bearer ${token}` },
    });

    const mappedCalendar = mapCalendar(data?.data?.data);
    dispatch({ type: GET_CALENDAR_SUCCESS, payload: mappedCalendar });
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: GET_CALENDAR_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};
export const addNewEvent = (eventData) => async (dispatch) => {
  dispatch({ type: LOADING_EVENT_ADD });
  var a = moment(eventData.end);
  var b = moment(eventData.start);
  const dur = a.diff(b, "days");
  try {
    const token = localStorage.getItem("token");
    const data = await api.post(
      `/admin/event`,
      {
        title: eventData.title,
        content: eventData.content,
        start: eventData.start,
        duration: dur,
          color: eventData.color,
          event_type_id:eventData.event_type_id
      },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    const mappedCalendar = mapCalendarObject(data?.data?.data);

    dispatch({ type: ADD_EVENT_SUCCESS, payload: mappedCalendar });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    dispatch({
      type: ADD_EVENT_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const getAllEvents = () => async (dispatch) => {
    dispatch({ type: LOADING_EVENTS });
    try {
      const token = await localStorage.getItem("token");
      const data = await api.get(`/admin/eventType`, {
        headers: { authorization: `Bearer ${token}` },
      });
 
      dispatch({ type: GET_EVENTS_SUCCESS, payload: data?.data?.data });
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
      }
      dispatch({
        type: GET_EVENTS_FAILED,
        payload: "Unauthorized: Please check your credentials.",
      });
    }
  };

  export const addNewEventType = (eventData) => async (dispatch) => {
    dispatch({ type: LOADING_EVENT_TYPE_ADD });
   
    try {
      const token = localStorage.getItem("token");
      const data = await api.post(
        `/admin/eventType`,
        {
          name: eventData.name,
          description: eventData.description,
          color: eventData.color,
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
  
      dispatch({ type: ADD_EVENT_TYPE_SUCCESS, payload: data?.data?.data });
      dispatch(closeCentredModal());
      dispatch(openSuccessModal("Success", true));
    } catch (error) {
      dispatch(openSuccessModal("Error", false));
      dispatch({
        type: ADD_EVENT_TYPE_FAILED,
        payload: "Unauthorized: Please check your credentials.",
      });
    }
  };

  export const updateEventType = (eventData,eventId) => async (dispatch) => {
    dispatch({ type: LOADING_EVENT_TYPE_UPDATE});
   
    try {
      const token = localStorage.getItem("token");
      const data = await api.put(
        `/admin/eventType/${eventId}`,
        {
          name: eventData.name,
          description: eventData.description,
          color: eventData.color,
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      dispatch({ type: UPDATE_EVENT_TYPE_SUCCESS, payload: data?.data?.data });
      dispatch(closeCentredModal());
      dispatch(openSuccessModal("Success", true));
    } catch (error) {
      dispatch(openSuccessModal("Error", false));
      dispatch({
        type: UPDATE_EVENT_TYPE_FAILED,
        payload: "Unauthorized: Please check your credentials.",
      });
    }
  };

  export const deleteEventType= (id) => async (dispatch) => {
    dispatch({ type: LOADING_EVENT_TYPE_DELETE });
    try {
      const token = localStorage.getItem("token");
      const data = await api.delete(
        `/admin/eventType/${id}`,
  
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      dispatch({ type: DELETE_EVENT_TYPE_SUCCESS, payload: id });
      dispatch(closeCentredModal());
      dispatch(openSuccessModal("Success", true));
    } catch (error) {
      dispatch(openSuccessModal("Error", false));
      dispatch({
        type: DELETE_EVENT_TYPE_FAILED,
        payload: "Fialed .",
      });
    }
  };