export const OPEN_RECLAMATION_MODAL = "OPEN_RECLAMATION_MODAL";
export const CLOSE_RECLAMATION_MODAL = "CLOSE_RECLAMATION_MODAL";

export const OPEN_INFO_MODAL = "OPEN_INFO_MODAL";
export const CLOSE_INFO_MODAL = "CLOSE_INFO_MODAL";

export const OPEN_ERROR_MODAL = "OPEN_ERROR_MODAL";
export const CLOSE_ERROR_MODAL = "CLOSE_ERROR_MODAL";

export const OPEN_CENTRED_MODAL = "OPEN_CENTRED_MODAL";
export const CLOSE_CENTRED_MODAL = "CLOSE_CENTRED_MODAL";

export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

export const OPEN_SUCCESS_MODAL = "OPEN_SUCCESS_MODAL";
export const CLOSE_SUCCESS_MODAL = "CLOSE_SUCCESS_MODAL";
