import { useDisclosure } from "@chakra-ui/hooks";
import { mapServiceTypeData } from "Constants/utils";
import { openCentredModal } from "Redux/Actions/modalAction";
import { getAllServiceType } from "Redux/Actions/serviceAction";
import { getAllService } from "Redux/Actions/serviceAction";
import CustomSelect from "components/CustomSelect";
import Card from "components/card";
import ServiceTypeForm from "components/service/ServiceTypeForm";
import ServiceTypeItem from "components/service/ServiceTypeItem";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { FcGoodDecision } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";

const ServiceView = () => {
  const [selected, setSelected] = useState();
  const dispatch = useDispatch();
  const { servicestypes } = useSelector((state) => state.serviceReducer);
  useEffect(() => {
    dispatch(getAllServiceType());
  }, []);
  return (
    <div className="mt-5  h-full ">
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Service type
          </div>
          <div className="flex items-center">
            {/* <div className="w-60">
              <CustomSelect
                data={mapServiceTypeData(servicestypes, true)}
                selected={selected}
                setSelected={setSelected}
              />
            </div> */}
            <div className="align-items-center  flex gap-2">
              <Button
                label="New"
                icon="pi pi-plus"
                severity="success"
                onClick={() =>
                  dispatch(
                    openCentredModal({
                      content: <ServiceTypeForm />,
                      headerText: "New Student",
                    })
                  )
                }
              />
            </div>
          </div>
        </div>
      </Card>

      <div className="mt-4 h-full overflow-x-scroll rounded-[20px] bg-white p-4 shadow-md xl:overflow-x-hidden">
        <div className="mb-2 flex flex-row border-b-2 pb-2">
          <div className="flex-1 text-lg font-bold text-navy-700 dark:text-white">
            Name
          </div>
          <div className="flex-1 text-lg font-bold text-navy-700 dark:text-white">
            Description
          </div>
          <div className="flex-1 text-lg font-bold text-navy-700 dark:text-white">
            Actions
          </div>
        </div>
        {servicestypes?.map((service) => {
          return <ServiceTypeItem item={service} key={service?.id} />;
        })}
      </div>
    </div>
  );
};

export default ServiceView;
