import { getAllEvents } from 'Redux/Actions/calendarAction'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DevelopmentTable from './components/DevelopmentTable'
import { columnsDataDevelopment } from './variables/columnsData'

const EventsView = () => {
    const dispatch = useDispatch()
  const { eventType, loadingEvents } = useSelector((state) => state.calendarReducer);
    
    useEffect(() => {
      dispatch(getAllEvents())
    }, [])
    
  return (
    <div>
     <div className="mt-5  h-full ">
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={eventType || []}
          loading={loadingEvents}
        />
      </div>
    </div>
  )
}

export default EventsView
