import {
  CLOSE_CENTRED_MODAL,
  CLOSE_RECLAMATION_MODAL,
  CLOSE_SUCCESS_MODAL,
  OPEN_CENTRED_MODAL,
  OPEN_RECLAMATION_MODAL,
  OPEN_SUCCESS_MODAL,
} from "../ActionsType/modalType";

const initialState = {
  openModal: false,
  openCentredModal: false,
  openSuccessModal: false,
  isSuccess: false,
  content: null,
  contentSuccess: null,
  headerText: "",
};

export const modalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_RECLAMATION_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case CLOSE_RECLAMATION_MODAL:
      return { ...state, openModal: false };
    case OPEN_CENTRED_MODAL:
      return {
        ...state,
        openCentredModal: true,
        content: payload.content,
        headerText: payload.headerText,
      };
    case CLOSE_CENTRED_MODAL:
      return { ...state, openCentredModal: false, content: null };
    case OPEN_SUCCESS_MODAL:
      return {
        ...state,
        openSuccessModal: true,
        isSuccess: payload.isSuccess,
        contentSuccess: payload.content,
      };
    case CLOSE_SUCCESS_MODAL:
      return {
        ...state,
        openSuccessModal: false,
        isSuccess: false,
        contentSuccess: null,
      };
    default:
      return state;
  }
};
