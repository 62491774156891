import React, { useEffect } from "react";
import DevelopmentTable from "./components/DevelopmentTable";
import { notifsDataColumns } from "../parents/variables/columnsData";
import { getUserWithRole } from "Redux/Actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { getAllNotifs } from "Redux/Actions/notifAction";

const NotificationView = () => {
  const dispatch = useDispatch();
  const { notifs, loading } = useSelector((state) => state.notifReducer);
  useEffect(() => {
    dispatch(getAllNotifs());
    dispatch(getUserWithRole("parent"));
    dispatch(getUserWithRole("teacher"));
  }, []);

  return (
    <div>
      <div className="mt-5  h-full ">
        <DevelopmentTable
          columnsData={notifsDataColumns}
          tableData={notifs || []}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default NotificationView;
