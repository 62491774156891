export const LOADING_TEACHER = "LOADING_TEACHER";
export const GET_TEACHERS_SUCCESS = "GET_TEACHERS_SUCCESS";
export const GET_TEACHERS_FAILED = "GET_TEACHERS_FAILED";

export const LOADING_ADD = "LOADING_ADD";
export const ADD_TEACHERS_SUCCESS = "ADD_TEACHERS_SUCCESS";
export const ADD_TEACHERS_FAILED = "ADD_TEACHERS_FAILED";

export const LOADING_DELETE = "LOADING_DELETE";
export const DELETE_TEACHER_SUCCESS = "DELETE_TEACHER_SUCCESS";
export const DELETE_TEACHER_FAILED = "DELETE_TEACHER_FAILED";

export const UPDATE_TEACHER_SUCCESS = "UPDATE_TEACHER_SUCCESS";
export const UPDATE_TEACHER_FAILED = "UPDATE_TEACHER_FAILED";
