import { UpdateIcon } from "Constants/icons";
import { DeleteIcon } from "Constants/icons";
import { closeCentredModal } from "Redux/Actions/modalAction";
import { openCentredModal } from "Redux/Actions/modalAction";
import { deleteServiceType } from "Redux/Actions/serviceAction";
import DeleteModal from "components/modal/DeleteModal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceTypeForm from "./ServiceTypeForm";
import { deleteService } from "Redux/Actions/serviceAction";
import ServiceForm from "./Serviceform";

const ServiceItem = ({ item }) => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.generalReducer);
  const handleDelete = (id, name) => {
    dispatch(
      openCentredModal({
        content: (
          <DeleteModal
            name={name}
            // loading={loadingDelete}
            onSubmit={() => dispatch(deleteService(id))}
            onCancel={() => dispatch(closeCentredModal())}
          />
        ),
        headerText: "Delete Service ",
      })
    );
    // dispatch(deleteParent(cell.row.original.id))
  };

  const handleUpdate = (item) => {
    dispatch(
      openCentredModal({
        content: <ServiceForm item={item} />,
        headerText: "Edit Service type",
      })
    );
    // dispatch(deleteParent(cell.row.original.id))
  };
  return (
    <div className="flex flex-row p-2">
      <div className="text-md flex-1 font-medium text-navy-700 dark:text-white">
        {item?.name}
      </div>
      <div className="text-md flex-1 font-medium text-navy-700 dark:text-white">
        {item?.description}
      </div>
      <div className="text-md flex-1 font-medium text-navy-700 dark:text-white">
        {item?.price}
      </div>
      <div
        className={`text-md flex-1 font-medium  ${
          item?.status === 1 ? "text-green-500" : "text-red-500"
        }`}
      >
        {item?.status === 1 ? "Actif" : "Inactif"}
      </div>
      <div className="text-md flex-1 font-medium text-navy-700 dark:text-white">
        <div className="flex gap-2">
          <div
            onClick={() => handleDelete(item?.id, item?.name)}
            className=" py-1 px-1 "
          >
            <DeleteIcon darkMode={darkMode} />
          </div>
          <div
            onClick={() =>
              // console.log(cell.row.original.name)

              handleUpdate(item)
            } // Call the delete action function with row id
            className=" py-1 px-1 "
          >
            <UpdateIcon darkMode={darkMode} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceItem;
