export const LOADING_BILLING = "LOADING_BILLING";
export const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS";
export const GET_BILLING_FAILED = "GET_BILLING_FAILED";

export const LOADING_BILLING_ADD = "LOADING_BILLING_ADD";
export const ADD_BILLING_SUCCESS = "ADD_BILLING_SUCCESS";
export const ADD_BILLING_FAILED = "ADD_BILLING_FAILED";

export const UPDATE_BILLING_SUCCESS = "UPDATE_BILLING_SUCCESS";
export const UPDATE_BILLING_FAILED = "UPDATE_BILLING_FAILED";

export const DELETE_BILLING_SUCCESS = "DELETE_BILLING_SUCCESS";
export const DELETE_BILLING_FAILED = "DELETE_BILLING_FAILED";
