import { mapClassData } from "Constants/utils";
import { programData } from "Constants/utils";
import { getAllClass } from "Redux/Actions/classActions";
import { updateProgram } from "Redux/Actions/programAction";
import { addNewProgram } from "Redux/Actions/programAction";
import CustomButton from "components/CustomButton";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

const ProgramForm = ({ program }) => {
  const dispatch = useDispatch();

  const [validationErrors, setValidationError] = useState({});
  const [programInputs, setProgramInputs] = useState({
    name: program?.name || null,
    description: program?.description || null,
    start_date: null,
  });

  const handleChange = (value, field) => {
    setProgramInputs({ ...programInputs, [field]: value[field] });
  };

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!programInputs.start_date) {
      errors = { ...errors, start_date: "date is required !" };
      valid = false;
    } else if (!programInputs.name) {
      errors = { ...errors, name: "name is required !" };
      valid = false;
    } else if (!programInputs.description) {
      errors = { ...errors, description: "description is required !" };
      valid = false;
    }

    setValidationError(errors);
    return valid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (program) {
        dispatch(updateProgram(program.id, programInputs));
      } else {
        dispatch(addNewProgram(programInputs));
      }
    }
  };
  return (
    <div>
      {programData?.map(({ label, type, field, placeholder }, index) => {
        return (
          <InputField
            key={index}
            extra="mb-3"
            label={label}
            placeholder={placeholder}
            type={type}
            field={field}
            required={true}
            defaultValue={programInputs[field]}
            setValue={(value) => handleChange(value, field)}
            totalInputs={programInputs}
            errMessage={validationErrors[field]}
          />
        );
      })}
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={program ? "Enregistrer" : "Ajouter"}
            disabled={!programInputs.name || !programInputs.description}
            onClick={handleSubmit}
            loading={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgramForm;
