import React, { useCallback, useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "assets/css/Calendar.css";
import ICalendar from "components/calendar/ICalendar";
import { useDispatch, useSelector } from "react-redux";
import { getAllCalendar } from "Redux/Actions/calendarAction";
import EventForm from "components/calendar/EventForm";
import { openCentredModal } from "Redux/Actions/modalAction";
const CalenDarView = () => {
  const dispatch = useDispatch();
  const { events, loading } = useSelector((state) => state.calendarReducer);
  const [myevents, setEvents] = useState();
  useEffect(() => {
    dispatch(getAllCalendar());
  }, []);

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      dispatch(
        openCentredModal({
          content: <EventForm start={start} end={end} />,
          headerText: "Nouvel évènement",
        })
      );
      // if (title) {
      //   // setEvents((prev) => [...prev, { start, end, title }])
      // }
    },
    [setEvents]
  );

  const handleSelectEvent = useCallback(
    (event) => window.alert(event.title),
    []
  );
  return (
    <div className="mt-5  h-full ">
      <ICalendar
        events={events}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
      />
    </div>
  );
};

export default CalenDarView;
