import { ServiceTypeFormData } from "Constants/utils";
import { updateServiceType } from "Redux/Actions/serviceAction";
import { newServiceType } from "Redux/Actions/serviceAction";
import CustomButton from "components/CustomButton";
import InputField from "components/fields/InputField";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

const ServiceTypeForm = ({ item }) => {
  const dispatch = useDispatch();
  const [classForm, setClassForm] = useState({
    name: item?.name || "",
    description: item?.description || "",
  });
  const [validationErrors, setValidationError] = useState({});

  const validateForm = () => {
    let valid = true;
    let errors = {};

    ServiceTypeFormData.forEach(({ field, required, label, errorMessage }) => {
      if (required && !classForm[field]) {
        errors = { ...errors, [field]: errorMessage };
        valid = false;
      }
    });

    setValidationError(errors);
    return valid;
  };

  const handleChange = (value, field) => {
    setClassForm({ ...classForm, [field]: value[field] });
    if (validationErrors[field] && value[field].length > 0) {
      const state = validationErrors;
      delete state[field];
      setValidationError(state);
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (item) {
        dispatch(updateServiceType(item?.id, classForm));
      } else {
        dispatch(newServiceType(classForm));
      }
    }
  };
  return (
    <div className="block w-full items-center justify-center">
      {ServiceTypeFormData?.map(
        ({ label, type, field, placeholder }, index) => {
          return (
            <InputField
              key={index}
              extra="mb-3"
              label={label}
              placeholder={placeholder}
              type={type}
              field={field}
              required={true}
              defaultValue={classForm[field]}
              setValue={(value) => handleChange(value, field)}
              totalInputs={classForm}
              errMessage={validationErrors[field]}
            />
          );
        }
      )}
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={"Ajouter"}
            disabled={!classForm.name || !classForm.description}
            onClick={handleSubmit}
            // loading={loadingAdd}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceTypeForm;
