import moment from "moment";

import api from "../../Constants/api";

import {
  GET_BILLING_FAILED,
  ADD_BILLING_SUCCESS,
  GET_BILLING_SUCCESS,
  LOADING_BILLING,
  LOADING_BILLING_ADD,
  ADD_BILLING_FAILED,
  UPDATE_BILLING_SUCCESS,
  UPDATE_BILLING_FAILED,
  DELETE_BILLING_SUCCESS,
  DELETE_BILLING_FAILED,
} from "../ActionsType/billingType";
import { closeCentredModal, openSuccessModal } from "./modalAction";
import { mapBilling } from "Constants/utils";

export const getAllBilling = () => async (dispatch) => {
  dispatch({ type: LOADING_BILLING });
  try {
    const token = await localStorage.getItem("token");

    const data = await api.get(`/admin/payments`, {
      headers: { authorization: `Bearer ${token}` },
    });

    const mappedBilling = mapBilling(data?.data?.data);
    dispatch({ type: GET_BILLING_SUCCESS, payload: mappedBilling });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_BILLING_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const newBilling = (payment) => async (dispatch) => {
  dispatch({ type: LOADING_BILLING_ADD });
  try {
    const token = await localStorage.getItem("token");

    const data = await api.post(`/admin/payments`, payment, {
      headers: { authorization: `Bearer ${token}` },
    });
    const mappedBilling = mapBilling(data?.data?.data);
    dispatch({ type: ADD_BILLING_SUCCESS, payload: mappedBilling });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: ADD_BILLING_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const updateBilling = (id, payment) => async (dispatch) => {
  dispatch({ type: LOADING_BILLING_ADD });
  try {
    const token = await localStorage.getItem("token");

    const data = await api.put(
      `/admin/payments/${id}`,
      { status: payment },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );

    dispatch({ type: UPDATE_BILLING_SUCCESS, payload: data.data.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: UPDATE_BILLING_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const deleteBillingAction = (id) => async (dispatch) => {
  try {
    const token = await localStorage.getItem("token");

    const data = await api.delete(`/admin/payments/${id}`, {
      headers: { authorization: `Bearer ${token}` },
    });

    dispatch({ type: DELETE_BILLING_SUCCESS, payload: id });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: DELETE_BILLING_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};
