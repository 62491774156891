export const LOADING_USER = "LOADING_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

export const FEEDBACK_LOADING = "FEEDBACK_LOADING";
export const CANCEL_FEEDBACK_LOADING = "CANCEL_FEEDBACK_LOADING";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const LOADING_USERS_WITH_ROLE = "LOADING_USERS_WITH_ROLE";
export const GET_USERS_WITH_ROLE_SUCCESS = "GET_USERS_WITH_ROLE_SUCCESS";
export const GET_USERS_WITH_ROLE_FAILED = "GET_USERS_WITH_ROLE_FAILED";
export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_FAILED = "GET_FEEDBACK_FAILED";

export const LOADING_DELETE_FEEDBACK = "LOADING_DELETE_FEEDBACK";
export const DELETE_FEEDBACK_SUCCESS = "DELETE_FEEDBACK_SUCCESS";
export const DELETE_FEEDBACK_FAILED = "DELETE_FEEDBACK_FAILED";

export const LOADING_GET_ABSENCES = "LOADING_GET_ABSENCES";
export const GET_ABSENCES_SUCCESS = "GET_ABSENCES_SUCCESS";
export const GET_ABSENCES_FAILED = "GET_ABSENCES_FAILED";

export const GET_STATS_SUCCESS = "GET_STATS_SUCCESS";
export const GET_STATS_FAILED = "GET_STATS_FAILED";
