import CheckTable from "./components/CheckTable";

import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "./variables/columnsData";
import tableDataDevelopment from "./variables/tableDataDevelopment.json";

import DevelopmentTable from "./components/DevelopmentTable";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserWithRole } from "Redux/Actions/userAction";

const Tables = () => {
  const dispatch = useDispatch();
  const { parents, loadingUsers } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(getUserWithRole("parent"));
  }, []);
  return (
    <div>
      <div className="mt-5  h-full ">
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={parents || []}
          loading={loadingUsers}
        />
      </div>
    </div>
  );
};

export default Tables;
