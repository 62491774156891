import { mapServiceTypeData } from "Constants/utils";
import { trimesterData } from "Constants/utils";
import { statusData } from "Constants/utils";
import { ServiceFormData } from "Constants/utils";
import { updateServiceType } from "Redux/Actions/serviceAction";
import { newService } from "Redux/Actions/serviceAction";
import { updateService } from "Redux/Actions/serviceAction";
import { newServiceType } from "Redux/Actions/serviceAction";
import CustomButton from "components/CustomButton";
import CustomSelect from "components/CustomSelect";
import InputField from "components/fields/InputField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ServiceForm = ({ item }) => {
  const dispatch = useDispatch();
  const { servicestypes, services } = useSelector(
    (state) => state.serviceReducer
  );
  const [classForm, setClassForm] = useState({
    name: item?.name || "",
    description: item?.description || "",
    price: item?.price || "",
    service_type_id: item?.service_type_id || 1,
    trimester_id: item?.trimester_id || 1,
    status: item?.status || 1,
  });
  const [validationErrors, setValidationError] = useState({});

  const validateForm = () => {
    let valid = true;
    let errors = {};

    ServiceFormData.forEach(({ field, required, label, errorMessage }) => {
      if (required && !classForm[field]) {
        errors = { ...errors, [field]: errorMessage };
        valid = false;
      }
    });

    setValidationError(errors);
    return valid;
  };

  const handleChange = (value, field) => {
    setClassForm({ ...classForm, [field]: value[field] });
    if (validationErrors[field] && value[field].length > 0) {
      const state = validationErrors;
      delete state[field];
      setValidationError(state);
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (item) {
        dispatch(updateService(item?.id, classForm));
      } else {
        dispatch(newService(classForm));
      }
    }
  };
  return (
    <div className="block w-full items-center justify-center">
      {ServiceFormData?.map(({ label, type, field, placeholder }, index) => {
        return type === "select" ? (
          field === "service_type_id" ? (
            <CustomSelect
              key={index}
              data={mapServiceTypeData(servicestypes, true)}
              selected={classForm[field]}
              setSelected={(v) => setClassForm({ ...classForm, [field]: v })}
              label="Service type"
              required
            />
          ) : field === "status" ? (
            <CustomSelect
              key={index}
              data={statusData}
              selected={classForm[field]}
              setSelected={(v) => setClassForm({ ...classForm, [field]: v })}
              label="Status"
              required
            />
          ) : (
            <CustomSelect
              key={index}
              data={trimesterData}
              selected={classForm[field]}
              setSelected={(v) => setClassForm({ ...classForm, [field]: v })}
              label="Trimester"
              required
              extra="mt-2"
            />
          )
        ) : (
          <InputField
            key={index}
            extra="mb-3"
            label={label}
            placeholder={placeholder}
            type={type}
            field={field}
            required={true}
            defaultValue={classForm[field]}
            setValue={(value) => handleChange(value, field)}
            totalInputs={classForm}
            errMessage={validationErrors[field]}
          />
        );
      })}
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={"Ajouter"}
            disabled={!classForm.name || !classForm.description}
            onClick={handleSubmit}
            // loading={loadingAdd}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceForm;
