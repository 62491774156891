import React, { useEffect } from "react";
import DevelopmentTable from "./components/DevelopmentTable";
import { useDispatch, useSelector } from "react-redux";
import { useSelect } from "@material-tailwind/react";
import { columnsDataAbsence } from "../parents/variables/columnsData";
import { getAllAbsence } from "Redux/Actions/userAction";

const AbsenceView = () => {
  const dispatch = useDispatch();
  const { absences } = useSelector((state) => state.userReducer);
  useEffect(() => {
    dispatch(getAllAbsence());
  }, []);
  return (
    <div>
      <div className="mt-5  h-full ">
        <DevelopmentTable
          columnsData={columnsDataAbsence}
          tableData={absences || []}
          loading={false}
        />
      </div>
    </div>
  );
};

export default AbsenceView;
