import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { CustomerService } from "../../../service/CustomerService";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { getAllBilling } from "Redux/Actions/billingAction";
import { Toast } from "primereact/toast";

import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { ListBox } from "primereact/listbox";
import { openCentredModal } from "Redux/Actions/modalAction";
import BillingForm from "./BillingForm";
import { getUserWithRole } from "Redux/Actions/userAction";
import { deleteBillingAction } from "Redux/Actions/billingAction";
import BillingExport from "./BillingExport";

export default function BasicFilterDemo() {
  const _ = require("lodash");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    amount: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "student.full_name": {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    "student.user.name": { value: null, matchMode: FilterMatchMode.EQUALS },
    reference: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [Billings, setBillings] = useState(null);
  const [billingDialog, setBillingDialog] = useState(false);
  const [deleteBillingDialog, setDeleteBillingDialog] = useState(false);
  const [deleteBillingsDialog, setDeleteBillingsDialog] = useState(false);
  const [billing, setBilling] = useState(emptyBilling);
  const [selectedBillings, setSelectedBillings] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [statuses] = useState(["unpaid", "Paid", "Partially Paid"]);
  const [selectedStudent, setSelectedStudent] = useState(billing);
  const [selectedParent, setSelectedParent] = useState(billing);
  const [selectedStatus, setSelectedStatus] = useState();

  const dt = useRef(null);
  const toast = useRef(null);
  var emptyBilling = {
    id: null,
    reference: "",
    amount: null,
    student: "",
    parent: null,
    status: 0,
  };
  const getSeverity = (status) => {
    switch (status) {
      case "Paid":
        return "success";

      case "Unpaid":
        return "danger";

      case "Partially Paid":
        return "info";

      // case "negotiation":
      //     return "warning";

      // case "renewal":
      //     return null;
    }
  };
  const dispatch = useDispatch();
  const { billings, loading } = useSelector((state) => state.billingReducer);
  useEffect(() => {
    dispatch(getAllBilling());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(getUserWithRole("parent"));
  }, []);
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="align-items-center flex">
        <div className=" flex-1">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
        <div className="align-items-center  flex gap-2">
          <Button
            label="Exporter"
            severity="success"
            onClick={() =>
              dispatch(
                openCentredModal({
                  content: <BillingExport billings={billings} />,
                  headerText: "Export Billing",
                })
              )
            }
          />
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            severity="success"
            onClick={() =>
              dispatch(
                openCentredModal({
                  content: <BillingForm />,
                  headerText: "New Billing",
                })
              )
            }
          />
        </div>
      </div>
    );
  };

  const StudentBodyTemplate = (rowData) => {
    return (
      <div className="align-items-center flex gap-2">
        <span>{rowData?.student?.full_name}</span>
      </div>
    );
  };
  const ParentBodyTemplate = (rowData) => {
    return (
      <div className="align-items-center flex gap-2">
        <span>{rowData?.student?.user?.name}</span>
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
    );
  };

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };

  const verifiedBodyTemplate = (rowData) => {
    return (
      <i
        className={classNames("pi", {
          "true-icon pi-check-circle": rowData.verified,
          "false-icon pi-times-circle": !rowData.verified,
        })}
      ></i>
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const verifiedRowFilterTemplate = (options) => {
    return (
      <TriStateCheckbox
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };
  const cols = [
    { field: "reference", header: "Ref" },
    { field: "amount", header: "Amount" },
    { field: "student", header: "Student" },
    { field: "parent", header: "Parent" },
    { field: "status", header: "Status" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    let bllingMap = billings.map((billing) => {
      return {
        ...billing,

        student: billing.student.full_name,
        parent: billing.student.user.name,
      };
    });

    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, bllingMap);
        doc.save("billing-list.pdf");
      });
    });
  };

  const exportExcel = () => {
    const mappedData = billings?.map((el) => ({
      Èlève: el?.student?.first_name + " " + el?.student?.last_name,
      Responsable: el?.student?.user?.name,
      Service: el?.service?.name,
      Status: el?.status,
      amount: el?.amount,
    }));

    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(mappedData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "billing-list");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "TND",
    });
  };

  const openNew = () => {
    setBilling(emptyBilling);
    setSubmitted(false);
    setBillingDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setBillingDialog(false);
  };

  const hideDeleteBillingDialog = () => {
    setDeleteBillingDialog(false);
  };

  const hideDeleteBillingsDialog = () => {
    setDeleteBillingsDialog(false);
  };

  const saveBilling = () => {
    setSubmitted(true);

    if (billing.name.trim()) {
      let _billings = [...Billings];
      let _billing = { ...billing };

      if (billing.id) {
        const index = findIndexById(billing.id);

        _billings[index] = _billing;
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "billing Updated",
          life: 3000,
        });
      } else {
        _billing.id = createId();
        _billing.image = "billing-placeholder.svg";
        _billings.push(_billing);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "billing Created",
          life: 3000,
        });
      }

      setBillings(_billings);
      setBillingDialog(false);
      setBilling(emptyBilling);
    }
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };
  const editBilling = (billing) => {
    dispatch(
      openCentredModal({
        content: <BillingForm billing={billing} />,
        headerText: "New Billing",
      })
    );
  };

  const confirmDeleteBilling = (Billing) => {
    setBilling(Billing);
    setDeleteBillingDialog(true);
  };

  const deleteBilling = (id) => {
    let _billings = billings.filter((val) => val.id !== billing.id);
    setBillings(_billings);
    setDeleteBillingDialog(false);
    setBilling(emptyBilling);
    dispatch(deleteBillingAction(id));
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Billing Deleted",
      life: 3000,
    });
  };

  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < Billings.length; i++) {
      if (Billings[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };
  const deleteSelectedBillings = () => {
    let _Billings = billings.filter((val) => !selectedBillings.includes(val));

    setBillings(_Billings);
    setDeleteBillingsDialog(false);
    setSelectedBillings(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Billings Deleted",
      life: 3000,
    });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editBilling(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteBilling(rowData)}
        />
      </React.Fragment>
    );
  };

  const billingDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={saveBilling} />
    </React.Fragment>
  );
  const deleteBillingDialogFooter = (rowData) => (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteBillingDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={() => deleteBilling(rowData)}
      />
    </React.Fragment>
  );
  const deleteBillingsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteBillingsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedBillings}
      />
    </React.Fragment>
  );
  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        {/* <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedBillings || !selectedBillings.length}
        /> */}
      </div>
    );
  };
  const confirmDeleteSelected = () => {
    setDeleteBillingsDialog(true);
  };
  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };
  const mapField = (billings, field, option) => {
    const uniqueValues = new Set();

    return billings.reduce((result, billing) => {
      let _billing = { ...billing };

      const value = _.get(billing, field);

      if (!uniqueValues.has(value)) {
        uniqueValues.add(value);
        const newObj = { id: _billing?.user?.id, [option]: value };
        result.push(newObj);
      }
      return result;
    }, []);
  };
  const header = renderHeader();
  return (
    <div className="card mt-5">
      <Toast ref={toast} />

      <DataTable
        value={billings}
        paginator
        rows={10}
        dataKey="id"
        loading={loading}
        filters={filters}
        globalFilterFields={[
          "service.name",
          "student.full_name",
          "student.user.name",
          "status",
          "amount",
        ]}
        header={header}
        emptyMessage="No Billings found."
      >
        <Column
          field="service.name"
          header="Service"
          style={{ minWidth: "12rem" }}
        />
        <Column header="Amount" field="amount" style={{ minWidth: "2rem" }} />

        <Column
          header="Student"
          filterField="student.full_name"
          style={{ minWidth: "5rem" }}
          body={StudentBodyTemplate}
        />
        <Column
          header="Parents"
          style={{ minWidth: "10rem" }}
          body={ParentBodyTemplate}
          filterField="student.user.name"
        />

        <Column
          field="status"
          header="Status"
          showFilterMenu={false}
          body={statusBodyTemplate}
        />
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "12rem" }}
        ></Column>
      </DataTable>

      <Dialog
        visible={deleteBillingDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={() => deleteBillingDialogFooter(billing?.id)}
        onHide={hideDeleteBillingDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {billing && (
            <span>
              Are you sure you want to delete <b>{billing?.reference}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteBillingsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteBillingsDialogFooter}
        onHide={hideDeleteBillingsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {billing && (
            <span>Are you sure you want to delete the selected Billings?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
