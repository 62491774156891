import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Card from "components/card";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const ReactionsChart = ({ data }) => {
  const chartData = {
    labels: ["Sad", "Happy", "Satisfied"],
    datasets: [
      {
        label: "Cantine",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        data: Object.values(data.cantine),
      },
      {
        label: "Programme",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        data: Object.values(data.garderie),
      },
    ],
  };
  return (
    <div className="  h-[500px] w-full rounded-[10px] bg-white p-4  text-lg font-bold text-darkBg">
      <h2>Réactions</h2>
      <Bar data={chartData} />
    </div>
  );
};

export default ReactionsChart;
