import { IconContext } from "react-icons";
import { GiTeacher } from "react-icons/gi";
const TeacherIcon = ({ h = "6", w = "6" }) => {
  return (
    <IconContext.Provider
      value={{
        color:
          "linear-gradient(to right, rgb(254, 202, 202), rgb(252, 165, 165), rgb(254, 240, 138))",
        className: `h-${h} w-${w} bgradient-to-r from-red-200 via-red-300 to-yellow-200`,
      }}
    >
      <div>
        <GiTeacher />
      </div>
    </IconContext.Provider>
  );
};

export default TeacherIcon;
