import { mapParentData } from "Constants/utils";
import { mapClassData } from "Constants/utils";
import { isMail } from "Constants/utils";
import { parentData } from "Constants/utils";
import { inscriptionformData } from "Constants/utils";
import { addNewParent } from "Redux/Actions/ParentAction";
import { getAllClass } from "Redux/Actions/classActions";
import { inscription } from "Redux/Actions/studentAction";
import { addNewStudent } from "Redux/Actions/studentAction";
import { getUserWithRole } from "Redux/Actions/userAction";
import CustomButton from "components/CustomButton";
import CustomSelect from "components/CustomSelect";
import Card from "components/card";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { studentformData } from "Constants/utils";
import { addParentGrantedAccesses } from "Redux/Actions/studentAction";
import { aaHealthInfo } from "Redux/Actions/studentAction";
import { validateStep1 } from "Redux/Actions/ParentAction";
import { assistanceSecondaireformData } from "Constants/utils";
const steps = [
    { number: 1, title: 'Parents' },
    { number: 2, title: 'Enfant' },
    { number: 3, title: 'Responsable' },
    { number: 4, title: 'Santé' }
  ];
const InscriptionView = () => {
  const dispatch = useDispatch();

  const { healthInfos, students } = useSelector(
    (state) => state.studentReducer
  );
  const { parents } = useSelector((state) => state.userReducer);
  const [validationErrors, setValidationError] = useState({});
  const [validationStudentsErrors, setValidationStudentsError] = useState({});
  const [validationAssistErrors, setValidationAssistError] = useState({});
  const [validationAssistSecErrors, setValidationAssistSecError] = useState({});

  useEffect(() => {
    dispatch(getUserWithRole("parent"));
    dispatch(getAllClass());
  }, []);
  const phoneExists = (phoneToCheck) => {
    return parents.find((user) => +user.phone === +phoneToCheck);
  };
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [parentForm, setParentForm] = useState({});
  const [studentForm, setStudentForm] = useState({});
  const [assistForm, setAssistForm] = useState({});
  const [assistSecForm, setAssistSecForm] = useState({});
  const [answers, setAnswers] = useState({});
  const validateParentForm = () => {
    let valid = true;
    let errors = {};
    const ee = [
      { id: 1, field: "prenom", required: true },
      { id: 2, field: "nom", required: true },
      { id: 3, field: "email", required: false },
      { id: 4, field: "phone", required: true },
      { id: 5, field: "password", required: true },
    ];
    if (!parentForm?.user_id) {
      ee.forEach(({ field, required, label }) => {
        if (required && !parentForm[field]) {
          errors = { ...errors, [field]: `${field} is required !` };
          valid = false;
        }
      });

      if (parentForm?.name?.length < 4) {
        errors = { ...errors, name: `Name must be at least 4 characters !` };
        valid = false;
      }
      if (parentForm?.phone?.length < 8) {
        errors = { ...errors, phone: `Phone must be at least 8 digits !` };
        valid = false;
      }
      if (phoneExists(parentForm?.phone)) {
        errors = { ...errors, phone: `Phone exist !` };
        valid = false;
      }
      if (parentForm?.email && !isMail(parentForm?.email)) {
        errors = { ...errors, email: `invalid email !` };
        valid = false;
      }
    }
    setValidationError(errors);
    return valid;
  };

  const validateStudentsForm = () => {
    let valid = true;
    let errors = {};

    studentformData.forEach(({ field, required, label }) => {
      if (required && !studentForm[field]) {
        errors = { ...errors, [field]: `${label} is required !` };
        valid = false;
      }
    });

    setValidationStudentsError(errors);
    return valid;
  };
  const validateAccessForm = () => {
    let valid = true;
    let errors = {};
    const dd = [
      { id: 1, field: "prenom", required: true },
      { id: 2, field: "nom", required: true },
      { id: 3, field: "email", required: false },
      { id: 4, field: "phone", required: true },
      { id: 5, field: "relation", required: true },
    ];
    assistanceSecondaireformData.forEach(({ field, required, label }) => {
      if (required && !assistSecForm[field]) {
        errors = { ...errors, [field]: `${label} is required !` };
        valid = false;
      }
      if (phoneExists(assistSecForm?.phone)) {
        errors = { ...errors, phone: `Phone exist !` };
        valid = false;
      }
    });

    setValidationAssistSecError(errors);
    return valid;
  };
  const handleNext = () => {
    if (currentStep === 1) {
      if (validateParentForm() && validateAccessForm()) {
        if (parentForm?.user_id) {
          setCurrentStep(currentStep + 1);
        } else {
          dispatch(
            validateStep1(
              {
                name: parentForm.prenom + " " + parentForm.nom,
                phone: parentForm.phone,
                email: parentForm?.email !== "" ? parentForm?.email : undefined,
                password: parentForm.password,
                password_confirmation: parentForm.password,
              },
              assistSecForm,
              () => setCurrentStep(currentStep + 1)
            )
          );
        }
      }
    } else if (currentStep === 2) {
      if (validateStudentsForm()) {
        dispatch(
          addNewStudent(studentForm, () => setCurrentStep(currentStep + 1))
        );
      }
    } else if (currentStep === 3) {
      const requiredFields = ["name", "phone", "relation", "surname"];
      let hasNonEmptyField = false;

      if (hasNonEmptyField) {
        for (const field of requiredFields) {
          if (!assistForm[field]) {
            setValidationAssistError({
              ...validationAssistErrors,
              [field]: `${field} est obligatoire !`,
            });
          }
          if (phoneExists(assistForm?.phone)) {
            setValidationAssistError({
              ...validationAssistErrors,
              phone: `Phone exist !`,
            });
          }
        }
      } else if (assistForm.hasOwnProperty("parent_id")) {
        dispatch(
          addParentGrantedAccesses(assistForm, () =>
            setCurrentStep(currentStep + 1)
          )
        );
        // setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  const resetData = () => {
    setCurrentStep(1);
    setParentForm({});
    setStudentForm({});
    setAssistForm({});
    setAnswers({});
  };
    const handleFormSubmit = () => {
      
        
        for (const key in answers) {
          
            if (!isNaN(key)) {
            const    ques = healthInfos?.find((el) => el.id === +key)?.question;
            const    answerValue = answers[key];
        dispatch(
        aaHealthInfo(
          {
            question: ques,
            answer: answerValue === true ? 0 : 1,
                student_id: +students[0].id,
                description:answers[`desc${key}`]
            
          },
          () => resetData()
        )
      );
            }
            

    //   dispatch(
    //     aaHealthInfo(
    //       {
    //         question: ques,
    //         answer: answerValue === true ? 0 : 1,
    //         student_id: +students[0].id,
    //       },
    //       () => resetData()
    //     )
    //   );
        }
      
  };
  return (
      <div className="mt-5  h-full ">
        <div className="flex mx-5 justify-between mb-6">
        {steps.map((step, index) => (
 <div key={index} className=" flex items-center">
 <div className="flex flex-col items-center">
   <div
     className={`w-10 h-10 rounded-full flex items-center justify-center 
     ${step.number <= currentStep ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-600'}`}
   >
     {step.number}
   </div>
   <div className={`mt-2 text-sm ${step.number <= currentStep ? 'font-semibold text-blue-500' : 'text-gray-600'}`}>
     {step.title}
   </div>
 </div>

</div>
))}
</div>
      <Card extra={"w-full h-full p-4"}>
        <div
          className={`relative flex ${
            currentStep === 1 ? "h-[85vh]" : "h-[75vh]"
          }  justify-between`}
        >
          <div className="block w-full items-center justify-center ">
            <div className="h-full">
              <div className="flex h-full flex-col justify-between">
                <div className="h-[60vh]">
                  {currentStep === 1 && (
                    <Step1
                      formData={parentForm}
                      setFormData={setParentForm}
                      validationErrors={validationErrors}
                      secAssist={assistSecForm}
                      setSecAssist={setAssistSecForm}
                      validationAssistSecErrors={validationAssistSecErrors}
                    />
                  )}
                  {currentStep === 2 && (
                    <Step2
                      formData={studentForm}
                      setFormData={setStudentForm}
                      parent_id={parentForm?.user_id}
                      validationErrors={validationStudentsErrors}
                    />
                  )}
                  {currentStep === 3 && (
                    <Step3
                      formData={assistForm}
                      setFormData={setAssistForm}
                      parent_id={parentForm?.user_id}
                      validationErrors={validationAssistErrors}
                    />
                  )}
                  {currentStep === 4 && (
                    <Step4 formData={answers} setFormData={setAnswers} />
                  )}
                </div>
                <div className="flex flex-row justify-between">
                  <div className="w-[20%]">
                    {currentStep > 1 && (
                      <div className="mx-2 flex justify-center">
                        <CustomButton
                          text={"Précedent"}
                          onClick={handlePrevious}
                        />
                      </div>
                    )}
                  </div>
                  <div className="w-[20%]">
                    {currentStep < 4 ? (
                      <div className="mx-2 flex justify-center">
                        <CustomButton text={"Suivant"} onClick={handleNext} />
                      </div>
                    ) : (
                      <div className="flex justify-center">
                        <CustomButton
                          text={"Envoyer"}
                          onClick={handleFormSubmit}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default InscriptionView;
