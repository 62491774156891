import { mapParentData } from "Constants/utils";
import { addNewNotif } from "Redux/Actions/notifAction";
import CustomButton from "components/CustomButton";
import CustomSelect from "components/CustomSelect";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

const NotifForm = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  const [title, setTitle] = useState();
  const [selected, setSelected] = useState("parent");
  const [selectedUsers, setSelectedUsers] = useState();
  const { parents, teachers, loadingUsers } = useSelector(
    (state) => state.userReducer
  );
  function handleSelect(data) {
    if (data.some((item) => item.value === "all")) {
      if (selected === "parent") {
        setSelectedUsers(
          mapParentData(parents, true).map((user) => user.value)
        );
      } else {
        setSelectedUsers(
          mapParentData(teachers, true).map((user) => user.value)
        );
      }
    } else {
      setSelectedUsers(data.map((user) => user.value));
    }
  }

  const handleSubmit = () => {
    dispatch(
      addNewNotif({
        title: title,
        message: message,
        selectedUsers: selectedUsers,
      })
    );
  };

  return (
    <div className="block w-full items-center justify-center">
      <InputField
        extra="mb-3"
        label={"Titre"}
        placeholder={"Titre"}
        field={"title"}
        required={true}
        setValue={(value) => setTitle(value["title"])}
      />
      <InputField
        extra="mb-3"
        label={"Contenu"}
        placeholder={"Entre votre message"}
        type={"text-area"}
        field={"message"}
        required={true}
        setValue={(value) => setMessage(value["message"])}
      />
      <CustomSelect
        data={[
          { label: "parent", value: "parent" },
          { label: "teacher", value: "teacher" },
        ]}
        selected={selected}
        setSelected={(value) => setSelected(value)}
        label={"Type"}
        required={true}
        extra={"mb-4"}
      />
      <div className="mt-4">
        <label
          className={` text-sm text-navy-700 dark:text-white ${"ml-3 font-bold"}`}
        >
          Users
          <span className="text-red-600"> *</span>
        </label>
        <Select
          options={
            selected === "parent"
              ? [
                  { value: "all", label: "Select All" },
                  ...mapParentData(parents, true),
                ]
              : [
                  { value: "all", label: "Select All" },
                  ...mapParentData(teachers, true),
                ]
          }
          placeholder="Select users"
          isMulti
          onChange={handleSelect}
          isSearchable={true}
        />
      </div>
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={"Envoyer"}
            disabled={!message || !selectedUsers}
            onClick={handleSubmit}
            loading={false}
          />
        </div>
      </div>
    </div>
  );
};

export default NotifForm;
