import api from "Constants/api";
import { GET_CALENDAR_FAILED } from "Redux/ActionsType/calendarType";
import { ADD_NOTIF_SUCCESS } from "Redux/ActionsType/notificationType";
import { ADD_NOTIF_FAILED } from "Redux/ActionsType/notificationType";
import { LOADING_ADD_NOTIF } from "Redux/ActionsType/notificationType";
import { GET_NOTIF_FAILED } from "Redux/ActionsType/notificationType";
import { GET_NOTIF_SUCCESS } from "Redux/ActionsType/notificationType";
import { LOADING_NOTIF } from "Redux/ActionsType/notificationType";
import { closeCentredModal, openSuccessModal } from "./modalAction";
import { LOADING_DELETE_NOTIF } from "Redux/ActionsType/notificationType";
import { DELETE_NOTIF_SUCCESS } from "Redux/ActionsType/notificationType";
import { DELETE_NOTIF_FAILED } from "Redux/ActionsType/notificationType";
import { LOADING_UPDATE_FEEDBACK } from "Redux/ActionsType/notificationType";
import { UPDATE_FEEDBACK_SUCCESS } from "Redux/ActionsType/notificationType";
import { UPDATE_FEEDBACK_FAILED } from "Redux/ActionsType/notificationType";

export const getAllNotifs = () => async (dispatch) => {
  dispatch({ type: LOADING_NOTIF });
  try {
    const token = localStorage.getItem("token");

    const data = await api.get(`/admin/notification`, {
      headers: { authorization: `Bearer ${token}` },
    });

    // console.log(data?.data?.data[0].absences);
    dispatch({ type: GET_NOTIF_SUCCESS, payload: data.data.data });
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: GET_NOTIF_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const deleteNotif = (id) => async (dispatch) => {
  dispatch({ type: LOADING_DELETE_NOTIF });
  try {
    const token = localStorage.getItem("token");

    const data = await api.delete(`/admin/notification/${id}`, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: DELETE_NOTIF_SUCCESS, payload: id });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: DELETE_NOTIF_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const addNewNotif = (notifData) => async (dispatch) => {
  dispatch({ type: LOADING_ADD_NOTIF });
  try {
    const token = localStorage.getItem("token");

    const data = await api.post(
      `/admin/notification`,
      {
        title: notifData?.title,
        content: notifData?.message,
        ids: notifData?.selectedUsers,
        feedbackId: notifData?.feedbackId,
      },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );

    dispatch({ type: ADD_NOTIF_SUCCESS, payload: data.data.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: ADD_NOTIF_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const answerAvis = (notifData) => async (dispatch) => {
  dispatch({ type: LOADING_UPDATE_FEEDBACK });
  try {
    const token = localStorage.getItem("token");

    const data = await api.post(
      `/admin/notificationFeedback`,
      {
        title: notifData?.title,
        content: notifData?.message,
        ids: notifData?.selectedUsers,
        feedbackId: notifData?.feedbackId,
      },
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );

    dispatch({ type: UPDATE_FEEDBACK_SUCCESS, payload: data.data.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: UPDATE_FEEDBACK_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};
