import axios from "axios";
import {
  CANCEL_FEEDBACK_LOADING,
  DELETE_FEEDBACK_SUCCESS,
  FEEDBACK_LOADING,
  GET_FEEDBACK_FAILED,
  GET_FEEDBACK_SUCCESS,
  LOADING_DELETE_FEEDBACK,
} from "../ActionsType/userType";
import api from "Constants/api";
import { closeCentredModal, openSuccessModal } from "./modalAction";
import { DELETE_CANTINE_FAILED } from "Redux/ActionsType/cantineType";

// Login
export const addFeedback = (feedback) => async (dispatch) => {
  dispatch({ type: FEEDBACK_LOADING });
  try {
    const token = await localStorage.getItem("token");
    await api.post(`/storeFeedback`, feedback, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: CANCEL_FEEDBACK_LOADING });
    // dispatch(handleInfo("add successfully !", 1));
    // dispatch(closeReclamationModal());
  } catch (error) {
    if (error) {
      dispatch({ type: CANCEL_FEEDBACK_LOADING });

      // dispatch(handleError("error while adding !", 1));
    }
  }
};

export const getFeedback = () => async (dispatch) => {
  dispatch({ type: FEEDBACK_LOADING });
  try {
    const token = localStorage.getItem("token");

    const data = await api.get(`/admin/feedback`, {
      headers: { authorization: `Bearer ${token}` },
    });

    dispatch({ type: GET_FEEDBACK_SUCCESS, payload: data.data.data });
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    if (error) {
      dispatch({ type: GET_FEEDBACK_FAILED, payload: error });
    }
  }
};

export const deleteFeedback = (id) => async (dispatch) => {
  dispatch({ type: LOADING_DELETE_FEEDBACK });
  try {
    const token = localStorage.getItem("token");
    const data = await api.delete(
      `/admin/feedback/${id}`,

      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    dispatch({ type: DELETE_FEEDBACK_SUCCESS, payload: id });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    dispatch({
      type: DELETE_CANTINE_FAILED,
      payload: "Fialed .",
    });
  }
};
