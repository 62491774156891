import {
  CLOSE_ERROR_MODAL,
  CLOSE_INFO_MODAL,
  CLOSE_NOTIFICATION,
  OPEN_ERROR_MODAL,
  OPEN_INFO_MODAL,
} from "../ActionsType/modalType";

export const handleInfo = (message, type) => async (dispatch) => {
  dispatch({
    type: type ? OPEN_INFO_MODAL : CLOSE_INFO_MODAL,
    payload: { message: message },
  });
};

export const handleError = (message, type) => async (dispatch) => {
  dispatch({ type: CLOSE_ERROR_MODAL });

  dispatch({
    type: type ? OPEN_ERROR_MODAL : CLOSE_ERROR_MODAL,
    payload: message,
  });
};

export const closeNotification = () => async (dispatch) => {
  dispatch({
    type: CLOSE_NOTIFICATION,
  });
};
