export const programDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
  {
    Header: "DATE",
    accessor: "start_date",
  },

  {
    Header: "ACTIONS",
    accessor: "id", // Use a unique identifier from your data as the accessor
  },
  // {
  //   Header: "PROGRESS",
  //   accessor: "progress",
  // },
];
