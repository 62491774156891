import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import Parents from "views/admin/parents";
import Teachers from "views/admin/teachers";
import Cantines from "views/admin/cantines";

import RTLDefault from "views/rtl/default";
import {
  FcCalendar,
  FcHome,
  FcGallery,
  FcFeedback,
  FcAbout,
  FcBusinessman,
  FcConferenceCall,
  FcCollaboration,
  FcMenu,
  FcMoneyTransfer,
  FcServices,
  FcTreeStructure,
  FcManager,
  FcBadDecision,
  FcBarChart,
  FcList,
} from "react-icons/fc";
import { HiBellAlert } from "react-icons/hi2";
import { GiTeacher } from "react-icons/gi";
import { MdFoodBank, MdOutlineClass } from "react-icons/md";
// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";
import GalerieView from "views/admin/Galeries";
import Files from "views/admin/Galeries/Files";
import StudentsView from "views/admin/students";
import ClassView from "views/admin/class";
import FeedbackView from "views/admin/feedback";
import NotificationView from "views/admin/notifications";
import CalenDarView from "views/admin/Calendar";
import BillingView from "views/admin/Billing";
import TeacherIcon from "components/icons/TeacherIcon";
import ServiceView from "views/admin/Service";
import Services from "views/admin/Service/Services";
import InscriptionView from "views/admin/Inscription";
import AbsenceView from "views/admin/Absence";
import DashboardView from "views/admin/Dashboard";
import ProgramView from "views/admin/Programs";
import EventsView from "views/admin/events";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    icon: <FcBarChart className="h-6 w-6" />,
    path: "dashboard",
    component: <DashboardView />,
    hidden: false, // Hide this route from the navigation menu
  },
  {
    name: "Inscription",
    layout: "/admin",
    icon: <FcManager className="h-6 w-6" />,
    path: "inscription",
    component: <InscriptionView />,
    hidden: false, // Hide this route from the navigation menu
    },
    {
        name: "Évènement",
        layout: "/admin",
        icon: <FcCalendar className="h-6 w-6" />,
        path: "eventsType",
        component: <EventsView />,
        hidden: false, // Hide this route from the navigation menu
      },
  {
    name: "Calendrier",
    layout: "/admin",
    icon: <FcCalendar className="h-6 w-6" />,
    path: "Events",
    component: <CalenDarView />,
    hidden: false, // Hide this route from the navigation menu
  },
  {
    name: "Notifications",
    layout: "/admin",
    icon: <HiBellAlert className="h-6 w-6  rounded " />,
    path: "notifications",
    component: <NotificationView />,
    hidden: false, // Hide this route from the navigation menu
  },
  {
    name: "Classes",
    layout: "/admin",
    icon: <MdOutlineClass className="h-6 w-6" />,
    path: "classes",
    component: <ClassView />,
    hidden: false, // Hide this route from the navigation menu
  },
  {
    name: "Programmes",
    layout: "/admin",
    icon: <FcList className="h-6 w-6" />,
    path: "programs",
    component: <ProgramView />,
    hidden: false, // Hide this route from the navigation menu
  },
  {
    name: "Èlèves",
    layout: "/admin",
    icon: <FcConferenceCall className="h-6 w-6" />,
    path: "students",
    component: <StudentsView />,
    hidden: false, // Hide this route from the navigation menu
  },
  {
    name: "Avis",
    layout: "/admin",
    icon: <FcFeedback className="h-6 w-6" />,
    path: "feedbacks",
    component: <FeedbackView />,
    hidden: false, // Hide this route from the navigation menu
  },
  {
    name: "Absences",
    layout: "/admin",
    icon: <FcBadDecision className="h-6 w-6" />,
    path: "absences",
    component: <AbsenceView />,
    hidden: false, // Hide this route from the navigation menu
  },
  {
    name: "Parents",
    layout: "/admin",
    icon: <FcCollaboration className="h-6 w-6" />,
    path: "parents",
    component: <Parents />,
    hidden: false, // Hide this route from the navigation menu
  },
  {
    name: "Professeurs",
    layout: "/admin",
    icon: <TeacherIcon></TeacherIcon>,
    path: "teachers",
    component: <Teachers />,
    hidden: false, // Hide this route from the navigation menu
  },
  {
    name: "Cantines",
    layout: "/admin",
    icon: <MdFoodBank className="h-6 w-6" />,
    path: "cantines",
    component: <Cantines />,
    hidden: false, // Hide this route from the navigation menu
  },
  {
    name: "Galerie",
    layout: "/admin",
    icon: <FcGallery className="h-6 w-6" />,
    path: "galeries",
    component: <GalerieView />,
    hidden: false, // Hide this route from the navigation menu
  },

  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    hidden: false, // Hide this route from the navigation menu
  },

  {
    name: "Type de service",
    layout: "/admin",
    path: "serviceType",
    icon: <FcTreeStructure className="h-6 w-6" />,
    component: <ServiceView />,
    hidden: true, // Hide this route from the navigation menu (Billing is not implemented)
  },
  {
    name: "Service",
    layout: "/admin",
    path: "listService",
    icon: <FcServices className="h-6 w-6" />,
    component: <Services />,
    hidden: true, // Hide this route from the navigation menu (Billing is not implemented)
  },
  {
    name: "Paiment",
    layout: "/admin",
    path: "billing",
    icon: <FcMoneyTransfer className="h-6 w-6" />,
    component: <BillingView />,
    hidden: true, // Hide this route from the navigation menu (Billing is not implemented)
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <FcAbout className="h-6 w-6" />,
  //   component: <Profile />,
  //   hidden: false, // Hide this route from the navigation menu
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
