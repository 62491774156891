import { aaHealthInfo } from "Redux/Actions/studentAction";
import CustomButton from "components/CustomButton";
import InputField from "components/fields/InputField";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

const QuestionForm = () => {
  const dispatch = useDispatch();
  const [quesForm, setQuesForm] = useState({});
  const handleInputChange = (value, field) => {
    setQuesForm({ ...quesForm, [field]: value[field], answer: 0 });
  };
  const handleSubmit = () => {
    dispatch(aaHealthInfo(quesForm));
  };
  return (
    <div className="mr-2  w-full grow">
      <InputField
        label="QUestion"
        id="title"
        name="title"
        extra="mb-3"
        placeholder="entrez votre question"
        type="text"
        field="question"
        required={true}
        setValue={(value) => handleInputChange(value, "question")}
      />
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={"Ajouter"}
            onClick={handleSubmit}
            disabled={!quesForm?.question}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionForm;
