import { getHealthInfo } from "Redux/Actions/studentAction";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import QuestionList from "./QuestionList";
import { Button } from "primereact/button";
import QuestionForm from "./QuestionForm";
import { openCentredModal } from "Redux/Actions/modalAction";

const Step4 = ({ formData, setFormData }) => {
  const dispatch = useDispatch();
  const { healthInfos } = useSelector((state) => state.studentReducer);
  useEffect(() => {
    dispatch(getHealthInfo());
  }, []);

  return (
    <div className="block h-[100%] w-full items-center justify-center">
      <div className="align-items-center  mb-5 flex justify-end gap-2">
        <Button
          label="Ajouter"
          icon="pi pi-plus"
          severity="success"
          onClick={() =>
            dispatch(
              openCentredModal({
                content: <QuestionForm />,
                headerText: "Nouveau Question",
              })
            )
          }
        />
      </div>
      <QuestionList
        questions={healthInfos}
        formData={formData}
        setFormData={setFormData}
      />
    </div>
  );
};

export default Step4;
