import React from "react";
import { Doughnut } from "react-chartjs-2";

import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip);
const FeedBackChart = ({ data }) => {
  const chartData = {
    labels: ["Payé", "Partiallement", "Non payé"],
    datasets: [
      {
        data: [data.paid, data.partiallyPaid, data.unpaid],
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 99, 332, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 332, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="  h-[500px] w-full rounded-[10px] bg-white p-4  text-lg font-bold text-darkBg">
      <h2>Paiements</h2>
      <Doughnut data={chartData} />
    </div>
  );
};

export default FeedBackChart;
