import { LoaderButton } from "Constants/icons";
import { useSelector } from "react-redux";

const DeleteModal = ({ name, onSubmit, onCancel, loading }) => {
  //   return <div>delete{name}</div>;
  const { loadingDelete } = useSelector((state) => state.parentReducer);
  const { loadingDeleteCantine } = useSelector((state) => state.cantineReducer);
  const { loadingDeleteFolder } = useSelector((state) => state.galerieReducer);
  const { loadingDeleteClass } = useSelector((state) => state.classReducer);
  const { loadingDeleteStudent } = useSelector((state) => state.studentReducer);
  const { loadingDeleteFeedback } = useSelector(
    (state) => state.feedBackReducer
  );

  return (
    <div className="p-6 text-center">
      <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
        Etes-vous sûr que vous voulez supprimer {name}
      </h3>
      <button
        type="button"
        onClick={onSubmit}
        className="mr-2 inline-flex items-center rounded-lg bg-red-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800"
      >
        {loadingDelete ||
        loadingDeleteCantine ||
        loadingDeleteFolder ||
        loadingDeleteFeedback ||
        loadingDeleteStudent ||
        loadingDeleteClass ? (
          <>
            <LoaderButton />
            Loading...
          </>
        ) : (
          "Oui, je suis sûr"
        )}
      </button>
      <button
        type="button"
        onClick={onCancel}
        className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600"
      >
        Non, annule
      </button>
    </div>
  );
};
export default DeleteModal;
