import { isMail } from "Constants/utils";
import { parentData } from "Constants/utils";
import { updateParent } from "Redux/Actions/ParentAction";
import { addNewParent } from "Redux/Actions/ParentAction";
import CustomButton from "components/CustomButton";
import InputField from "components/fields/InputField";
import { useDebugValue, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ParentForm = ({ user }) => {
  const dispatch = useDispatch();
  const { loadingAdd, error } = useSelector((state) => state.parentReducer);
  const nameParts = user?.name?.split(" ");
  const [userData, setUserData] = useState(
    user
      ? {
          first_name: nameParts[0],
          last_name: nameParts[1],
          email: user?.email,
          phone: user?.phone,
        }
      : {}
  );

  const [validationErrors, setValidationError] = useState({});

  const handleChange = (value, field) => {
    setUserData({ ...userData, [field]: value[field] });
    if (validationErrors[field] && value[field].length > 0) {
      const state = validationErrors;
      delete state[field];
      setValidationError(state);
    }
  };
  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!userData?.user_id) {
      parentData.forEach(({ field, required, label }) => {
        if (user && field === "password") {
          return null;
        } else if (required && !userData[field]) {
          errors = { ...errors, [field]: `${label} is required !` };
          valid = false;
        }
      });

      if (userData?.phone?.length < 8) {
        errors = { ...errors, phone: `Phone must be at least 8 digits !` };
        valid = false;
      }
      if (userData?.password?.length < 8) {
        errors = {
          ...errors,
          password: `Password must be at least 8 digits !`,
        };
        valid = false;
      }
    }
    setValidationError(errors);

    return valid;
  };
  const handleSubmit = () => {
    if (validateForm()) {
      if (user) {
        dispatch(
          updateParent(user?.id, {
            name: userData?.first_name + " " + userData?.last_name,
            email: userData?.email,
            phone: userData?.phone,
          })
        );
      } else {
        dispatch(
          addNewParent({
            name: userData?.first_name + " " + userData?.last_name,
            email: userData?.email,
            phone: userData?.phone,
            password: userData?.password,
            password_confirmation: userData?.password,
          })
        );
      }
    }
  };
  return (
    <div className="block w-full items-center justify-center">
      {parentData.map(
        ({ label, placeholder, type, field, required }, index) => {
          if (
            user &&
            (field === "password" || field === "password_confirmation")
          )
            return null;
          return (
            <InputField
              key={index}
              extra="mb-3"
              label={label}
              placeholder={placeholder}
              type={type}
              field={field}
              required={required}
              defaultValue={userData[field]}
              setValue={(value) => handleChange(value, field)}
              totalInputs={userData}
              errMessage={validationErrors[field]}
            />
          );
        }
      )}
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={user ? "Update" : "Ajouter"}
            disabled={!userData.first_name || !userData.phone}
            onClick={handleSubmit}
            loading={loadingAdd}
          />
        </div>
      </div>
    </div>
  );
};
export default ParentForm;
