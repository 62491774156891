import { GET_PROGRAM_FAILED } from "Redux/ActionsType/programType";
import { ADD_PROGRAM_SUCCESS } from "Redux/ActionsType/programType";
import { DELETE_PROGRAM_FAILED } from "Redux/ActionsType/programType";
import { LOADING_DELETE_PROG } from "Redux/ActionsType/programType";
import { UPDATE_PROGRAM_FAILED } from "Redux/ActionsType/programType";
import { UPDATE_PROGRAM_SUCCESS } from "Redux/ActionsType/programType";
import { DELETE_PROGRAM_SUCCESS } from "Redux/ActionsType/programType";
import { ADD_PROGRAM_FAILED } from "Redux/ActionsType/programType";
import { LOADING_ADD } from "Redux/ActionsType/programType";
import { GET_PROGRAM_SUCCESS } from "Redux/ActionsType/programType";
import { LOADING_PROGRAM } from "Redux/ActionsType/programType";

const initialState = {
  loading: false,
  loadingAdd: false,
  loadingDeleteProgram: false,
  programs: [],
  error: null,
};

export const programReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_PROGRAM:
      return {
        ...state,
        loading: true,
      };
    case GET_PROGRAM_SUCCESS:
      return { ...state, loading: false, programs: payload };
    case GET_PROGRAM_FAILED:
      return { ...state, loading: false, error: payload };

    case LOADING_ADD:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_PROGRAM_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        programs: [payload, ...state.programs],
      };
    case ADD_PROGRAM_FAILED:
      return { ...state, loadingAdd: false, error: payload };

    case LOADING_DELETE_PROG:
      return {
        ...state,
        loadingDeleteProgram: true,
      };
    case DELETE_PROGRAM_SUCCESS:
      return {
        ...state,
        loadingDeleteProgram: false,
        programs: state?.programs?.filter(({ id }) => +id !== +payload),
      };
    case DELETE_PROGRAM_FAILED:
      return { ...state, loadingDeleteProgram: false, error: payload };
    case UPDATE_PROGRAM_SUCCESS:
      return {
        ...state,
        loadingDeleteProgram: false,
        programs: state.programs.map((parent) =>
          parent.id === payload.id ? payload : parent
        ),
      };
    case UPDATE_PROGRAM_FAILED:
      return { ...state, loadingDeleteProgram: false, error: payload };

    default:
      return state;
  }
};
