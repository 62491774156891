import { classData } from "Constants/utils";
import { mapParentData } from "Constants/utils";
import { mapClassData } from "Constants/utils";
import { studentformData } from "Constants/utils";
import { updateStudent } from "Redux/Actions/studentAction";
import { addNewStudent } from "Redux/Actions/studentAction";
import { getUserWithRole } from "Redux/Actions/userAction";
import CustomButton from "components/CustomButton";
import CustomSelect from "components/CustomSelect";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const StudentForm = ({ user }) => {
  const dispatch = useDispatch();
  const { classes } = useSelector((state) => state.classReducer);
  const { parents, loadingUsers } = useSelector((state) => state.userReducer);
  const { loadingAdd } = useSelector((state) => state.studentReducer);

  const [validationErrors, setValidationError] = useState({});
  const [studentData, setStudentData] = useState({
    first_name: user?.first_name || null,
    last_name: user?.last_name || null,
    birthday: user?.birthday || null,
    classe_id: user?.classe?.id || null,
    user_id: user?.user_id || null,
    profile_photo_url: null,
  });

  useEffect(() => {
    dispatch(getUserWithRole("parent"));
  }, []);

  const handleChange = (value, field) => {
    setStudentData({ ...studentData, [field]: value[field] });
  };
  const validateStudentsForm = () => {
    let valid = true;
    let errors = {};

    studentformData.forEach(({ field, required, label }) => {
      if (required && !studentData[field]) {
        errors = { ...errors, [field]: `${label} is required !` };
        valid = false;
      }
    });

    setValidationError(errors);
    return valid;
  };
  const handleSubmit = () => {
    if (validateStudentsForm()) {
      if (user) {
        dispatch(updateStudent(studentData, user.id));
      } else {
        dispatch(addNewStudent(studentData));
      }
    }
  };

  return (
    <div className="block w-full items-center justify-center ">
      <div className="grid grid-cols-2 gap-4">
        {studentformData.map(
          ({ label, placeholder, type, field, required }, index) => {
            return type === "select" ? (
              <div className="mt-2">
                <CustomSelect
                  data={
                    field === "classe_id"
                      ? mapClassData(classes, true)
                      : mapParentData(parents, true)
                  }
                  selected={studentData[field]}
                  setSelected={(value) =>
                    setStudentData({ ...studentData, [field]: Number(value) })
                  }
                  label={label}
                  required={required}
                  errMessage={validationErrors[field]}
                />
              </div>
            ) : (
              <InputField
                key={index}
                extra="mb-3 "
                label={label}
                placeholder={placeholder}
                type={type}
                field={field}
                required={required}
                defaultValue={studentData[field]}
                setValue={(value) => handleChange(value, field)}
                totalInputs={studentData}
                errMessage={validationErrors[field]}
              />
            );
          }
        )}
      </div>
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={user ? "Enregistrer" : "Ajouter"}
            disabled={false}
            onClick={handleSubmit}
            loading={loadingAdd}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentForm;
