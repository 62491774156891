import { GET_SERVICE_FAILED } from "Redux/ActionsType/serviceType";
import { GET_SERVICE_TYPES_FAILED } from "Redux/ActionsType/serviceType";
import { ADD_SERVICE_TYPES_FAILED } from "Redux/ActionsType/serviceType";
import { DELETE_SERVICE_TYPES_FAILED } from "Redux/ActionsType/serviceType";
import { ADD_SERVICE_FAILED } from "Redux/ActionsType/serviceType";
import { DELETE_SERVICE_FAILED } from "Redux/ActionsType/serviceType";
import { UPDATE_SERVICE_TYPES_FAILED } from "Redux/ActionsType/serviceType";
import { UPDATE_SERVICE_FAILED } from "Redux/ActionsType/serviceType";
import { UPDATE_SERVICE_SUCCESS } from "Redux/ActionsType/serviceType";
import { UPDATE_SERVICE_TYPES_SUCCESS } from "Redux/ActionsType/serviceType";
import { DELETE_SERVICE_SUCCESS } from "Redux/ActionsType/serviceType";
import { ADD_SERVICE_SUCCESS } from "Redux/ActionsType/serviceType";
import { DELETE_SERVICE_TYPES_SUCCESS } from "Redux/ActionsType/serviceType";
import { ADD_SERVICE_TYPES_SUCCESS } from "Redux/ActionsType/serviceType";
import { GET_SERVICE_TYPES_SUCCESS } from "Redux/ActionsType/serviceType";
import { GET_SERVICE_SUCCESS } from "Redux/ActionsType/serviceType";
import { LOADING_SERVICE } from "Redux/ActionsType/serviceType";

const initialState = {
  loading: false,
  services: [],
  servicestypes: [],
  error: null,
};

export const serviceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_SERVICE:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICE_SUCCESS:
      return { ...state, loading: false, services: payload };
    case GET_SERVICE_FAILED:
      return { ...state, loading: false, error: payload };
    case GET_SERVICE_TYPES_SUCCESS:
      return { ...state, loading: false, servicestypes: payload };
    case GET_SERVICE_TYPES_FAILED:
      return { ...state, loading: false, error: payload };

    case ADD_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: [...state.services, payload],
      };
    case ADD_SERVICE_FAILED:
      return { ...state, loading: false, error: payload };

    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: state?.services?.filter(({ id }) => +id !== +payload),
      };
    case DELETE_SERVICE_FAILED:
      return { ...state, loading: false, error: payload };

    case ADD_SERVICE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        servicestypes: [...state.servicestypes, payload],
      };
    case ADD_SERVICE_TYPES_FAILED:
      return { ...state, loading: false, error: payload };
    case DELETE_SERVICE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        servicestypes: state?.servicestypes?.filter(
          ({ id }) => +id !== +payload
        ),
      };
    case DELETE_SERVICE_TYPES_FAILED:
      return { ...state, loading: false, error: payload };

    case UPDATE_SERVICE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        servicestypes: state.servicestypes.map((service) =>
          service.id === payload.id ? payload : service
        ),
      };
    case UPDATE_SERVICE_TYPES_FAILED:
      return { ...state, loading: false, error: payload };
    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: state.services.map((service) =>
          service.id === payload.id ? payload : service
        ),
      };
    case UPDATE_SERVICE_FAILED:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
