import { combineReducers } from "redux";
import { modalReducer } from "./modal";
import { userReducer } from "./user";
import { cantineReducer } from "./cantine";
import { toastModalReducer } from "./toastModalReducer";
import { feedBackReducer } from "./feedback";
import { galerieReducer } from "./galerie";
import { calendarReducer } from "./calendar";
import { parentReducer } from "./parents";
import { generalReducer } from "./general";
import { teacherReducer } from "./teachers";
import { classReducer } from "./class";
import { studentReducer } from "./student";
import { notifReducer } from "./notif";
import { billingReducer } from "./billing";
import { serviceReducer } from "./service";
import { programReducer } from "./program";
const appReducer = combineReducers({
  modalReducer,
  userReducer,
  cantineReducer,
  toastModalReducer,
  feedBackReducer,
  galerieReducer,
  calendarReducer,
  parentReducer,
  generalReducer,
  classReducer,
  teacherReducer,
  studentReducer,
  notifReducer,
  billingReducer,
  serviceReducer,
  programReducer,
});

export default appReducer;
