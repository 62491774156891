import { useSelect } from "@material-tailwind/react";
import { updatePassword } from "Redux/Actions/userAction";
import CustomButton from "components/CustomButton";
import InputField from "components/fields/InputField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const UpdatePassword = ({ user }) => {
  const dispatch = useDispatch();
  const [pass, setPass] = useState("");
  const { loadingPass } = useSelector((state) => state.userReducer);
  const handleSubmit = () => {
    dispatch(updatePassword({ user_id: +user?.id, password: pass }));
  };
  return (
    <div>
      <InputField
        extra="mb-3"
        label={"Nouveau mot de passe"}
        placeholder={"Nouveau mot de passe"}
        type={"password"}
        field={"password"}
        required={true}
        setValue={(value) => setPass(value["password"])}
      />
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={"Update"}
            disabled={pass?.length < 8}
            onClick={handleSubmit}
            loading={loadingPass}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
