export const LOADING_PROGRAM = "LOADING_PROGRAM";
export const GET_PROGRAM_SUCCESS = "GET_PROGRAM_SUCCESS";
export const GET_PROGRAM_FAILED = "GET_PROGRAM_FAILED";

export const LOADING_ADD = "LOADING_ADD";
export const ADD_PROGRAM_SUCCESS = "ADD_PROGRAM_SUCCESS";
export const ADD_PROGRAM_FAILED = "ADD_PROGRAM_FAILED";

export const LOADING_DELETE_PROG = "LOADING_DELETE_PROG";
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS";
export const DELETE_PROGRAM_FAILED = "DELETE_PROGRAM_FAILED";

export const LOADING_UPDATE = "LOADING_UPDATE";
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS";
export const UPDATE_PROGRAM_FAILED = "UPDATE_PROGRAM_FAILED";
