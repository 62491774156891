import React, { useEffect } from "react";
import { mapClassData } from "Constants/utils";
import { studentformData } from "Constants/utils";
import CustomSelect from "components/CustomSelect";
import { mapParentData } from "Constants/utils";
import { useSelector } from "react-redux";
import InputField from "components/fields/InputField";
const Step2 = ({ formData, setFormData, parent_id, validationErrors }) => {
  const { parents, loadingUsers } = useSelector((state) => state.userReducer);
  const { classes } = useSelector((state) => state.classReducer);
  useEffect(() => {
    if (parent_id) {
      setFormData({ ...formData, user_id: parent_id });
    } else {
      setFormData({ ...formData, user_id: parents[0]?.id });
    }
  }, []);
  const handleChange = (value, field) => {
    setFormData({ ...formData, [field]: value[field] });
  };

  return (
    <div className="block h-[100%] w-full items-center justify-center">
      {studentformData.map(
        ({ label, placeholder, type, field, required }, index) => {
          if (field === "user_id") {
            return null;
          } else {
            return type === "select" ? (
              <div className="mt-2" key={field}>
                <CustomSelect
                  data={
                    field === "classe_id"
                      ? mapClassData(classes, true)
                      : field === "user_id"
                      ? mapParentData(parents, true)
                      : [
                          { label: "male", value: "1" },
                          { label: "female", value: "2" },
                        ]
                  }
                  selected={formData[field]}
                  setSelected={(value) =>
                    setFormData({ ...formData, [field]: Number(value) })
                  }
                  label={label}
                  required={required}
                />
              </div>
            ) : (
              <InputField
                key={index}
                extra="mb-3 "
                label={label}
                placeholder={placeholder}
                type={type}
                field={field}
                required={required}
                defaultValue={formData[field]}
                setValue={(value) => handleChange(value, field)}
                totalInputs={formData}
                errMessage={validationErrors[field]}
              />
            );
          }
        }
      )}
    </div>
  );
};

export default Step2;
