import api from "Constants/api";
import { GET_SERVICE_TYPES_FAILED } from "Redux/ActionsType/serviceType";
import { ADD_SERVICE_TYPES_SUCCESS } from "Redux/ActionsType/serviceType";
import { DELETE_SERVICE_TYPES_SUCCESS } from "Redux/ActionsType/serviceType";
import { DELETE_SERVICE_TYPES_FAILED } from "Redux/ActionsType/serviceType";
import { ADD_SERVICE_TYPES_FAILED } from "Redux/ActionsType/serviceType";
import { GET_SERVICE_TYPES_SUCCESS } from "Redux/ActionsType/serviceType";
import { GET_SERVICE_FAILED } from "Redux/ActionsType/serviceType";
import { GET_SERVICE_SUCCESS } from "Redux/ActionsType/serviceType";
import { LOADING_SERVICE } from "Redux/ActionsType/serviceType";
import { closeCentredModal, openSuccessModal } from "./modalAction";
import { UPDATE_SERVICE_TYPES_SUCCESS } from "Redux/ActionsType/serviceType";
import { UPDATE_SERVICE_TYPES_FAILED } from "Redux/ActionsType/serviceType";
import { ADD_SERVICE_SUCCESS } from "Redux/ActionsType/serviceType";
import { ADD_SERVICE_FAILED } from "Redux/ActionsType/serviceType";
import { DELETE_SERVICE_SUCCESS } from "Redux/ActionsType/serviceType";
import { DELETE_SERVICE_FAILED } from "Redux/ActionsType/serviceType";
import { UPDATE_SERVICE_SUCCESS } from "Redux/ActionsType/serviceType";
import { UPDATE_SERVICE_FAILED } from "Redux/ActionsType/serviceType";

export const getAllService = () => async (dispatch) => {
  dispatch({ type: LOADING_SERVICE });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.get(`/admin/services`, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: GET_SERVICE_SUCCESS, payload: data?.data });
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: GET_SERVICE_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const newService = (formData) => async (dispatch) => {
  dispatch({ type: LOADING_SERVICE });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.post(`/admin/services`, formData, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: ADD_SERVICE_SUCCESS, payload: data?.data });
    dispatch(openSuccessModal("Success", true));
    dispatch(closeCentredModal());
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    console.log(error);
    dispatch({
      type: ADD_SERVICE_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const deleteService = (id) => async (dispatch) => {
  dispatch({ type: LOADING_SERVICE });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.delete(`/admin/services/${id}`, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: DELETE_SERVICE_SUCCESS, payload: id });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    console.log(error);
    dispatch({
      type: DELETE_SERVICE_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const getAllServiceType = () => async (dispatch) => {
  dispatch({ type: LOADING_SERVICE });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.get(`/admin/serviceTypes`, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: GET_SERVICE_TYPES_SUCCESS, payload: data?.data });
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    dispatch({
      type: GET_SERVICE_TYPES_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const newServiceType = (formData) => async (dispatch) => {
  dispatch({ type: LOADING_SERVICE });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.post(`/admin/serviceTypes`, formData, {
      headers: { authorization: `Bearer ${token}` },
    });

    dispatch({ type: ADD_SERVICE_TYPES_SUCCESS, payload: data?.data });
    dispatch(closeCentredModal());

    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));

    dispatch({
      type: ADD_SERVICE_TYPES_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const deleteServiceType = (id) => async (dispatch) => {
  dispatch({ type: LOADING_SERVICE });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.delete(`/admin/serviceTypes/${id}`, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: DELETE_SERVICE_TYPES_SUCCESS, payload: id });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    console.log(error);
    dispatch({
      type: DELETE_SERVICE_TYPES_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const updateServiceType = (id, newFormData) => async (dispatch) => {
  dispatch({ type: LOADING_SERVICE });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.put(`/admin/serviceTypes/${id}`, newFormData, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: UPDATE_SERVICE_TYPES_SUCCESS, payload: data.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    console.log(error);
    dispatch({
      type: UPDATE_SERVICE_TYPES_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};

export const updateService = (id, newFormData) => async (dispatch) => {
  dispatch({ type: LOADING_SERVICE });
  try {
    const token = localStorage.getItem("token");
    const { data } = await api.put(`/admin/services/${id}`, newFormData, {
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({ type: UPDATE_SERVICE_SUCCESS, payload: data.data });
    dispatch(closeCentredModal());
    dispatch(openSuccessModal("Success", true));
  } catch (error) {
    dispatch(openSuccessModal("Error", false));
    console.log(error);
    dispatch({
      type: UPDATE_SERVICE_FAILED,
      payload: "Unauthorized: Please check your credentials.",
    });
  }
};
