import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import { DiApple } from "react-icons/di";
import { DiAndroid } from "react-icons/di";
import { DiWindows } from "react-icons/di";

import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { DeleteIcon, Loading } from "Constants/icons";
import { useDispatch, useSelector } from "react-redux";
import { PlusIconNoStroke } from "Constants/icons";
import { UpdateIcon } from "Constants/icons";
import { openCentredModal } from "Redux/Actions/modalAction";
import ParentForm from "components/Parent/ParentForm";
import { deleteUser } from "Redux/Actions/userAction";
import CentredModal from "components/modal/CentredModal";
import DeleteModal from "components/modal/DeleteModal";
import { closeCentredModal } from "Redux/Actions/modalAction";
import { Button } from "primereact/button";
import { EyeIcon } from "Constants/icons";
import ParentDetails from "./ParentDetails";
import { SettingsIcon } from "Constants/icons";
import UpdatePassword from "components/modal/UpdatePassword";
import { exportParent } from "Redux/Actions/ParentAction";

const DevelopmentTable = ({ columnsData, tableData, loading }) => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.generalReducer);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const handleDelete = (id, name) => {
    dispatch(
      openCentredModal({
        content: (
          <DeleteModal
            name={name}
            // loading={loadingDelete}
            onSubmit={() => dispatch(deleteUser(id, "parent"))}
            onCancel={() => dispatch(closeCentredModal())}
          />
        ),
        headerText: "Delete Parent",
      })
    );
    // dispatch(deleteParent(cell.row.original.id))
  };

  const handleUpdate = (user) => {
    dispatch(
      openCentredModal({
        content: <ParentForm user={user} />,
        headerText: "Edit Responsable",
      })
    );
    // dispatch(deleteParent(cell.row.original.id))
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // This is provided by usePagination
    pageCount, // This is provided by usePagination
    gotoPage, // This is provided by usePagination
    nextPage, // This is provided by usePagination
    previousPage, // This is provided by usePagination
    canNextPage, // This is provided by usePagination
    canPreviousPage, // This is provided by usePagination
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Optionally set initial page index
    },
    usePagination
  );

  return (
    <Card extra={"w-full h-full p-4"}>
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Liste des parents
        </div>
        <div className="align-items-center  flex gap-2">
          <Button
            label="Exporter"
            severity="success"
            onClick={() => dispatch(exportParent())}
          />
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            severity="success"
            onClick={() =>
              dispatch(
                openCentredModal({
                  content: <ParentForm />,
                  headerText: "Nouveau Parent",
                })
              )
            }
          />
        </div>
      </div>

      <div className=" h-[70vh] overflow-x-scroll xl:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="mt-8  w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups?.map((headerGroup, index) => (
              <tr {...headerGroup?.getHeaderGroupProps()} key={index}>
                {headerGroup?.headers?.map((column, index) => (
                  <th
                    // {...column?.getHeaderProps(column?.getSortByToggleProps())}
                    className="border-b border-gray-200 pr-32 pb-[10px] text-start dark:!border-navy-700 "
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {loading ? (
            <tbody {...getTableBodyProps()}>
              <tr>
                <td colSpan={headerGroups[0]?.headers?.length}>
                  <div role=" w-full h-full p-4 d-flex items-center justify-center">
                    <Loading />
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page?.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row?.getRowProps()} key={index}>
                    {row?.cells?.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "ACTIONS") {
                        data = (
                          <div className="flex items-center gap-2">
                            <div
                              onClick={() =>
                                handleDelete(
                                  cell.row.original.id,
                                  cell.row.original.name
                                )
                              } // Call the edit action function with row id
                              className=" py-1 px-1 "
                            >
                              <DeleteIcon darkMode={darkMode} />
                            </div>
                            <div
                              onClick={() =>
                                // console.log(cell.row.original.name)

                                handleUpdate(cell.row.original)
                              } // Call the delete action function with row id
                              className=" py-1 px-1 "
                            >
                              <UpdateIcon darkMode={darkMode} />
                            </div>
                            <div
                              onClick={() =>
                                // console.log(cell.row.original.name)

                                dispatch(
                                  openCentredModal({
                                    content: (
                                      <ParentDetails user={cell.row.original} />
                                    ),
                                    headerText: "Detail parent",
                                  })
                                )
                              } // Call the delete action function with row id
                              className=" py-1 px-1 "
                            >
                              <EyeIcon darkMode={darkMode} />
                            </div>
                            <div
                              onClick={() =>
                                // console.log(cell.row.original.name)

                                dispatch(
                                  openCentredModal({
                                    content: (
                                      <UpdatePassword
                                        user={cell.row.original}
                                      />
                                    ),
                                    headerText: "Modifier mot de passe",
                                  })
                                )
                              } // Call the delete action function with row id
                              className=" py-1 px-1 "
                            >
                              <SettingsIcon darkMode={darkMode} />
                            </div>
                          </div>
                        );
                      } else {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      }

                      // } else if (cell.column.Header === "DATE") {
                      //   data = (
                      //     <p className="text-sm font-bold text-navy-700 dark:text-white">
                      //       {cell.value}
                      //     </p>
                      //   );
                      // } else if (cell.column.Header === "PROGRESS") {
                      //   data = (
                      //     <div className="flex items-center gap-3">
                      //       <p className="text-sm font-bold text-navy-700 dark:text-white">
                      //         {cell.value}%
                      //       </p>
                      //       <Progress width="w-[68px]" value={cell.value} />
                      //     </div>
                      //   );
                      // }
                      return (
                        <td
                          {...cell?.getCellProps()}
                          key={index}
                          className="pt-[14px] pb-3 text-[14px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className="flex w-full items-center justify-center">
        <nav aria-label="Page navigation example">
          <ul className="inline-flex -space-x-px text-sm">
            <li>
              <div
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="ml-0 flex h-8 items-center justify-center rounded-l-lg border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                Précédente
              </div>
            </li>
            <li>
              <a
                href="#"
                className="flex h-8 items-center justify-center border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                Page {pageIndex + 1} of {pageCount}
              </a>
            </li>

            <li>
              <div
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="flex h-8 items-center justify-center rounded-r-lg border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                Suivante
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </Card>
  );
};

export default DevelopmentTable;
