export const LOADING_ADD_STUDENT = "LOADING_ADD_STUDENT";
export const ADD_STUDENT_SUCCESS = "ADD_STUDENT_SUCCESS";
export const ADD_STUDENT_FAILED = "ADD_STUDENT_FAILED";

export const LOADING_GET_STUDENT = "LOADING_GET_STUDENT";
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS";
export const GET_STUDENT_FAILED = "GET_STUDENT_FAILED";

export const LOADING_DELETE_STUDENT = "LOADING_DELETE_STUDENT";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_FAILED = "DELETE_STUDENT_FAILED";

export const GET_HEALTH_SUCCESS = "GET_HEALTH_SUCCESS";
export const GET_HEALTH_FAILED = "GET_HEALTH_FAILED";

export const ADD_HEALTH_SUCCESS = "ADD_HEALTH_SUCCESS";
export const ADD_HEALTH_FAILED = "ADD_HEALTH_FAILED";

export const LOADING_UPDATE_STUDENT = "LOADING_UPDATE_STUDENT";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_FAILED = "UPDATE_STUDENT_FAILED";
