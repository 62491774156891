import Footer from "components/footer/FooterAuthDefault";
import authImg from "assets/img/background/bg.jpg";
import { Link, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import routes from "routes.js";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { useEffect } from "react";
import siginImg from "../../assets/img/auth/log.jpg";
import SuccessModal from "components/modal/SuccessModal";
export default function Auth() {
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      navigate("/admin/dashboard", { replace: true });
    }
  }, [token, navigate]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <SuccessModal />

          <div className="relative flex md:h-[100vh]">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start  md:w-[100%] md:pt-12 lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 ">
              <div className="mb-auto flex h-[100%] flex-col pl-5 pr-5 md:pr-0 md:pl-12  lg:pl-0 xl:max-w-full">
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
