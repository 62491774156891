import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { useState } from "react";
import { useEffect } from "react";
import { Provider } from "react-redux";
import configureStore from "Redux/Store";
import Auth from "components/Auth";

const App = () => {
  const token = localStorage.getItem("token");

  const location = useLocation();
  const navigate = useNavigate();
  const store = configureStore();

  useEffect(() => {
    if (location.pathname === "/" && token) {
      navigate("/admin/dashboard", { replace: true });
    }
  }, [location.pathname, token]);

  return (
    <Provider store={store}>
      <Auth>
        <Routes>
          <Route path="auth/*" element={<AuthLayout />} />
          <Route path="admin/*" element={<AdminLayout />} />
          <Route path="rtl/*" element={<RtlLayout />} />
          <Route
            path="/"
            element={
              token ? (
                <Navigate to="/admin" replace />
              ) : (
                <Navigate to="/auth" replace />
              )
            }
          />
        </Routes>
      </Auth>
    </Provider>
  );
};

export default App;
