import { isMail } from "Constants/utils";
import { cantineData } from "Constants/utils";
import { addNewCantine } from "Redux/Actions/cantineAction";
import CustomButton from "components/CustomButton";
import InputField from "components/fields/InputField";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const CantineForm = ({ cantine }) => {
  const { loadingAdd } = useSelector((state) => state.cantineReducer);
  const dispatch = useDispatch();
  const [cantineD, setCantineD] = useState({
    day: cantine?.day,
    menu: cantine?.menu || [],
  });

  const [validationErrors, setValidationError] = useState({});

  const handleChange = (value, field) => {
    if (field === "day") {
      setCantineD({
        ...cantineD,
        [field]: value[field],
      });
    }

    if (validationErrors[field] && value[field].length > 0) {
      const state = validationErrors;
      delete state[field];
      setValidationError(state);
    }
    // setUserData({ ...userData, [field]: value[field] });
    // if (validationErrors[field] && value[field].length > 0) {
    //   const state = validationErrors;
    //   delete state[field];
    //   setValidationError(state);
    // }
  };
  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!cantineD.day) {
      errors = { ...errors, day: "date is required !" };
      valid = false;
    } else if (cantineD.menu.length === 0) {
      errors = { ...errors, menu: "menu is required !" };
      valid = false;
    }

    setValidationError(errors);
    return valid;
  };
  const handleSubmit = () => {
    if (validateForm()) {
      if (cantine) {
        // dispatch(updateParent(user?.id, userData));
      } else {
        dispatch(addNewCantine(cantineD));
      }
    }
  };
    const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const newMenuItem = event.target.value.trim();

      if (newMenuItem !== "") {
        setCantineD((prevState) => ({
          ...prevState,
          menu: [...prevState.menu, newMenuItem],
        }));
        event.target.value = ""; 
      }
    }
  };

  const deleteItem = (item) => {
    const updatedMenu = cantineD.menu.filter((menuItem) => menuItem !== item);
    setCantineD((prevState) => ({
      ...prevState,
      menu: updatedMenu,
    }));
  };
  return (
    <div className="block w-full items-center justify-center">
      {cantineData?.map(({ label, type, field }, index) => {
        return (
          <InputField
            key={index}
            extra="mb-3"
            label={label}
            placeholder={"placeholder"}
            type={type}
            field={field}
            required={true}
            defaultValue={cantineData[field]}
            setValue={(value) => handleChange(value, field)}
            totalInputs={cantineData}
            errMessage={validationErrors[field]}
            onKeyDown={field === "menu" ? handleKeyDown : undefined}
          />
        );
      })}
      <div className="flex gap-2">
        {cantineD?.menu?.map((item, index) => (
          <div className="flex items-center rounded-md border border-gray-100 py-1 shadow-md ps-2">
            <div key={index} className="me-1">
              {item}
            </div>
            <svg
              aria-hidden="true"
              onClick={() => deleteItem(item)}
              className="mx-1 h-4 w-4 cursor-pointer"
              fill="red"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <div className="w-1/3">
          <CustomButton
            text={"Ajouter"}
            disabled={false}
            onClick={handleSubmit}
            loading={loadingAdd}
          />
        </div>
      </div>
    </div>
  );
};
export default CantineForm;
