export const columnsDataDevelopment = [
  {
    Header: "NOM",
    accessor: "first_name",
  },
  {
    Header: "PRENOM",
    accessor: "last_name",
  },
  {
    Header: "DATE DE NAISSANCE",
    accessor: "birthday",
  },
  {
    Header: "CLASS",
    accessor: "classe.name",
  },
  {
    Header: "ACTIONS",
    accessor: "id", // Use a unique identifier from your data as the accessor
  },
  // {
  //   Header: "PROGRESS",
  //   accessor: "progress",
  // },
];

export const columnsDataFeedback = [
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "COMMENT",
    accessor: "comment",
  },
  {
    Header: "PARENT",
    accessor: "user",
  },
  {
    Header: "DATE",
    accessor: "created_at",
  },
  {
    Header: "ACTIONS",
    accessor: "id", // Use a unique identifier from your data as the accessor
  },
  // {
  //   Header: "PROGRESS",
  //   accessor: "progress",
  // },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const notifsDataColumns = [
  {
    Header: "TITLE",
    accessor: "title",
  },
  {
    Header: "MESSAGE",
    accessor: "content",
  },
  {
    Header: "USERS",
    accessor: "user",
  },
  {
    Header: "ACTIONS",
    accessor: "id", // Use a unique identifier from your data as the accessor
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
